import * as React from 'react';
import { connect } from 'react-redux';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import Input from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { inputValidator } from '../../../utils/';
import styles from './UserForm.module.css';
import { formatFieldToEdit } from '../../../utils/FieldFormatHelper';

interface IUserFormProps {
  token: string;
  user: any;
  userFromProps?: any;
  loading: boolean;
  onSubmitUser(userPayload: any): any;
}

const userTypeOptions = [
  {
    label: 'QA',
    value: 'qa'
  },
  {
    label: 'Developer',
    value: 'dev'
  },
];

const userRoleOptions = [
  {
    label: 'User',
    value: 'user'
  },
  {
    label: 'Administrator',
    value: 'admin'
  },
];

const userActiveOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const initialState: any = {
  formControls: {
    name: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 255,
      },
      valid: false,
      touched: false
    },
    lastName: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 255,
      },
      valid: false,
      touched: false
    },
    email: {
      value: '',
      validation: {
        isRequired: true,
        isEmail: true,
      },
      valid: false,
      touched: false
    },
    type: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    role: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    active: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
  },
  formIsValid: false,
  showModal: false,
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    user: state.users.currentUser,
    loading: loadingAppSelector(state),
  };
};

const formatUserFromProps = (userFromProps: any): any => {
  return {
    name: formatFieldToEdit(userFromProps.name),
    lastName: formatFieldToEdit(userFromProps.lastName),
    email: formatFieldToEdit(userFromProps.email),
    password: formatFieldToEdit(userFromProps.password),
    confirmPassword: formatFieldToEdit(userFromProps.password),
    type: formatFieldToEdit(userFromProps.type),
    role: formatFieldToEdit(userFromProps.role),
    active: formatFieldToEdit(userFromProps.active),
  };
};

class UserForm extends React.Component<IUserFormProps>{
  constructor(props: IUserFormProps) {
    super(props);
    if (!props.userFromProps) {
      initialState.formControls.password = {
        value: '',
        validation: {
          isRequired: true,
          minLength: 4,
          maxLength: 100,
        },
        valid: false,
        touched: false
      };
      
      initialState.formControls.confirmPassword = {
        value: '',
        validation: {
          isRequired: true,
          minLength: 4,
          maxLength: 100,
        },
        valid: false,
        touched: false
      };
    }
    this.state = initialState;
  }
  state: State;
  

  componentDidMount(): void {
    const { userFromProps } = this.props;
    if (userFromProps) {
      const formattedUser = formatUserFromProps(userFromProps);
      const { formControls } = this.state;
      let updatedFormControls = { ...formControls };

      Object.keys(formControls).forEach((key: string) => {
        const updatedFormInput= {
          ...updatedFormControls[key as FormControlKey],
          value: formattedUser[key],
          valid: inputValidator(formattedUser[key], formControls[key as FormControlKey].validation),
          touched: true,
        };
        updatedFormControls = {
          ...updatedFormControls,
          [key]: updatedFormInput,
        };
      });
      this.setState({ formControls: updatedFormControls });
    }
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { value } = isSelect? event : event.target;
    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value,
      valid: inputValidator(value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement>, inputIdentifier: FormControlKey) => {
    // Gets formControls from current state.
    const { formControls } = this.state;

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls });
  };

  userSubmitHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { onSubmitUser } = this.props;
    const { name, lastName, email, type, role, active, password, confirmPassword } = this.state.formControls;
    await onSubmitUser({
      name: name.value,
      lastName: lastName.value,
      email: email.value,
      password: password? password.value : undefined,
      confirmPassword: confirmPassword? confirmPassword.value : undefined,
      type: type.value,
      role: role.value,
      // @ts-ignore
      active: active.value === false ? false : true,
    });
  };

  renderPasswordInputs = () => {
    const { password, confirmPassword } = this.state.formControls;
    const { loading, userFromProps } = this.props;
    if (userFromProps) {
      return null;
    }
    return (
      <>
        <Input
          elementType="input"
          elementConfig={{type: 'password', placeholder: 'Type a password'}}
          value={password.value}
          shouldValidate={true}
          valid={password.valid}
          touched={password.touched}
          label="Password"
          disabled={loading}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'password', false)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'password')}
        />
        <Input
          elementType="input"
          elementConfig={{type: 'password', placeholder: 'Confirm your password'}}
          value={confirmPassword.value}
          shouldValidate={true}
          valid={confirmPassword.valid}
          touched={confirmPassword.touched}
          label="Password Confirmation"
          disabled={loading}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'confirmPassword', false)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'confirmPassword')}
        />
      </>
   );
  }

  render() {
    const { loading, userFromProps } = this.props;
    const { name, lastName, email, type, role, active } = this.state.formControls;
    const { formIsValid } = this.state;

    const spinner = loading? <Spinner type="linear" /> : null;

    return (
        <div className={styles.Form}>
          <div className={styles.Spinner}>
            { spinner }
          </div>
          <form>
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type a name', autoFocus: true}}
              value={name.value}
              shouldValidate={true}
              valid={name.valid}
              touched={name.touched}
              label="Name"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'name', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'name')}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type a last name'}}
              value={lastName.value}
              shouldValidate={true}
              valid={lastName.valid}
              touched={lastName.touched}
              label="Last Name"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'lastName', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'lastName')}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'email', placeholder: 'Type an email'}}
              value={email.value}
              shouldValidate={true}
              valid={email.valid}
              touched={email.touched}
              label="Email"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'email', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'email')}
            />
            { this.renderPasswordInputs() }
            <Dropdown
              value={type.value}
              label="Type"
              disabled={loading}
              options={userTypeOptions}
              shouldValidate={true}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'type', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'type')}
            />
            <Dropdown
              value={role.value}
              label="Role"
              disabled={loading}
              options={userRoleOptions}
              shouldValidate={true}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'role', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'role')}
            />
            <Dropdown
              value={active.value}
              label="Active"
              disabled={loading}
              options={userActiveOptions}
              shouldValidate={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'active', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'active')}
            />
            <div className={`d-flex justify-content-center ${styles.Buttons}`}>
              <Button type="submit" color="success" disabled={!formIsValid || loading} onClick={(event: React.MouseEvent<HTMLElement>) => this.userSubmitHandler(event)}>
                Save
              </Button>
              <div className={styles.Divider}/>
              <NavigationItem link={userFromProps? `/users/${userFromProps.id}/details` : '/users'} exact>
                <Button type="submit" color="danger" disabled={loading}>
                  Cancel
                </Button>
              </NavigationItem>
            </div>
          </form>
        </div>
    );
  }

}

export default connect(matchStateToProps, null)(UserForm);
