import axios from '../axios-instance';

export const getProfiles = (token: string, index: number, items: number, searchBy: any, search: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  let path = '/profiles?';
  if (index !== -1) {
    path += `items=${items? items: 10}&index=${index}`;
    path += search?  `&search=${search}` : '';
  }
  return axios.get(path, options);
};

export const getProfileById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.get(`/profiles/${id}`, options);
};

export const createProfile = (token: string, profile: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.post('/profiles/', profile, options);
};

export const updateProfile = (token: string, profile: any, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.put(`/profiles/${id}`, profile, options);
};

export const deleteProfileById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.delete(`/profiles/${id}`, options);
};

