import * as api from '../../services/api';

import {
  CLEAR_SINGLE_ACCOUNT,
  CREATE_SINGLE_ACCOUNT_FAIL,
  CREATE_SINGLE_ACCOUNT_START,
  CREATE_SINGLE_ACCOUNT_SUCCESS,
  DELETE_SINGLE_ACCOUNT_FAIL,
  DELETE_SINGLE_ACCOUNT_START,
  DELETE_SINGLE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS_FAIL,
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_SINGLE_ACCOUNT_FAIL,
  FETCH_SINGLE_ACCOUNT_START,
  FETCH_SINGLE_ACCOUNT_SUCCESS,
  IAccountActionTypes,
  SET_ACCOUNT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_ACCOUNT_FAIL,
  UPDATE_SINGLE_ACCOUNT_START,
  UPDATE_SINGLE_ACCOUNT_SUCCESS,
} from './types';

export const fetchSingleAccountStart = (): IAccountActionTypes => {
    return {
      type: FETCH_SINGLE_ACCOUNT_START
    };
};

export const fetchSingleAccountSuccess = (currentAccount: {}): IAccountActionTypes => {
    return {
      type: FETCH_SINGLE_ACCOUNT_SUCCESS,
      currentAccount,
    };
};

export const fetchSingleAccountFail = (error: Error): IAccountActionTypes => {
  return {
    type: FETCH_SINGLE_ACCOUNT_FAIL,
    error,
  };
};

export const clearSingleAccount = (): IAccountActionTypes => {
  return {
    type: CLEAR_SINGLE_ACCOUNT,
  };
};

export const createSingleAccountStart = (): IAccountActionTypes => {
  return {
    type: CREATE_SINGLE_ACCOUNT_START
  };
};

export const createSingleAccountSuccess = (currentAccount: {}): IAccountActionTypes => {
  return {
    type: CREATE_SINGLE_ACCOUNT_SUCCESS,
    currentAccount,
  };
};

export const createSingleAccountFail = (error: Error): IAccountActionTypes => {
  return {
    type: CREATE_SINGLE_ACCOUNT_FAIL,
    error,
  };
};

export const updateSingleAccountStart = (): IAccountActionTypes => {
  return {
    type: UPDATE_SINGLE_ACCOUNT_START
  };
};

export const updateSingleAccountSuccess = (currentAccount: {}): IAccountActionTypes => {
  return {
    type: UPDATE_SINGLE_ACCOUNT_SUCCESS,
    currentAccount,
  };
};

export const updateSingleAccountFail = (error: Error): IAccountActionTypes => {
  return {
    type: UPDATE_SINGLE_ACCOUNT_FAIL,
    error,
  };
};

export const deleteSingleAccountStart = (): IAccountActionTypes => {
  return {
    type: DELETE_SINGLE_ACCOUNT_START,
  };
};

export const deleteSingleAccountSuccess = (): IAccountActionTypes => {
  return {
    type: DELETE_SINGLE_ACCOUNT_SUCCESS,
  };
};

export const deleteSingleAccountFail= (error: Error): IAccountActionTypes => {
  return {
    type: DELETE_SINGLE_ACCOUNT_FAIL,
    error
  };
};

export const fetchAccountsStart = (): IAccountActionTypes => {
  return {
    type: FETCH_ACCOUNTS_START
  };
};

export const fetchAccountsSuccess = (accountsList: [], accountsPages: number, index: number): IAccountActionTypes => {
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    accountsList,
    accountsPages,
    index,
  };
};

export const fetchAccountsFail = (error: Error): IAccountActionTypes => {
  return {
    type: FETCH_ACCOUNTS_FAIL,
    error,
  };
};

export const setAccountListRedirectPath = (path: string): IAccountActionTypes => {
  return {
    type: SET_ACCOUNT_LIST_REDIRECT_PATH,
    path
  };
};

export const getAccountById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleAccountStart());
    return api.getAccountById(token, id)
      .then(response => {
        dispatch(fetchSingleAccountSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleAccountFail(error));
      });
  }
};

export const deleteAccountById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleAccountStart());
     return api.deleteAccountById(token, id)
        .then(response => {
          dispatch(deleteSingleAccountSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleAccountFail(error));
        });
  }
};

export const getAccounts = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchAccountsStart());
    return api.getAccounts(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchAccountsSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchAccountsFail(error));
      });
  }
};

export const createAccount = (token: string, account: any) => {
  return (dispatch: any) => {
    dispatch(createSingleAccountStart());
    return api.createAccount(token, account)
      .then(response => {
        dispatch(createSingleAccountSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleAccountFail(error));
      });
  }
};

export const updateAccount = (token: string, account: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleAccountStart());
    return api.updateAccount(token, account, id)
      .then(response => {
        dispatch(updateSingleAccountSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleAccountFail(error));
      });
  }
};
