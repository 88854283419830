import * as React from 'react';
import { connect } from 'react-redux';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../../store/global/selector';
import { inputValidator } from '../../../../utils/';
import styles from './PasswordChangeForm.module.css';

interface IPasswordChangeFormProps {
  token: string;
  user: any;
  userFromProps?: any;
  loading: boolean;
  onSubmitUser(userPayload: any): any;
}

const initialState: any = {
  formControls: {
    password: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 4,
        maxLength: 100,
      },
      valid: false,
      touched: false
    },
    confirmPassword: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 4,
        maxLength: 100,
      },
      valid: false,
      touched: false
    }
  },
  formIsValid: false,
  showModal: false,
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    user: state.users.currentUser,
    loading: loadingAppSelector(state),
  };
};

class PasswordChangeForm extends React.Component<IPasswordChangeFormProps>{
  constructor(props: IPasswordChangeFormProps) {
    super(props);
    this.state = initialState;
  }
  state: State;
  

  componentDidMount(): void {
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { value } = isSelect? event : event.target;
    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value,
      valid: inputValidator(value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement>, inputIdentifier: FormControlKey) => {
    // Gets formControls from current state.
    const { formControls } = this.state;

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls });
  };

  userSubmitHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { onSubmitUser } = this.props;
    const { password, confirmPassword } = this.state.formControls;
    await onSubmitUser({
      password: password.value,
      confirmPassword: confirmPassword.value,
    });
  };

  render() {
    const { loading, userFromProps } = this.props;
    const { password, confirmPassword } = this.state.formControls;
    const { formIsValid } = this.state;

    const spinner = loading? <Spinner type="linear" /> : null;

    return (
        <div className={styles.Form}>
          <div className={styles.Spinner}>
            { spinner }
          </div>
          <form>
            <Input
              elementType="input"
              elementConfig={{type: 'password', placeholder: 'Type a password'}}
              value={password.value}
              shouldValidate={true}
              valid={password.valid}
              touched={password.touched}
              label="Password"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'password', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'password')}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'password', placeholder: 'Confirm your password'}}
              value={confirmPassword.value}
              shouldValidate={true}
              valid={confirmPassword.valid}
              touched={confirmPassword.touched}
              label="Password Confirmation"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'confirmPassword', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'confirmPassword')}
            />
            <div className={`d-flex justify-content-center ${styles.Buttons}`}>
              <Button type="submit" color="success" disabled={!formIsValid || loading} onClick={(event: React.MouseEvent<HTMLElement>) => this.userSubmitHandler(event)}>
                Save
              </Button>
              <div className={styles.Divider}/>
              <NavigationItem link={userFromProps? `/users/${userFromProps.id}/details` : '/users'} exact>
                <Button type="submit" color="danger" disabled={loading}>
                  Cancel
                </Button>
              </NavigationItem>
            </div>
          </form>
        </div>
    );
  }
}

export default connect(matchStateToProps, null)(PasswordChangeForm);
