import * as React from 'react';
import { connect } from 'react-redux';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import Input from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { IReduxState } from '../../../store/global/types';
import { inputValidator } from '../../../utils';
import { getProfiles } from '../../../store/profiles/index';
import { getAccounts } from '../../../store/accounts/index';
import { getEnvironments } from '../../../store/environments/index';
import { getDevices } from '../../../store/devices/index';
import { getBrowsers } from '../../../store/browsers/index';
import styles from './TestReportForm.module.css';
import * as _ from 'lodash';
import Can from '../../../components/Can/Can';
import { formatFieldToEdit } from '../../../utils/FieldFormatHelper';

interface ITestReportFormProps {
  token: string;
  testReport: any;
  testReportFromProps?: any;
  loading: boolean;
  profiles: any;
  profilesPages: number;
  accounts: any;
  accountsPages: number;
  environments: any;
  environmentsPages: number;
  devices: any;
  devicesPages: number;
  browsers: any;
  browsersPages: number;
  onSubmitTestReport(testReportPayload: any): any;
  onGetProfiles(token:string, accountId: number): any;
  onGetAccounts(token:string): any;
  onGetEnvironments(token:string): any;
  onGetDevices(token:string): any;
  onGetBrowsers(token:string): any;
}

const sanitizeFieldValue = (value: any) => value === "" || _.isUndefined(value) || _.isNaN(value) ? null : value;

const formTypeOptions = [
  {
    label: 'web',
    value: 'web',
  },
  {
    label: 'mobile',
    value: 'mobile',
  },
  {
    label: 'support',
    value: 'support',
  },
];

const booleanOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const statusOptions = [
  {
    label: 'Passed',
    value: 'Passed',
  },
  {
    label: 'Failed',
    value: 'Failed',
  },
];

const formatStandardOptions = (options: any) => {
  return options.map((option: any) => {
    return {
      label: option.description,
      value: option.id,
    }
  })
};

const initialState = {
  formControls: {
    account: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    projectKey: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 100,
      },
      valid: false,
      touched: false
    },
    project: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 255,
      },
      valid: false,
      touched: false
    },
    formType: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    issueKey: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 100,
      },
      valid: false,
      touched: false
    },
    tester: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    developer: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    environment: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    device: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    browser: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    version: {
      value: '',
      validation: {
        maxLength: 20,
      },
      valid: true,
      touched: false
    },
    build: {
      value: '',
      validation: {
        maxLength: 20,
      },
      valid: true,
      touched: false
    },
    os: {
      value: '',
      validation: {
        minLength: 2,
        maxLength: 100,
      },
      valid: true,
      touched: false
    },
    regression: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    applications: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    url: {
      value: '',
      validation: {
        isURL: true,
      },
      valid: true,
      touched: false
    },
    returned: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
    status: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    notes: {
      value: '',
      validation: {
        minLength: 2,
        maxLength: 500,
      },
      valid: true,
      touched: false
    },
  },
  formIsValid: false,
  showModal: false,
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: IReduxState) => {
  return {
    token: state.login.token,
    testReport: state.testReports.currentTestReport,
    loading: loadingAppSelector(state),
    accounts: state.accounts.accountsList,
    accountsPages: state.accounts.accountsPages,
    profiles: state.profiles.profilesList,
    profilesPages: state.profiles.profilesPages,
    environments: state.environments.environmentsList,
    environmentsPages: state.environments.environmentsPages,
    devices: state.devices.devicesList,
    devicesPages: state.devices.devicesPages,
    browsers: state.browsers.browsersList,
    browsersPages: state.browsers.browsersPages,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetProfiles: (token: string, accountId: number) => dispatch(getProfiles(token, -1, -1, 'account.id', accountId)),
    onGetAccounts: (token: string) => dispatch(getAccounts(token, -1, -1, null, null)),
    onGetEnvironments: (token: string) => dispatch(getEnvironments(token, -1, -1, null, null)),
    onGetDevices: (token: string) => dispatch(getDevices(token, -1, -1, null, null)),
    onGetBrowsers: (token: string) => dispatch(getBrowsers(token, -1, -1, null, null)),
  }
};

const formatTestReportFromProps = (testReportFromProps: any): any => {
  return {
    account: formatFieldToEdit(testReportFromProps.account.id),
    projectKey: formatFieldToEdit(testReportFromProps.projectKey),
    project: formatFieldToEdit(testReportFromProps.project),
    formType: formatFieldToEdit(testReportFromProps.formType),
    issueKey: formatFieldToEdit(testReportFromProps.issueId),
    tester: formatFieldToEdit(testReportFromProps.reviewerProfile.id),
    developer: formatFieldToEdit(testReportFromProps.reviewedProfile.id),
    environment: formatFieldToEdit(testReportFromProps.environment.id),
    device: formatFieldToEdit(testReportFromProps.device.id),
    browser: formatFieldToEdit(testReportFromProps.browser.id),
    version: formatFieldToEdit(testReportFromProps.version),
    build: formatFieldToEdit(testReportFromProps.build),
    os: formatFieldToEdit(testReportFromProps.os),
    regression: formatFieldToEdit(testReportFromProps.regression),
    applications: formatFieldToEdit(testReportFromProps.applications),
    url: formatFieldToEdit(testReportFromProps.url),
    returned: formatFieldToEdit(testReportFromProps.returned),
    status: formatFieldToEdit(testReportFromProps.status),
    notes: formatFieldToEdit(testReportFromProps.notes),
  };
};

class TestReportForm extends React.Component<ITestReportFormProps>{
  constructor(props: ITestReportFormProps) {
    super(props);
    this.state = initialState;
  }

  state: State;
  componentDidMount(): void {
    const { testReportFromProps, profiles, accounts, environments, devices, browsers} = this.props;
    if (testReportFromProps) {
      const formattedTestReport = formatTestReportFromProps(testReportFromProps);
      const { formControls } = this.state;
      let updatedFormControls = { ...formControls };
      Object.keys(formControls).forEach((key: string) => {
        const updatedFormInput= {
          ...updatedFormControls[key as FormControlKey],
          value: formattedTestReport[key],
          valid: inputValidator(formattedTestReport[key], formControls[key as FormControlKey].validation),
          touched: true,
        };
        updatedFormControls = {
          ...updatedFormControls,
          [key]: updatedFormInput,
        };
      });
      if (testReportFromProps.reviewerProfileId && !_.some(profiles, {id: testReportFromProps.reviewerProfileId})) {
        profiles.push(testReportFromProps.reviewerProfile);
      }
      if (testReportFromProps.reviewedProfileId && !_.some(profiles, {id: testReportFromProps.reviewedProfileId})) {
        profiles.push(testReportFromProps.reviewedProfile);
      }
      if (testReportFromProps.account.id && !_.some(accounts, {id: testReportFromProps.account.id})) {
        accounts.push(testReportFromProps.account);
      }
      if (testReportFromProps.environment.id && !_.some(environments, {id: testReportFromProps.environment.id})) {
        environments.push(testReportFromProps.environment);
      }
      if (testReportFromProps.device.id && !_.some(devices, {id: testReportFromProps.device.id})) {
        devices.push(testReportFromProps.device);
      }
      if (testReportFromProps.browser.id && !_.some(browsers, {id: testReportFromProps.browser.id})) {
        browsers.push(testReportFromProps.browser);
      }
      this.setState({ formControls: updatedFormControls });
    }
  }

  resetProfileDropdowns = () => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const testerInput = {
      ...formControls['tester'],
      value: '',
      valid: false,
      touched: false,
    };
    const developerInput = {
      ...formControls['developer'],
      value: '',
      valid: false,
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      tester: testerInput,
      developer: developerInput,
    };

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid: false });
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { value } = isSelect? event : event.target;
    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const changed = value !== formControls[inputIdentifier].value;
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value,
      valid: inputValidator(value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid }, () => {
      if (inputIdentifier === 'account' && changed) {
        this.resetProfileDropdowns();
      }
    });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state.
    const { formControls } = this.state;
    const { value } = isSelect? event : event.target;
    let validations = {};
    if (!isSelect) {
      const curedValue= !_.isEmpty(value.toString().trim())? value : '';
      validations = {
        valid: inputValidator(curedValue, formControls[inputIdentifier].validation),
        value: curedValue,
      };
    };

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
      ...validations
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };
    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;

    });

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  profileDropdownHandler = () => {
    const { profiles, onGetProfiles, token, profilesPages} = this.props;
    const { account } = this.state.formControls;
    if (_.isEmpty(profiles) || profilesPages !== 1 || (profiles && +(profiles[0].accountId) !== +account.value)) {
      onGetProfiles(token, +account.value);
    }
  };

  accountDropdownHandler = () => {
    const { accounts, onGetAccounts, token, accountsPages} = this.props;
    if (_.isEmpty(accounts) || accountsPages !== 1) {
      onGetAccounts(token);
    }
  };

  environmentDropdownHandler = () => {
    const { environments, onGetEnvironments, token, environmentsPages} = this.props;
    if (_.isEmpty(environments) || environmentsPages !== 1) {
      onGetEnvironments(token);
    }
  };

  deviceDropdownHandler = () => {
    const { devices, onGetDevices, token, devicesPages} = this.props;
    if (_.isEmpty(devices) || devicesPages !== 1) {
      onGetDevices(token);
    }
  };

  browserDropdownHandler = () => {
    const { browsers, onGetBrowsers, token, browsersPages} = this.props;
    if (_.isEmpty(browsers) || browsersPages !== 1) {
      onGetBrowsers(token);
    }
  };

  testReportSubmitHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { onSubmitTestReport } = this.props;
    const { account, tester, developer, project, projectKey, issueKey, environment, device, browser, version, build, os, regression, applications, url, returned, status, notes, formType } = this.state.formControls;
    await onSubmitTestReport({
      accountId: sanitizeFieldValue(account.value),
      reviewerProfileId: sanitizeFieldValue(tester.value),
      reviewedProfileId: sanitizeFieldValue(developer.value),
      project: sanitizeFieldValue(project.value),
      formType: sanitizeFieldValue(formType.value),
      projectKey: sanitizeFieldValue(projectKey.value),
      issueId: sanitizeFieldValue(issueKey.value),
      environmentId: sanitizeFieldValue(environment.value),
      deviceId: sanitizeFieldValue(device.value),
      browserId: sanitizeFieldValue(browser.value),
      version: sanitizeFieldValue(version.value),
      build: sanitizeFieldValue(build.value),
      os: sanitizeFieldValue(os.value),
      regression: sanitizeFieldValue(regression.value),
      applications: sanitizeFieldValue(applications.value),
      url: sanitizeFieldValue(url.value),
      returned: sanitizeFieldValue(returned.value),
      status: sanitizeFieldValue(status.value),
      notes: sanitizeFieldValue(notes.value),
    });
  };

  renderAdminOnlyFields = () => {
    const { account, projectKey, project, tester, formType} = this.state.formControls;
    const { loading, accounts, profiles} = this.props;

    return (
      <>
        <Dropdown
          value={account.value}
          label="Account"
          disabled={loading}
          options={formatStandardOptions(accounts)}
          shouldValidate={true}
          required
          onMenuOpen={this.accountDropdownHandler}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'account', true)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'account', true)}
        />
        <Input
          elementType="input"
          elementConfig={{type: 'text', placeholder: 'Type the project key',}}
          value={projectKey.value}
          shouldValidate={true}
          valid={projectKey.valid}
          touched={projectKey.touched}
          label="Project Key"
          disabled={loading}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'projectKey', false)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'projectKey', false)}
        />
        <Input
          elementType="input"
          elementConfig={{type: 'text', placeholder: 'Type the project description', }}
          value={project.value}
          shouldValidate={true}
          valid={project.valid}
          touched={project.touched}
          label="Project"
          disabled={loading}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'project', false)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'project', false)}
        />
        <Dropdown
          value={formType.value}
          label="Report Type"
          disabled={loading}
          options={formTypeOptions}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'formType', true)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'formType', true)}
        />
        <Dropdown
          value={tester.value}
          label="Tester"
          disabled={loading}
          options={formatStandardOptions(profiles)}
          shouldValidate={true}
          required
          placeholder="Select a Tester"
          onMenuOpen={this.profileDropdownHandler}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'tester', true)}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'tester', true)}
        />
      </>
    );
  };

  render() {
    const { loading, testReportFromProps, environments, devices, browsers, profiles } = this.props;
    const { environment, device, browser, version, build, os, regression, applications, url, returned, status, notes, issueKey, developer, account } = this.state.formControls;
    const { formIsValid } = this.state;

    const spinner = loading ? <Spinner type="linear" /> : null;
    const filteredProfiles = profiles.filter((profile: any) => profile.accountId === account.value);

    return (
        <div className={styles.Form}>
          <div className={styles.Spinner}>
            { spinner }
          </div>
          <form>
            <Can
              perform="testReport:editAdminOnlyFields"
              data={null}
              yes={this.renderAdminOnlyFields}
              no={() => null}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type the issue key',}}
              value={issueKey.value}
              shouldValidate={true}
              valid={issueKey.valid}
              touched={issueKey.touched}
              label="Issue Key"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'issueKey', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'issueKey', false)}
            />
            <Dropdown
              value={developer.value}
              label="Developer"
              disabled={loading}
              options={formatStandardOptions(filteredProfiles)}
              shouldValidate={true}
              required
              placeholder="Select a Developer"
              onMenuOpen={this.profileDropdownHandler}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'developer', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'developer', true)}
            />
            <Dropdown
              value={environment.value}
              label="Environment"
              disabled={loading}
              options={formatStandardOptions(environments)}
              shouldValidate={true}
              placeholder="Select an Environment"
              onMenuOpen={this.environmentDropdownHandler}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'environment', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'environment', true)}
            />
            <Dropdown
              value={device.value}
              label="Device"
              disabled={loading}
              options={formatStandardOptions(devices)}
              shouldValidate={true}
              placeholder="Select an Device"
              onMenuOpen={this.deviceDropdownHandler}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'device', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'device', true)}
            />
            <Dropdown
              value={browser.value}
              label="Browser"
              disabled={loading}
              options={formatStandardOptions(browsers)}
              shouldValidate={true}
              placeholder="Select an Browser"
              onMenuOpen={this.browserDropdownHandler}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'browser', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'browser', true)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type the version',}}
              value={version.value}
              shouldValidate={true}
              valid={version.valid}
              touched={version.touched}
              label="Version"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'version', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'version', false)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type the build number',}}
              value={build.value}
              shouldValidate={true}
              valid={build.valid}
              touched={build.touched}
              label="Build"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'build', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'build', false)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type an os',}}
              value={os.value}
              shouldValidate={true}
              valid={os.valid}
              touched={os.touched}
              label="OS"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'os', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'os', false)}
            />
            <Dropdown
              value={regression.value}
              label="Regression"
              disabled={loading}
              options={booleanOptions}
              shouldValidate={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'regression', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'regression', true)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type the applications',}}
              value={applications.value}
              shouldValidate={true}
              valid={applications.valid}
              touched={applications.touched}
              label="Applications"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'applications', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'applications', false)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type a url',}}
              value={url.value}
              shouldValidate={true}
              valid={url.valid}
              touched={url.touched}
              label="URL"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'url', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'url', false)}
            />
            <Dropdown
              value={returned.value}
              label="Failed UAT"
              disabled={loading}
              options={booleanOptions}
              shouldValidate={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'returned', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'returned', true)}
            />
            <Dropdown
              value={status.value}
              label="QA Status"
              disabled={loading}
              options={statusOptions}
              shouldValidate={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'status', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'status', true)}
            />
            <Input
              elementType="textarea"
              elementConfig={{type: 'textarea', placeholder: 'Type some notes',}}
              value={notes.value}
              shouldValidate={true}
              valid={notes.valid}
              touched={notes.touched}
              label="Notes"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'notes', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'notes', false)}
              limitText= {true}
            />
            <div className={`d-flex justify-content-center ${styles.Buttons}`}>
              <Button type="submit" color="success" disabled={!formIsValid || loading} onClick={(event: React.MouseEvent<HTMLElement>) => this.testReportSubmitHandler(event)}>
                Save
              </Button>
              <div className={styles.Divider}/>
              <NavigationItem link={testReportFromProps? `/testReports/${testReportFromProps.id}/details` : '/testReports'} exact>
                <Button type="submit" color="danger" disabled={loading}>
                  Cancel
                </Button>
              </NavigationItem>
            </div>
          </form>
        </div>
    );
  }

}

export default connect(matchStateToProps, matchDispatchToProps)(TestReportForm);
