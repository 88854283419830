interface IDefectUpdateData {
  description?: string;
  severity?: boolean;
}

const severityMapper: any = {
  'Minor': 1,
  'Moderate': 2,
  'Major': 3,
  'Critical': 4,
};

export const identifyDefectChanges = (prevDefect: any, newDefect: any) => {
  const changes: IDefectUpdateData = {};
  if (newDefect.description && (prevDefect.description !== newDefect.description)) {
    changes.description = newDefect.description;
  }

  if (severityMapper[prevDefect.severity] !== newDefect.severity) {
    changes.severity = newDefect.severity;
  }
  return changes;
}