import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleEnvironment, deleteEnvironmentById, getEnvironmentById } from '../../../store/environments';
import { formatEnvironmentActive, IEnvironmentActive } from '../../../utils/environments/Formatters';
import styles from './EnvironmentDetails.module.css';
import * as _ from 'lodash';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IEnvironmentDetailsProps {
  token: string;
  match: any;
  environment: any;
  index: number;
  location: any;
  history: any;
  loading: boolean;
  environmentListRedirectPath: string;
  onGetEnvironmentById(id: string, token: string): any;
  onClearSingleEnvironment(): any;
  onDeleteEnvironmentById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    environment: state.environments.currentEnvironment,
    index: state.environments.index,
    environmentListRedirectPath: state.environments.environmentListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetEnvironmentById: (id: string, token: string) => dispatch(getEnvironmentById(id, token)),
    onClearSingleEnvironment: () => dispatch(clearSingleEnvironment()),
    onDeleteEnvironmentById: (id: string, token: string) => dispatch(deleteEnvironmentById(id, token)),
  }
};
const formatOptionalField = (field: any) => !(_.isNull(field) || _.isUndefined(field))? field : '-'; 

const formatEnvironmentDetails = (environment: any) => {
  return {
    ID: environment.id,
    Description: formatOptionalField(environment.description),
    'Active': formatEnvironmentActive[environment.active as keyof IEnvironmentActive],
  }
};

class EnvironmentDetails extends React.Component<IEnvironmentDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetEnvironmentById } = this.props;
    onGetEnvironmentById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetEnvironmentById } = this.props;
      onGetEnvironmentById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleEnvironment } = this.props;
    onClearSingleEnvironment();
  }

  onEditButtonClickHandler = () => {
    const { history, environment } = this.props;
    history.push(`/environments/${environment.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteEnvironmentById, environment, token, history } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteEnvironmentById(environment.id, token);
    history.push('/');
  };

  render() {
    const { environment, match, loading, index, environmentListRedirectPath } = this.props;
    const { showModal } = this.state;
    const environmentDetails = environment? <DescriptionList entity={formatEnvironmentDetails(environment)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const environmentListURL = environmentListRedirectPath? environmentListRedirectPath : `/environments?&page=${index? index : 1}`;

    return (
      <div className={styles.EnvironmentDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={environmentListURL} exact>
              Environments
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={environmentListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.EnvironmentDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {environmentDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this environment?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(EnvironmentDetails);
