import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleEnvironment, getEnvironmentById, updateEnvironment } from '../../../store/environments';
import EnvironmentForm from '../EnvironmentForm/EnvironmentForm';
import styles from './EnvironmentEdit.module.css';
import * as _ from 'lodash';
import * as EnvironmentValidator from '../../../utils/environments/EnvironmentValidator';

interface IEnvironmentEditProps {
  token: string;
  environment: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  environmentsError: any;
  environmentListRedirectPath: string;
  onUpdateEnvironment(token: string, environment: any, id: string): any;
  onGetEnvironmentById(token: string, id: string): any;
  onClearSingleEnvironment(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    environment: state.environments.currentEnvironment,
    index: state.environments.index,
    loading: loadingAppSelector(state),
    environmentListRedirectPath: state.environments.environmentListRedirectPath,
    environmentsError: state.environments.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateEnvironment: (token: string, environment: any, id: string) => dispatch(updateEnvironment(token, environment, id)),
    onGetEnvironmentById: (token: string, id: string) => dispatch(getEnvironmentById(id, token)),
    onClearSingleEnvironment: () => dispatch(clearSingleEnvironment()),
  }
};

class EnvironmentEdit extends React.Component<IEnvironmentEditProps>{

  async componentDidMount() {
    const { match, onGetEnvironmentById, token } = this.props;
    await onGetEnvironmentById(token, match.params.id);
  }

  onSubmitEnvironment = async (environmentPayload: any) => {
    const { onUpdateEnvironment, environment, token, history } = this.props;
    const environmentChanges = EnvironmentValidator.identifyEnvironmentChanges(environment, environmentPayload);
    if (_.isEmpty(environmentChanges)) {
      history.push(`/environments/${environment.id}/details`);
      return;
    }
    await onUpdateEnvironment(token, environmentChanges, environment.id);
    const { environmentsError } = this.props;
    if (!environmentsError) {
      history.push(`/environments/${environment.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleEnvironment } = this.props;
    onClearSingleEnvironment();
  }

  render() {
    const { environment, match, loading, index, environmentListRedirectPath } = this.props;
    let environmentForm = null;
    if (environment && environment.id.toString() === match.params.id) {
      environmentForm = <EnvironmentForm onSubmitEnvironment={this.onSubmitEnvironment} environmentFromProps={environment}  />;
    }
    const environmentListURL = environmentListRedirectPath? environmentListRedirectPath : `/environments?&page=${index? index : 1}`;

    return (
      <div className={styles.EnvironmentEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={environmentListURL} exact>
                Environments
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/environments/${match.params.id}/details`} exact>
                {environment? environment.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/environments/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { environmentForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(EnvironmentEdit);
