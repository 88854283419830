interface IProfileUpdateData {
  description?: string;
  email?: string;
  token?: string;
  userId?: boolean;
  accountId?: boolean;
  active?: boolean;

}

export const identifyProfileChanges = (prevProfile: any, newProfile: any) => {
  const changes: IProfileUpdateData = {};
  if (newProfile.description && (prevProfile.description !== newProfile.description)) {
    changes.description = newProfile.description;
  }
  if (newProfile.email && prevProfile.email !== newProfile.email) {
    changes.email = newProfile.email;
  }
  if (newProfile.token && prevProfile.token !== newProfile.token) {
    changes.token = newProfile.token;
  }
  if (newProfile.userId && prevProfile.user.id !== newProfile.userId) {
    changes.userId = newProfile.userId;
  }
  if (newProfile.accountId && prevProfile.account.id !== newProfile.accountId) {
    changes.accountId = newProfile.accountId;
  }
  if (prevProfile.active !== newProfile.active) {
    changes.active = newProfile.active;
  }

  return changes;
}