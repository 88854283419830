import * as React from 'react';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import styles from './Toolbar.module.css';

interface IToolbar {
  onDrawerToggleClick?(): any;
}

const toolbar: React.FunctionComponent<IToolbar> = (props) => {
  const { onDrawerToggleClick } = props;
  return (
    <header>
      <DrawerToggle onClick={onDrawerToggleClick}/>
      <NavigationItems classes={styles.DesktopOnly}/>
    </header>
  );
};

export default toolbar;
