import * as api from '../../services/api';

import {
  CLEAR_SINGLE_TEST_CASE,
  CREATE_SINGLE_TEST_CASE_FAIL,
  CREATE_SINGLE_TEST_CASE_START,
  CREATE_SINGLE_TEST_CASE_SUCCESS,
  DELETE_SINGLE_TEST_CASE_FAIL,
  DELETE_SINGLE_TEST_CASE_START,
  DELETE_SINGLE_TEST_CASE_SUCCESS,
  FETCH_SINGLE_TEST_CASE_FAIL,
  FETCH_SINGLE_TEST_CASE_START,
  FETCH_SINGLE_TEST_CASE_SUCCESS,
  FETCH_TEST_CASES_FAIL,
  FETCH_TEST_CASES_START,
  FETCH_TEST_CASES_SUCCESS,
  ITestCaseActionTypes,
  SET_TEST_CASE_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_TEST_CASE_FAIL,
  UPDATE_SINGLE_TEST_CASE_START,
  UPDATE_SINGLE_TEST_CASE_SUCCESS,
} from './types';

export const fetchSingleTestCaseStart = (): ITestCaseActionTypes => {
    return {
      type: FETCH_SINGLE_TEST_CASE_START
    };
};

export const fetchSingleTestCaseSuccess = (currentTestCase: {}): ITestCaseActionTypes => {
    return {
      type: FETCH_SINGLE_TEST_CASE_SUCCESS,
      currentTestCase,
    };
};

export const fetchSingleTestCaseFail = (error: Error): ITestCaseActionTypes => {
  return {
    type: FETCH_SINGLE_TEST_CASE_FAIL,
    error,
  };
};

export const clearSingleTestCase = (): ITestCaseActionTypes => {
  return {
    type: CLEAR_SINGLE_TEST_CASE,
  };
};

export const createSingleTestCaseStart = (): ITestCaseActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_CASE_START
  };
};

export const createSingleTestCaseSuccess = (currentTestCase: {}): ITestCaseActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_CASE_SUCCESS,
    currentTestCase,
  };
};

export const createSingleTestCaseFail = (error: Error): ITestCaseActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_CASE_FAIL,
    error,
  };
};

export const updateSingleTestCaseStart = (): ITestCaseActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_CASE_START
  };
};

export const updateSingleTestCaseSuccess = (currentTestCase: {}): ITestCaseActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_CASE_SUCCESS,
    currentTestCase,
  };
};

export const updateSingleTestCaseFail = (error: Error): ITestCaseActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_CASE_FAIL,
    error,
  };
};

export const deleteSingleTestCaseStart = (): ITestCaseActionTypes => {
  return {
    type: DELETE_SINGLE_TEST_CASE_START,
  };
};

export const deleteSingleTestCaseSuccess = (): ITestCaseActionTypes => {
  return {
    type: DELETE_SINGLE_TEST_CASE_SUCCESS,
  };
};

export const deleteSingleTestCaseFail= (error: Error): ITestCaseActionTypes => {
  return {
    type: DELETE_SINGLE_TEST_CASE_FAIL,
    error
  };
};

export const fetchTestCasesStart = (): ITestCaseActionTypes => {
  return {
    type: FETCH_TEST_CASES_START
  };
};

export const fetchTestCasesSuccess = (testCasesList: [], testCasesPages: number, index: number): ITestCaseActionTypes => {
  return {
    type: FETCH_TEST_CASES_SUCCESS,
    testCasesList,
    testCasesPages,
    index,
  };
};

export const fetchTestCasesFail = (error: Error): ITestCaseActionTypes => {
  return {
    type: FETCH_TEST_CASES_FAIL,
    error,
  };
};

export const setTestCaseListRedirectPath = (path: string): ITestCaseActionTypes => {
  return {
    type: SET_TEST_CASE_LIST_REDIRECT_PATH,
    path
  };
};

export const getTestCaseById = (id: string, testReportId: number, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleTestCaseStart());
    return api.getTestCaseById(token, testReportId, id)
      .then(response => {
        dispatch(fetchSingleTestCaseSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleTestCaseFail(error));
      });
  }
};

export const deleteTestCaseById = (id: string, testReportId: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleTestCaseStart());
     return api.deleteTestCaseById(token, id, testReportId)
        .then(response => {
          dispatch(deleteSingleTestCaseSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleTestCaseFail(error));
        });
  }
};

export const getTestCases = (token: string, testReportId: number, index: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchTestCasesStart());
    return api.getTestCases(token, testReportId, index, searchBy, search)
      .then(response => {
        dispatch(fetchTestCasesSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchTestCasesFail(error));
      });
  }
};

export const createTestCase = (token: string, testCase: any, testReportId: string) => {
  return (dispatch: any) => {
    dispatch(createSingleTestCaseStart());
    return api.createTestCase(token, testCase, testReportId)
      .then(response => {
        dispatch(createSingleTestCaseSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleTestCaseFail(error));
      });
  }
};

export const updateTestCase = (token: string, testCase: any, id: string, testReportId: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleTestCaseStart());
    return api.updateTestCase(token, testCase, id, testReportId)
      .then(response => {
        dispatch(updateSingleTestCaseSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleTestCaseFail(error));
      });
  }
};
