import * as React from 'react';

interface IPagination {
  totalPages: number;
  currentPage: number;
  onPageSelection(key: string, value: string): any;
};

const pagination: React.FunctionComponent<IPagination> = (props) => {
  const { totalPages, currentPage, onPageSelection } = props;
  const maxDisplayedPages = 10;
  let displayedTotalPages = totalPages > maxDisplayedPages ? maxDisplayedPages : totalPages;
  let pagesArray = Array.from(Array(displayedTotalPages).keys());

  if (totalPages > maxDisplayedPages ) {
    displayedTotalPages = maxDisplayedPages;
    if (currentPage > displayedTotalPages) {
      pagesArray = [];
      for (let i = currentPage - maxDisplayedPages; i < currentPage; i ++) {
        pagesArray.push(i);
      }
    }
  }

  if (!totalPages || currentPage < 1 || currentPage > totalPages) {
    return null;
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${ currentPage === 1? 'disabled' : null}`}>
          <button className="page-link" onClick={onPageSelection.bind(null, 'page', (1).toString())} tabIndex= {-1}>{`<<`}</button>
        </li>
        <li className={`page-item ${ currentPage === 1? 'disabled' : null}`}>
          <button className="page-link" onClick={onPageSelection.bind(null, 'page', (currentPage-1).toString())} tabIndex= {-1}>Previous</button>
        </li>
        {
          pagesArray.map((value, index) => (
            <li key={index}  className={`page-item ${(value+1) === currentPage? 'active': null}`} >
              <button className="page-link" onClick={onPageSelection.bind(null, 'page',(value+1).toString())}>
              {value+1}
              </button>
            </li>
          ))
        }
        <li className={`page-item ${ currentPage === totalPages? 'disabled' : null}`}>
          <button className="page-link" onClick={onPageSelection.bind(null, 'page', (currentPage+1).toString())}>Next</button>
        </li>
        <li className={`page-item ${ currentPage === totalPages? 'disabled' : null}`}>
          <button className="page-link" onClick={onPageSelection.bind(null, 'page', (totalPages).toString())}>{`>>`}</button>
        </li>
      </ul>
    </nav>
  );
}

export default pagination;
