import {
  CLEAR_SINGLE_DEVICE,
  CREATE_SINGLE_DEVICE_FAIL,
  CREATE_SINGLE_DEVICE_START,
  CREATE_SINGLE_DEVICE_SUCCESS,
  DELETE_SINGLE_DEVICE_FAIL,
  DELETE_SINGLE_DEVICE_START,
  DELETE_SINGLE_DEVICE_SUCCESS,
  FETCH_DEVICES_FAIL,
  FETCH_DEVICES_START,
  FETCH_DEVICES_SUCCESS,
  FETCH_SINGLE_DEVICE_FAIL,
  FETCH_SINGLE_DEVICE_START,
  FETCH_SINGLE_DEVICE_SUCCESS,
  IClearSingleDeviceAction,
  ICreateSingleDeviceFailAction,
  ICreateSingleDeviceStartAction,
  ICreateSingleDeviceSuccessAction,
  IDeleteSingleDeviceFailAction,
  IDeleteSingleDeviceStartAction,
  IDeleteSingleDeviceSuccessAction,
  IDeviceActionTypes,
  IDeviceState,
  IFetchDevicesFailAction,
  IFetchDevicesStartAction,
  IFetchDevicesSuccessAction,
  IFetchSingleDeviceFailAction,
  IFetchSingleDeviceStartAction,
  IFetchSingleDeviceSuccessAction,
  ISetDeviceListRedirectPath,
  IUpdateSingleDeviceFailAction,
  IUpdateSingleDeviceStartAction,
  IUpdateSingleDeviceSuccessAction,
  SET_DEVICE_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_DEVICE_FAIL,
  UPDATE_SINGLE_DEVICE_START,
  UPDATE_SINGLE_DEVICE_SUCCESS,
} from './types';

const initialState: IDeviceState = {
  currentDevice: null,
  devicesList: [],
  devicesPages: 0,
  index: 0,
  deviceListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleDeviceStart = (state: IDeviceState, action: IFetchSingleDeviceStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleDeviceSuccess = (state: IDeviceState, action: IFetchSingleDeviceSuccessAction) => {
  return {
    ...state,
    currentDevice: action.currentDevice,
    error: null,
    loading: false
  };
};

const fetchSingleDeviceFail = (state: IDeviceState, action: IFetchSingleDeviceFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleDevice = (state: IDeviceState, action: IClearSingleDeviceAction) => {
  return {
    ...state,
    currentDevice: null
  };
};

const createSingleDeviceStart = (state: IDeviceState, action: ICreateSingleDeviceStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleDeviceSuccess = (state: IDeviceState, action: ICreateSingleDeviceSuccessAction) => {
  return {
    ...state,
    currentDevice: action.currentDevice,
    error: null,
    loading: false
  };
};

const createSingleDeviceFail = (state: IDeviceState, action: ICreateSingleDeviceFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleDeviceStart = (state: IDeviceState, action: IUpdateSingleDeviceStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleDeviceSuccess = (state: IDeviceState, action: IUpdateSingleDeviceSuccessAction) => {
  return {
    ...state,
    currentDevice: action.currentDevice,
    error: null,
    loading: false
  };
};

const updateSingleDeviceFail = (state: IDeviceState, action: IUpdateSingleDeviceFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleDeviceStart = (state: IDeviceState, action: IDeleteSingleDeviceStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleDeviceSuccess = (state: IDeviceState, action: IDeleteSingleDeviceSuccessAction) => {
  return {
    ...state,
    currentDevice: null,
    error: null,
    loading: false
  };
};

const deleteSingleDeviceFail = (state: IDeviceState, action: IDeleteSingleDeviceFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchDevicesStart = (state: IDeviceState, action: IFetchDevicesStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchDevicesSuccess = (state: IDeviceState, action: IFetchDevicesSuccessAction) => {
  return {
    ...state,
    devicesList: action.devicesList,
    error: null,
    loading: false,
    devicesPages: action.devicesPages,
    index: action.index,
  };
};

const fetchDevicesFail = (state: IDeviceState, action: IFetchDevicesFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setDeviceListRedirectPath = (state: IDeviceState, action: ISetDeviceListRedirectPath) => {
  return {
    ...state,
    deviceListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IDeviceActionTypes): IDeviceState => {
  switch ( action.type ) {
    case FETCH_SINGLE_DEVICE_START: return fetchSingleDeviceStart(state, action);
    case FETCH_SINGLE_DEVICE_SUCCESS: return fetchSingleDeviceSuccess(state, action);
    case FETCH_SINGLE_DEVICE_FAIL: return fetchSingleDeviceFail(state, action);
    case CREATE_SINGLE_DEVICE_START: return createSingleDeviceStart(state, action);
    case CREATE_SINGLE_DEVICE_SUCCESS: return createSingleDeviceSuccess(state, action);
    case CREATE_SINGLE_DEVICE_FAIL: return createSingleDeviceFail(state, action);
    case UPDATE_SINGLE_DEVICE_START: return updateSingleDeviceStart(state, action);
    case UPDATE_SINGLE_DEVICE_SUCCESS: return updateSingleDeviceSuccess(state, action);
    case UPDATE_SINGLE_DEVICE_FAIL: return updateSingleDeviceFail(state, action);
    case DELETE_SINGLE_DEVICE_START: return deleteSingleDeviceStart(state, action);
    case DELETE_SINGLE_DEVICE_SUCCESS: return deleteSingleDeviceSuccess(state, action);
    case DELETE_SINGLE_DEVICE_FAIL: return deleteSingleDeviceFail(state, action);
    case CLEAR_SINGLE_DEVICE: return clearSingleDevice(state, action);
    case FETCH_DEVICES_START: return fetchDevicesStart(state, action);
    case FETCH_DEVICES_SUCCESS: return fetchDevicesSuccess(state, action);
    case FETCH_DEVICES_FAIL: return fetchDevicesFail(state, action);
    case SET_DEVICE_LIST_REDIRECT_PATH: return setDeviceListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
