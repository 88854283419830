import * as React from 'react';
import NavigationItem from '../Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../UI/Button/Button';
import styles from './NotFound.module.css';

const notFound: React.FunctionComponent = (props) => {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-md-12">
            <h1>Oops!</h1>
            <h2>404 Not Found</h2>
            <div> Sorry, an error has occured, Requested page not found! </div>
            <div className={`d-flex justify-content-center ${styles.Button}`}>
              <NavigationItem link='/' exact>
                <Button type="button" color="danger">
                  Take Me Home
                </Button>
              </NavigationItem>
            </div>
        </div>
      </div>
    </div>
  );
};

export default notFound;
