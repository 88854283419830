import {
  CLEAR_SINGLE_USER,
  CREATE_SINGLE_USER_FAIL,
  CREATE_SINGLE_USER_START,
  CREATE_SINGLE_USER_SUCCESS,
  DELETE_SINGLE_USER_FAIL,
  DELETE_SINGLE_USER_START,
  DELETE_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_USER_FAIL,
  FETCH_SINGLE_USER_START,
  FETCH_SINGLE_USER_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  IClearSingleUserAction,
  ICreateSingleUserFailAction,
  ICreateSingleUserStartAction,
  ICreateSingleUserSuccessAction,
  IDeleteSingleUserFailAction,
  IDeleteSingleUserStartAction,
  IDeleteSingleUserSuccessAction,
  IFetchSingleUserFailAction,
  IFetchSingleUserStartAction,
  IFetchSingleUserSuccessAction,
  IFetchUsersFailAction,
  IFetchUsersStartAction,
  IFetchUsersSuccessAction,
  ISetUserListRedirectPath,
  IUpdateSingleUserFailAction,
  IUpdateSingleUserStartAction,
  IUpdateSingleUserSuccessAction,
  IUserActionTypes,
  IUserState,
  SET_USER_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_USER_FAIL,
  UPDATE_SINGLE_USER_START,
  UPDATE_SINGLE_USER_SUCCESS,
} from './types';

const initialState: IUserState = {
  currentUser: null,
  usersList: [],
  usersPages: 0,
  index: 0,
  userListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleUserStart = (state: IUserState, action: IFetchSingleUserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleUserSuccess = (state: IUserState, action: IFetchSingleUserSuccessAction) => {
  return {
    ...state,
    currentUser: action.currentUser,
    error: null,
    loading: false
  };
};

const fetchSingleUserFail = (state: IUserState, action: IFetchSingleUserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleUser = (state: IUserState, action: IClearSingleUserAction) => {
  return {
    ...state,
    currentUser: null
  };
};

const createSingleUserStart = (state: IUserState, action: ICreateSingleUserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleUserSuccess = (state: IUserState, action: ICreateSingleUserSuccessAction) => {
  return {
    ...state,
    currentUser: action.currentUser,
    error: null,
    loading: false
  };
};

const createSingleUserFail = (state: IUserState, action: ICreateSingleUserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleUserStart = (state: IUserState, action: IUpdateSingleUserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleUserSuccess = (state: IUserState, action: IUpdateSingleUserSuccessAction) => {
  return {
    ...state,
    currentUser: action.currentUser,
    error: null,
    loading: false
  };
};

const updateSingleUserFail = (state: IUserState, action: IUpdateSingleUserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleUserStart = (state: IUserState, action: IDeleteSingleUserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleUserSuccess = (state: IUserState, action: IDeleteSingleUserSuccessAction) => {
  return {
    ...state,
    currentUser: null,
    error: null,
    loading: false
  };
};

const deleteSingleUserFail = (state: IUserState, action: IDeleteSingleUserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchUsersStart = (state: IUserState, action: IFetchUsersStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchUsersSuccess = (state: IUserState, action: IFetchUsersSuccessAction) => {
  return {
    ...state,
    usersList: action.usersList,
    error: null,
    loading: false,
    usersPages: action.usersPages,
    index: action.index,
  };
};

const fetchUsersFail = (state: IUserState, action: IFetchUsersFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setUserListRedirectPath = (state: IUserState, action: ISetUserListRedirectPath) => {
  return {
    ...state,
    userListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IUserActionTypes): IUserState => {
  switch ( action.type ) {
    case FETCH_SINGLE_USER_START: return fetchSingleUserStart(state, action);
    case FETCH_SINGLE_USER_SUCCESS: return fetchSingleUserSuccess(state, action);
    case FETCH_SINGLE_USER_FAIL: return fetchSingleUserFail(state, action);
    case CREATE_SINGLE_USER_START: return createSingleUserStart(state, action);
    case CREATE_SINGLE_USER_SUCCESS: return createSingleUserSuccess(state, action);
    case CREATE_SINGLE_USER_FAIL: return createSingleUserFail(state, action);
    case UPDATE_SINGLE_USER_START: return updateSingleUserStart(state, action);
    case UPDATE_SINGLE_USER_SUCCESS: return updateSingleUserSuccess(state, action);
    case UPDATE_SINGLE_USER_FAIL: return updateSingleUserFail(state, action);
    case DELETE_SINGLE_USER_START: return deleteSingleUserStart(state, action);
    case DELETE_SINGLE_USER_SUCCESS: return deleteSingleUserSuccess(state, action);
    case DELETE_SINGLE_USER_FAIL: return deleteSingleUserFail(state, action);
    case CLEAR_SINGLE_USER: return clearSingleUser(state, action);
    case FETCH_USERS_START: return fetchUsersStart(state, action);
    case FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
    case FETCH_USERS_FAIL: return fetchUsersFail(state, action);
    case SET_USER_LIST_REDIRECT_PATH: return setUserListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
