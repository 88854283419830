import * as api from '../../services/api';

import {
  CLEAR_SINGLE_ENVIRONMENT,
  CREATE_SINGLE_ENVIRONMENT_FAIL,
  CREATE_SINGLE_ENVIRONMENT_START,
  CREATE_SINGLE_ENVIRONMENT_SUCCESS,
  DELETE_SINGLE_ENVIRONMENT_FAIL,
  DELETE_SINGLE_ENVIRONMENT_START,
  DELETE_SINGLE_ENVIRONMENT_SUCCESS,
  FETCH_ENVIRONMENTS_FAIL,
  FETCH_ENVIRONMENTS_START,
  FETCH_ENVIRONMENTS_SUCCESS,
  FETCH_SINGLE_ENVIRONMENT_FAIL,
  FETCH_SINGLE_ENVIRONMENT_START,
  FETCH_SINGLE_ENVIRONMENT_SUCCESS,
  IEnvironmentActionTypes,
  SET_ENVIRONMENT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_ENVIRONMENT_FAIL,
  UPDATE_SINGLE_ENVIRONMENT_START,
  UPDATE_SINGLE_ENVIRONMENT_SUCCESS,
} from './types';

export const fetchSingleEnvironmentStart = (): IEnvironmentActionTypes => {
    return {
      type: FETCH_SINGLE_ENVIRONMENT_START
    };
};

export const fetchSingleEnvironmentSuccess = (currentEnvironment: {}): IEnvironmentActionTypes => {
    return {
      type: FETCH_SINGLE_ENVIRONMENT_SUCCESS,
      currentEnvironment,
    };
};

export const fetchSingleEnvironmentFail = (error: Error): IEnvironmentActionTypes => {
  return {
    type: FETCH_SINGLE_ENVIRONMENT_FAIL,
    error,
  };
};

export const clearSingleEnvironment = (): IEnvironmentActionTypes => {
  return {
    type: CLEAR_SINGLE_ENVIRONMENT,
  };
};

export const createSingleEnvironmentStart = (): IEnvironmentActionTypes => {
  return {
    type: CREATE_SINGLE_ENVIRONMENT_START
  };
};

export const createSingleEnvironmentSuccess = (currentEnvironment: {}): IEnvironmentActionTypes => {
  return {
    type: CREATE_SINGLE_ENVIRONMENT_SUCCESS,
    currentEnvironment,
  };
};

export const createSingleEnvironmentFail = (error: Error): IEnvironmentActionTypes => {
  return {
    type: CREATE_SINGLE_ENVIRONMENT_FAIL,
    error,
  };
};

export const updateSingleEnvironmentStart = (): IEnvironmentActionTypes => {
  return {
    type: UPDATE_SINGLE_ENVIRONMENT_START
  };
};

export const updateSingleEnvironmentSuccess = (currentEnvironment: {}): IEnvironmentActionTypes => {
  return {
    type: UPDATE_SINGLE_ENVIRONMENT_SUCCESS,
    currentEnvironment,
  };
};

export const updateSingleEnvironmentFail = (error: Error): IEnvironmentActionTypes => {
  return {
    type: UPDATE_SINGLE_ENVIRONMENT_FAIL,
    error,
  };
};

export const deleteSingleEnvironmentStart = (): IEnvironmentActionTypes => {
  return {
    type: DELETE_SINGLE_ENVIRONMENT_START,
  };
};

export const deleteSingleEnvironmentSuccess = (): IEnvironmentActionTypes => {
  return {
    type: DELETE_SINGLE_ENVIRONMENT_SUCCESS,
  };
};

export const deleteSingleEnvironmentFail= (error: Error): IEnvironmentActionTypes => {
  return {
    type: DELETE_SINGLE_ENVIRONMENT_FAIL,
    error
  };
};

export const fetchEnvironmentsStart = (): IEnvironmentActionTypes => {
  return {
    type: FETCH_ENVIRONMENTS_START
  };
};

export const fetchEnvironmentsSuccess = (environmentsList: [], environmentsPages: number, index: number): IEnvironmentActionTypes => {
  return {
    type: FETCH_ENVIRONMENTS_SUCCESS,
    environmentsList,
    environmentsPages,
    index,
  };
};

export const fetchEnvironmentsFail = (error: Error): IEnvironmentActionTypes => {
  return {
    type: FETCH_ENVIRONMENTS_FAIL,
    error,
  };
};

export const setEnvironmentListRedirectPath = (path: string): IEnvironmentActionTypes => {
  return {
    type: SET_ENVIRONMENT_LIST_REDIRECT_PATH,
    path
  };
};

export const getEnvironmentById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleEnvironmentStart());
    return api.getEnvironmentById(token, id)
      .then(response => {
        dispatch(fetchSingleEnvironmentSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleEnvironmentFail(error));
      });
  }
};

export const deleteEnvironmentById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleEnvironmentStart());
     return api.deleteEnvironmentById(token, id)
        .then(response => {
          dispatch(deleteSingleEnvironmentSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleEnvironmentFail(error));
        });
  }
};

export const getEnvironments = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchEnvironmentsStart());
    return api.getEnvironments(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchEnvironmentsSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchEnvironmentsFail(error));
      });
  }
};

export const createEnvironment = (token: string, environment: any) => {
  return (dispatch: any) => {
    dispatch(createSingleEnvironmentStart());
    return api.createEnvironment(token, environment)
      .then(response => {
        dispatch(createSingleEnvironmentSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleEnvironmentFail(error));
      });
  }
};

export const updateEnvironment = (token: string, environment: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleEnvironmentStart());
    return api.updateEnvironment(token, environment, id)
      .then(response => {
        dispatch(updateSingleEnvironmentSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleEnvironmentFail(error));
      });
  }
};
