import rules, { IRules } from '../../utils/authorization/rules';
import { connect } from 'react-redux';
import * as React from 'react';

const check = (rules: IRules, role: keyof IRules, action: string, data: any) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;
  if (staticPermissions && (staticPermissions.includes(action) || staticPermissions.includes('all:all'))) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

interface ICanProps {
  perform: string;
  data: any;
  role: keyof IRules;
  yes(): any;
  no(): any;
};

const matchStateToProps = (state: any) => {
  return {
    role: state.login.role,
  };
};
const can: React.FunctionComponent<ICanProps> = (props) =>
  check(rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();
  
export default connect(matchStateToProps, null)(can);