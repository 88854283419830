import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleDevice, createDevice } from '../../../store/devices';
import DeviceForm from '../DeviceForm/DeviceForm';
import styles from './DeviceCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IDeviceCreateProps {
  token: string;
  device: any;
  index: number;
  history: any;
  loading: boolean;
  deviceListRedirectPath: string;
  onCreateDevice(token: string, device: any): any;
  onClearSingleDevice(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    device: state.devices.currentDevice,
    index: state.devices.index,
    deviceListRedirectPath: state.devices.deviceListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateDevice: (token: string, device: any) => dispatch(createDevice(token, device)),
    onClearSingleDevice: () => dispatch(clearSingleDevice()),
  }
};

class DeviceCreate extends React.Component<IDeviceCreateProps>{
  state: State = initialState;

  onSubmitDevice = async (devicePayload: any) => {
    const { onCreateDevice, token, history } = this.props;
    await onCreateDevice(token, devicePayload);
    const { device } = this.props;
    if (device) {
      history.push(`/devices/${device.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleDevice } = this.props;
    onClearSingleDevice();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, deviceListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const deviceListURL = deviceListRedirectPath? deviceListRedirectPath : `/devices?&page=${index? index : 1}`;

    return (
      <div className={styles.DeviceCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={deviceListURL} exact>
                Devices
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={deviceListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <DeviceForm onSubmitDevice={this.onSubmitDevice} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(DeviceCreate);
