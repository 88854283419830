import * as React from 'react';
import Logo from "../../Logo/Logo";
import Backdrop from '../../UI/Backdrop/Backdrop';
import NavigationItem from "../NavigationItems/NavigationItem/NavigationItem";
import styles from './SideDrawer.module.css';
import Can from '../../Can/Can';

interface ISideDrawer {
  open: boolean;
  onClose?(): any;
}

const renderAdminNavigationItems = () => {
  return (
    <>
      <div>
        <NavigationItem link="/" exact><Logo height="34px"/> QA Metrics ADM </NavigationItem>
      </div>
      <div>
        <NavigationItem link="/" exact>Test Reports</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/users" exact>Users</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/profiles" exact>Profiles</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/accounts" exact>Accounts</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/environments" exact>Environments</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/devices" exact>Devices</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/browsers" exact>Browsers</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/status" exact>Status</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/logout" position="right">Logout</NavigationItem>
      </div>
    </>
  );
};

const renderNonAdminNavigationItems = () => {
  return (
    <>
      <div>
        <NavigationItem link="/" exact><Logo height="34px"/> QA Metrics ADM </NavigationItem>
      </div>
      <div>
        <NavigationItem link="/" exact>Test Reports</NavigationItem>
      </div>
      <div>
        <NavigationItem link="/users" exact>Users</NavigationItem>
      </div>
    </>
  );
};

const sideDrawer: React.FunctionComponent<ISideDrawer> = ( props ) => {
  const { open, onClose } = props;
  let attachedClasses = [styles.SideDrawer, styles.Close];
  if (props.open) {
    attachedClasses = [styles.SideDrawer, styles.Open];
  }
  return (
    <>
      <header>
        <Backdrop show={open} onClick={onClose}/>
        <div className={attachedClasses.join(' ')} onClick={onClose}>
          <nav>
            <Can
              perform="adminRoutes"
              data={null}
              yes={renderAdminNavigationItems}
              no={renderNonAdminNavigationItems}
            />
          </nav>
        </div>
      </header>
    </>
  );
};

export default sideDrawer;
