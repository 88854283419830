import React from 'react';
import logoImage from '../../assets/images/logo.svg';

interface ILogo {
  height: string;
  style?: object;
  classes?: string;
}

const logo: React.FunctionComponent<ILogo> = (props) => {
  const { height, style, classes } = props;
  return (
      <img src={logoImage} style={{ height, ...style }} className={classes} alt="App Logo" />
  );
};

export default logo;
