export const FETCH_SINGLE_TEST_STATUS_START = 'FETCH_SINGLE_TEST_STATUS_START';
export const FETCH_SINGLE_TEST_STATUS_SUCCESS = 'FETCH_SINGLE_TEST_STATUS_SUCCESS';
export const FETCH_SINGLE_TEST_STATUS_FAIL = 'FETCH_SINGLE_TEST_STATUS_FAIL';
export const CLEAR_SINGLE_TEST_STATUS = 'CLEAR_SINGLE_TEST_STATUS';

export const FETCH_TEST_STATUS_START = "FETCH_TEST_STATUS_START";
export const FETCH_TEST_STATUS_SUCCESS = "FETCH_TEST_STATUS_SUCCESS";
export const FETCH_TEST_STATUS_FAIL = "FETCH_TEST_STATUS_FAIL";

export const CREATE_SINGLE_TEST_STATUS_START = 'CREATE_SINGLE_TEST_STATUS_START';
export const CREATE_SINGLE_TEST_STATUS_SUCCESS = 'CREATE_SINGLE_TEST_STATUS_SUCCESS';
export const CREATE_SINGLE_TEST_STATUS_FAIL = 'CREATE_SINGLE_TEST_STATUS_FAIL';

export const UPDATE_SINGLE_TEST_STATUS_START = 'UPDATE_SINGLE_TEST_STATUS_START';
export const UPDATE_SINGLE_TEST_STATUS_SUCCESS = 'UPDATE_SINGLE_TEST_STATUS_SUCCESS';
export const UPDATE_SINGLE_TEST_STATUS_FAIL = 'UPDATE_SINGLE_TEST_STATUS_FAIL';

// export const DELETE_SINGLE_BROWSER_START = 'DELETE_SINGLE_BROWSER_START';
// export const DELETE_SINGLE_BROWSER_SUCCESS = 'DELETE_SINGLE_BROWSER_SUCCESS';
// export const DELETE_SINGLE_BROWSER_FAIL = 'DELETE_SINGLE_BROWSER_FAIL';

export const SET_TEST_STATUS_LIST_REDIRECT_PATH = 'SET_TEST_STATUS_LIST_REDIRECT_PATH';

export interface ITestStatusState {
  current: {} | null;
  list: [];
  page: number;
  index: number;
  listRedirectPath: string | null;
  error: any;
  loading: boolean;
}

export interface IFetchSingleTestStatusStartAction {
  type: typeof FETCH_SINGLE_TEST_STATUS_START;
}

export interface IFetchSingleTestStatusSuccessAction {
  type: typeof FETCH_SINGLE_TEST_STATUS_SUCCESS;
  currentTestStatus: {};
}

export interface IFetchSingleTestStatusFailAction {
  type: typeof FETCH_SINGLE_TEST_STATUS_FAIL;
  error: object;
}

export interface IClearSingleTestStatusAction {
  type: typeof CLEAR_SINGLE_TEST_STATUS;
}

export interface ICreateSingleTestStatusStartAction {
  type: typeof CREATE_SINGLE_TEST_STATUS_START;
}

export interface ICreateSingleTestStatusSuccessAction {
  type: typeof CREATE_SINGLE_TEST_STATUS_SUCCESS;
  currentTestStatus: {};
}

export interface ICreateSingleTestStatusFailAction {
  type: typeof CREATE_SINGLE_TEST_STATUS_FAIL;
  error: object;
}

export interface IUpdateSingleTestStatusStartAction {
  type: typeof UPDATE_SINGLE_TEST_STATUS_START;
}

export interface IUpdateSingleTestStatusSuccessAction {
  type: typeof UPDATE_SINGLE_TEST_STATUS_SUCCESS;
  currentTestStatus: {};
}

export interface IUpdateSingleTestStatusFailAction {
  type: typeof UPDATE_SINGLE_TEST_STATUS_FAIL;
  error: object;
}

// export interface IDeleteSingleBrowserStartAction {
//   type: typeof DELETE_SINGLE_BROWSER_START;
// }

// export interface IDeleteSingleBrowserSuccessAction {
//   type: typeof DELETE_SINGLE_BROWSER_SUCCESS;
// }

// export interface IDeleteSingleBrowserFailAction {
//   type: typeof DELETE_SINGLE_BROWSER_FAIL;
//   error: object;
// }

export interface IFetchTestStatusStartAction {
  type: typeof FETCH_TEST_STATUS_START;
}

export interface IFetchTestStatusSuccessAction {
  type: typeof FETCH_TEST_STATUS_SUCCESS;
  list: [];
  page: number;
  index: number;
}

export interface IFetchTestStatusFailAction {
  type: typeof FETCH_TEST_STATUS_FAIL;
  error: object;
}

export interface ISetTestStatusListRedirectPath {
  type: typeof SET_TEST_STATUS_LIST_REDIRECT_PATH;
  path: string;
}

export type ITestStatusActionTypes =
  | IFetchSingleTestStatusStartAction
  | IFetchSingleTestStatusSuccessAction
  | IFetchSingleTestStatusFailAction
  | IClearSingleTestStatusAction
  | IFetchTestStatusStartAction
  | IFetchTestStatusSuccessAction
  | IFetchTestStatusFailAction
  // | IDeleteSingleBrowserStartAction
  // | IDeleteSingleBrowserSuccessAction
  // | IDeleteSingleBrowserFailAction
  | ICreateSingleTestStatusStartAction
  | ICreateSingleTestStatusSuccessAction
  | ICreateSingleTestStatusFailAction
  | IUpdateSingleTestStatusStartAction
  | IUpdateSingleTestStatusSuccessAction
  | IUpdateSingleTestStatusFailAction
  | ISetTestStatusListRedirectPath;
