export const FETCH_SINGLE_DEVICE_START = 'FETCH_SINGLE_DEVICE_START';
export const FETCH_SINGLE_DEVICE_SUCCESS = 'FETCH_SINGLE_DEVICE_SUCCESS';
export const FETCH_SINGLE_DEVICE_FAIL = 'FETCH_SINGLE_DEVICE_FAIL';
export const CLEAR_SINGLE_DEVICE = 'CLEAR_SINGLE_DEVICE';

export const FETCH_DEVICES_START = 'FETCH_DEVICES_START';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAIL = 'FETCH_DEVICES_FAIL';

export const CREATE_SINGLE_DEVICE_START = 'CREATE_SINGLE_DEVICE_START';
export const CREATE_SINGLE_DEVICE_SUCCESS = 'CREATE_SINGLE_DEVICE_SUCCESS';
export const CREATE_SINGLE_DEVICE_FAIL = 'CREATE_SINGLE_DEVICE_FAIL';

export const UPDATE_SINGLE_DEVICE_START = 'UPDATE_SINGLE_DEVICE_START';
export const UPDATE_SINGLE_DEVICE_SUCCESS = 'UPDATE_SINGLE_DEVICE_SUCCESS';
export const UPDATE_SINGLE_DEVICE_FAIL = 'UPDATE_SINGLE_DEVICE_FAIL';

export const DELETE_SINGLE_DEVICE_START = 'DELETE_SINGLE_DEVICE_START';
export const DELETE_SINGLE_DEVICE_SUCCESS = 'DELETE_SINGLE_DEVICE_SUCCESS';
export const DELETE_SINGLE_DEVICE_FAIL = 'DELETE_SINGLE_DEVICE_FAIL';

export const SET_DEVICE_LIST_REDIRECT_PATH = 'SET_DEVICE_LIST_REDIRECT_PATH';

export interface IDeviceState {
  currentDevice: {} | null;
  devicesList: [],
  deviceListRedirectPath: string | null,
  devicesPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleDeviceStartAction {
  type: typeof FETCH_SINGLE_DEVICE_START;
}

export interface IFetchSingleDeviceSuccessAction {
  type: typeof FETCH_SINGLE_DEVICE_SUCCESS;
  currentDevice: {};
}

export interface IFetchSingleDeviceFailAction {
  type: typeof FETCH_SINGLE_DEVICE_FAIL;
  error: object;
}

export interface ICreateSingleDeviceStartAction {
  type: typeof CREATE_SINGLE_DEVICE_START;
}

export interface ICreateSingleDeviceSuccessAction {
  type: typeof CREATE_SINGLE_DEVICE_SUCCESS;
  currentDevice: {};
}

export interface ICreateSingleDeviceFailAction {
  type: typeof CREATE_SINGLE_DEVICE_FAIL;
  error: object;
}

export interface IUpdateSingleDeviceStartAction {
  type: typeof UPDATE_SINGLE_DEVICE_START;
}

export interface IUpdateSingleDeviceSuccessAction {
  type: typeof UPDATE_SINGLE_DEVICE_SUCCESS;
  currentDevice: {};
}

export interface IUpdateSingleDeviceFailAction {
  type: typeof UPDATE_SINGLE_DEVICE_FAIL;
  error: object;
}

export interface IDeleteSingleDeviceStartAction {
  type: typeof DELETE_SINGLE_DEVICE_START;
}

export interface IDeleteSingleDeviceSuccessAction {
  type: typeof DELETE_SINGLE_DEVICE_SUCCESS;
}

export interface IDeleteSingleDeviceFailAction {
  type: typeof DELETE_SINGLE_DEVICE_FAIL;
  error: object;
}

export interface IClearSingleDeviceAction {
  type: typeof CLEAR_SINGLE_DEVICE;
}

export interface IFetchDevicesStartAction {
  type: typeof FETCH_DEVICES_START;
}

export interface IFetchDevicesSuccessAction {
  type: typeof FETCH_DEVICES_SUCCESS;
  devicesList: [];
  devicesPages: number,
  index: number;
}

export interface IFetchDevicesFailAction {
  type: typeof FETCH_DEVICES_FAIL;
  error: object;
}

export interface ISetDeviceListRedirectPath {
  type: typeof SET_DEVICE_LIST_REDIRECT_PATH;
  path: string;
}

export type IDeviceActionTypes = IFetchSingleDeviceStartAction | IFetchSingleDeviceSuccessAction | IFetchSingleDeviceFailAction
  | IFetchDevicesStartAction | IFetchDevicesSuccessAction | IFetchDevicesFailAction | IClearSingleDeviceAction
  | IDeleteSingleDeviceStartAction | IDeleteSingleDeviceSuccessAction | IDeleteSingleDeviceFailAction | ICreateSingleDeviceStartAction
  | ICreateSingleDeviceSuccessAction | ICreateSingleDeviceFailAction | IUpdateSingleDeviceStartAction | IUpdateSingleDeviceSuccessAction
  | IUpdateSingleDeviceFailAction | ISetDeviceListRedirectPath;
