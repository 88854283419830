import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleProfile, deleteProfileById, getProfileById } from '../../../store/profiles';
import { formatProfileActive, IProfileActive } from '../../../utils/profiles/Formatters';
import styles from './ProfileDetails.module.css';
import { formatOptionalField } from '../../../utils/FieldFormatHelper';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IProfileDetailsProps {
  token: string;
  match: any;
  profile: any;
  index: number;
  location: any;
  history: any;
  loading: boolean;
  profileListRedirectPath: string;
  onGetProfileById(id: string, token: string): any;
  onClearSingleProfile(): any;
  onDeleteProfileById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    index: state.profiles.index,
    profile: state.profiles.currentProfile,
    loading: loadingAppSelector(state),
    profileListRedirectPath: state.profiles.profileListRedirectPath,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetProfileById: (id: string, token: string) => dispatch(getProfileById(id, token)),
    onClearSingleProfile: () => dispatch(clearSingleProfile()),
    onDeleteProfileById: (id: string, token: string) => dispatch(deleteProfileById(id, token)),
  }
};

const formatProfileDetails = (profile: any) => {
  return {
    ID: profile.id,
    Description: formatOptionalField(profile.description),
    'Email': formatOptionalField(profile.email),
    Token: formatOptionalField(profile.token),
    User: formatOptionalField(`${profile.user.name} ${profile.user.lastName}`),
    Account: formatOptionalField(profile.account.description),
    'Active': formatOptionalField(formatProfileActive[profile.active as keyof IProfileActive]),
  }
};

class ProfileDetails extends React.Component<IProfileDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetProfileById } = this.props;
    onGetProfileById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetProfileById } = this.props;
      onGetProfileById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleProfile } = this.props;
    onClearSingleProfile();
  }

  onEditButtonClickHandler = () => {
    const { history, profile } = this.props;
    history.push(`/profiles/${profile.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteProfileById, profile, token, history } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteProfileById(profile.id, token);
    history.push('/');
  };

  render() {
    const { profile, match, loading, index, profileListRedirectPath, } = this.props;
    const { showModal } = this.state;
    const profileDetails = profile? <DescriptionList entity={formatProfileDetails(profile)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const profileListURL = profileListRedirectPath? profileListRedirectPath : `/profiles?&page=${index? index : 1}`;
    return (
      <div className={styles.ProfileDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={profileListURL} exact>
              Profiles
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={profileListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.ProfileDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {profileDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this profile?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(ProfileDetails);
