import {
  CLEAR_SINGLE_ENVIRONMENT,
  CREATE_SINGLE_ENVIRONMENT_FAIL,
  CREATE_SINGLE_ENVIRONMENT_START,
  CREATE_SINGLE_ENVIRONMENT_SUCCESS,
  DELETE_SINGLE_ENVIRONMENT_FAIL,
  DELETE_SINGLE_ENVIRONMENT_START,
  DELETE_SINGLE_ENVIRONMENT_SUCCESS,
  FETCH_ENVIRONMENTS_FAIL,
  FETCH_ENVIRONMENTS_START,
  FETCH_ENVIRONMENTS_SUCCESS,
  FETCH_SINGLE_ENVIRONMENT_FAIL,
  FETCH_SINGLE_ENVIRONMENT_START,
  FETCH_SINGLE_ENVIRONMENT_SUCCESS,
  IClearSingleEnvironmentAction,
  ICreateSingleEnvironmentFailAction,
  ICreateSingleEnvironmentStartAction,
  ICreateSingleEnvironmentSuccessAction,
  IDeleteSingleEnvironmentFailAction,
  IDeleteSingleEnvironmentStartAction,
  IDeleteSingleEnvironmentSuccessAction,
  IEnvironmentActionTypes,
  IEnvironmentState,
  IFetchEnvironmentsFailAction,
  IFetchEnvironmentsStartAction,
  IFetchEnvironmentsSuccessAction,
  IFetchSingleEnvironmentFailAction,
  IFetchSingleEnvironmentStartAction,
  IFetchSingleEnvironmentSuccessAction,
  ISetEnvironmentListRedirectPath,
  IUpdateSingleEnvironmentFailAction,
  IUpdateSingleEnvironmentStartAction,
  IUpdateSingleEnvironmentSuccessAction,
  SET_ENVIRONMENT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_ENVIRONMENT_FAIL,
  UPDATE_SINGLE_ENVIRONMENT_START,
  UPDATE_SINGLE_ENVIRONMENT_SUCCESS,
} from './types';

const initialState: IEnvironmentState = {
  currentEnvironment: null,
  environmentsList: [],
  environmentsPages: 0,
  index: 0,
  environmentListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleEnvironmentStart = (state: IEnvironmentState, action: IFetchSingleEnvironmentStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleEnvironmentSuccess = (state: IEnvironmentState, action: IFetchSingleEnvironmentSuccessAction) => {
  return {
    ...state,
    currentEnvironment: action.currentEnvironment,
    error: null,
    loading: false
  };
};

const fetchSingleEnvironmentFail = (state: IEnvironmentState, action: IFetchSingleEnvironmentFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleEnvironment = (state: IEnvironmentState, action: IClearSingleEnvironmentAction) => {
  return {
    ...state,
    currentEnvironment: null
  };
};

const createSingleEnvironmentStart = (state: IEnvironmentState, action: ICreateSingleEnvironmentStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleEnvironmentSuccess = (state: IEnvironmentState, action: ICreateSingleEnvironmentSuccessAction) => {
  return {
    ...state,
    currentEnvironment: action.currentEnvironment,
    error: null,
    loading: false
  };
};

const createSingleEnvironmentFail = (state: IEnvironmentState, action: ICreateSingleEnvironmentFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleEnvironmentStart = (state: IEnvironmentState, action: IUpdateSingleEnvironmentStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleEnvironmentSuccess = (state: IEnvironmentState, action: IUpdateSingleEnvironmentSuccessAction) => {
  return {
    ...state,
    currentEnvironment: action.currentEnvironment,
    error: null,
    loading: false
  };
};

const updateSingleEnvironmentFail = (state: IEnvironmentState, action: IUpdateSingleEnvironmentFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleEnvironmentStart = (state: IEnvironmentState, action: IDeleteSingleEnvironmentStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleEnvironmentSuccess = (state: IEnvironmentState, action: IDeleteSingleEnvironmentSuccessAction) => {
  return {
    ...state,
    currentEnvironment: null,
    error: null,
    loading: false
  };
};

const deleteSingleEnvironmentFail = (state: IEnvironmentState, action: IDeleteSingleEnvironmentFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchEnvironmentsStart = (state: IEnvironmentState, action: IFetchEnvironmentsStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchEnvironmentsSuccess = (state: IEnvironmentState, action: IFetchEnvironmentsSuccessAction) => {
  return {
    ...state,
    environmentsList: action.environmentsList,
    error: null,
    loading: false,
    environmentsPages: action.environmentsPages,
    index: action.index,
  };
};

const fetchEnvironmentsFail = (state: IEnvironmentState, action: IFetchEnvironmentsFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setEnvironmentListRedirectPath = (state: IEnvironmentState, action: ISetEnvironmentListRedirectPath) => {
  return {
    ...state,
    environmentListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IEnvironmentActionTypes): IEnvironmentState => {
  switch ( action.type ) {
    case FETCH_SINGLE_ENVIRONMENT_START: return fetchSingleEnvironmentStart(state, action);
    case FETCH_SINGLE_ENVIRONMENT_SUCCESS: return fetchSingleEnvironmentSuccess(state, action);
    case FETCH_SINGLE_ENVIRONMENT_FAIL: return fetchSingleEnvironmentFail(state, action);
    case CREATE_SINGLE_ENVIRONMENT_START: return createSingleEnvironmentStart(state, action);
    case CREATE_SINGLE_ENVIRONMENT_SUCCESS: return createSingleEnvironmentSuccess(state, action);
    case CREATE_SINGLE_ENVIRONMENT_FAIL: return createSingleEnvironmentFail(state, action);
    case UPDATE_SINGLE_ENVIRONMENT_START: return updateSingleEnvironmentStart(state, action);
    case UPDATE_SINGLE_ENVIRONMENT_SUCCESS: return updateSingleEnvironmentSuccess(state, action);
    case UPDATE_SINGLE_ENVIRONMENT_FAIL: return updateSingleEnvironmentFail(state, action);
    case DELETE_SINGLE_ENVIRONMENT_START: return deleteSingleEnvironmentStart(state, action);
    case DELETE_SINGLE_ENVIRONMENT_SUCCESS: return deleteSingleEnvironmentSuccess(state, action);
    case DELETE_SINGLE_ENVIRONMENT_FAIL: return deleteSingleEnvironmentFail(state, action);
    case CLEAR_SINGLE_ENVIRONMENT: return clearSingleEnvironment(state, action);
    case FETCH_ENVIRONMENTS_START: return fetchEnvironmentsStart(state, action);
    case FETCH_ENVIRONMENTS_SUCCESS: return fetchEnvironmentsSuccess(state, action);
    case FETCH_ENVIRONMENTS_FAIL: return fetchEnvironmentsFail(state, action);
    case SET_ENVIRONMENT_LIST_REDIRECT_PATH: return setEnvironmentListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
