import * as React from 'react';

interface IButton {
  type: 'button' | 'submit' | 'reset';
  color: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): any;
}

const button: React.FunctionComponent<IButton> = (props) => {
  const { type, color, disabled, onClick, autoFocus } = props;
  return (
    <button
      type={type}
      disabled={disabled}
      className={`btn btn-${color}`}
      onClick={onClick}
      autoFocus={autoFocus}
      >
      {props.children}
    </button>
  );
};

export default button;
