import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleAccount, createAccount } from '../../../store/accounts';
import AccountForm from '../AccountForm/AccountForm';
import styles from './AccountCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IAccountCreateProps {
  token: string;
  account: any;
  index: number;
  history: any;
  loading: boolean;
  accountListRedirectPath: string;
  onCreateAccount(token: string, account: any): any;
  onClearSingleAccount(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    account: state.accounts.currentAccount,
    index: state.accounts.index,
    accountListRedirectPath: state.accounts.accountListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateAccount: (token: string, account: any) => dispatch(createAccount(token, account)),
    onClearSingleAccount: () => dispatch(clearSingleAccount()),
  }
};

class AccountCreate extends React.Component<IAccountCreateProps>{
  state: State = initialState;

  onSubmitAccount = async (accountPayload: any) => {
    const { onCreateAccount, token, history } = this.props;
    await onCreateAccount(token, accountPayload);
    const { account } = this.props;
    if (account) {
      history.push(`/accounts/${account.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleAccount } = this.props;
    onClearSingleAccount();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, accountListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const accountListURL = accountListRedirectPath? accountListRedirectPath : `/accounts?&page=${index? index : 1}`;
    
    return (
      <div className={styles.AccountCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={accountListURL} exact>
                Accounts
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={accountListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <AccountForm onSubmitAccount={this.onSubmitAccount} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(AccountCreate);
