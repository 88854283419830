interface IUserUpdateData {
  name?: string;
  lastName?: string;
  email?: string;
  password?: string;
  type?: string;
  role?: string;
  active?: boolean;
  confirmPassword?: string;
}

export const identifyUserChanges = (prevUser: any, newUser: any) => {
  const changes: IUserUpdateData = {};
  if (prevUser.name !== newUser.name) {
    changes.name = newUser.name;
  }
  if (prevUser.lastName !== newUser.lastName) {
    changes.lastName = newUser.lastName;
  }
  if (prevUser.email !== newUser.email) {
    changes.email = newUser.email;
  }
  if (prevUser.type !== newUser.type) {
    changes.type = newUser.type;
  }
  if (prevUser.role !== newUser.role) {
    changes.role = newUser.role;
  }
  if (prevUser.active !== newUser.active) {
    changes.active = newUser.active;
  }
  return changes;
};

export const identifyPasswordChange = (prevUser: any, newUser: any) => {
  const changes: IUserUpdateData = {};
  if (newUser.password &&  newUser.confirmPassword && newUser.password === newUser.confirmPassword) {
    changes.password = newUser.password;
  }
  return changes;
};
