import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Logo from '../../components/Logo/Logo';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import withErrorHandling from '../../hoc/withErrorHandling/withErrorHandling';
import axios from '../../services/api/axios-instance';
import { login, setLoginRedirectPath } from '../../store/login';
import { inputValidator } from '../../utils';
import styles from './Login.module.css';

const initialState = {
  formControls: {
    email: {
      value: '',
      validation: {
        isRequired: true,
        isEmail: true
      },
      valid: false,
      touched: false
    },
    password: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    }
  },
  formIsValid: false
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

interface ILoginProps {
  loading: boolean;
  error: any;
  isAuthenticated: boolean;
  loginRedirectPath: string;
  onLogin(email: string, password: string): any;
  onSetLoginRedirectPath(): any;
}

const mapStateToProps = (state: any) => {
  return {
    error: state.login.error,
    isAuthenticated: state.login.token !== null,
    loading: state.login.loading,
    loginRedirectPath: state.login.loginRedirectPath
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogin: (email: string, password: string) => dispatch(login(email, password)),
    onSetLoginRedirectPath: () => dispatch(setLoginRedirectPath('/'))
  };
};

class Login extends React.Component<ILoginProps> {
  state: State = initialState;

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement>, inputIdentifier: FormControlKey) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { target } = event;
    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value: target.value,
      valid: inputValidator(target.value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement>, inputIdentifier: FormControlKey) => {
    // Gets formControls from current state.
    const { formControls } = this.state;

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls });
  };

  loginSubmitHandler = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    this.props.onLogin(this.state.formControls.email.value, this.state.formControls.password.value);
  };

  render() {
    const { formIsValid } = this.state;
    const { email, password } = this.state.formControls;
    const { loginRedirectPath, isAuthenticated, loading } = this.props;
    const redirect = isAuthenticated ? <Redirect to={loginRedirectPath}/> : null;
    const logo = <Logo height="100px" classes={styles.Logo}/>;
    const spinner = loading? <Spinner type="linear"/> : null;
    const disabled = !formIsValid || loading;
    return (
      <div className={styles.Background}>
        {redirect}
        <div className={`container ${styles.ContainerAlign}`}>
          <Card title="QA Metrics ADM" header={logo} classes={styles.card}>
          <Input
            elementType="input"
            elementConfig={{type: 'text', placeholder: 'Type your email', autoFocus: true}}
            value={email.value}
            shouldValidate={true}
            valid={email.valid}
            touched={email.touched}
            label="Email"
            disabled={loading}
            onEnterKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => (event.which === 13) && !disabled? this.loginSubmitHandler(event) : null}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'email')}
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'email')}
          />
          <Input
            elementType="input"
            elementConfig={{type: 'password', placeholder: 'Type your password'}}
            value={password.value}
            shouldValidate={true}
            valid={password.valid}
            touched={password.touched}
            label="Password"
            disabled={loading}
            onEnterKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => (event.which === 13) && !disabled? this.loginSubmitHandler(event) : null}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'password')}
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'password')}
          />
          <div className={styles.LoginButton}>
            <Button type="submit" color="info" disabled={disabled} onClick={(event: React.MouseEvent<HTMLElement>) => this.loginSubmitHandler(event)}>
              Login
            </Button>
          </div>
            {spinner}
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(Login, axios));
