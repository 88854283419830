import React, { useEffect, useState } from "react";
import './Input.css';
import { Input, } from 'reactstrap';

interface IInput {
  elementType: string;
  elementConfig?: object;
  value?: string;
  disabled?: boolean;
  valid?: boolean;
  shouldValidate?: boolean;
  touched?: boolean;
  label?: string;
  tip?: string;
  required?: boolean;
  noLabel?: boolean;
  onChange?(event: React.ChangeEvent<HTMLElement>): any;
  onBlur?(event: React.ChangeEvent<HTMLElement>): any;
  onEnterKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): any
  limitText?: boolean;
}

const InputComponent: React.FunctionComponent<IInput> = (props) => {
  let inputElement = null;
  const formStyles = ['form-group'];
  const inputStyles = [];

  const {
    valid, shouldValidate, touched, label, tip, required,
    noLabel,limitText, value
  } = props;

  // Display error message by change
  const [displayValue, setDisplayValue] = useState("none");
  const [textLength, setTextLength] = useState(0);


  useEffect(() => {
    if (!valid && shouldValidate) {
      setDisplayValue("block");
    } else {
      setDisplayValue("none");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  useEffect(() => {
    let text:any = value;
    setTextLength(text.length)
  }, [value]);


  if (!valid && shouldValidate && touched) {
    inputStyles.push('is-invalid');
  }
  if (required) {
    formStyles.push('required');
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <Input
          id={label}
          disabled={props.disabled}
          className={inputStyles.join(' ')}
          {...props.elementConfig}
          value={props.value ? props.value : ''}
          onKeyDown={props.onEnterKeyDown}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <Input
          type="textarea"
          id={label}
          disabled={props.disabled}
          className={inputStyles.join(' ')}
          {...props.elementConfig}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      );
      break;
    default:
      inputElement = (
        <Input
          id={label}
          disabled={props.disabled}
          className={inputStyles.join(' ')}
          {...props.elementConfig}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
          onKeyDown={props.onEnterKeyDown}
          onBlur={props.onBlur}
        />
      );
  }
  const tipBody = tip? (
    <small id="emailHelp" className="form-text text-muted">
        {tip}
    </small>
  ): null;

  const inputGroup = (
    <>
      {inputElement}
      <div className="invalid-feedback" style={{ display: displayValue }}>
        {limitText ? textLength > 1 ? `The maximum lenght text is 500 characters` : 'The minimun length text is 2 characters' : `Please provide a valid ${label}`}
      </div>
      {tipBody}
    </>
  );
  return !noLabel ? (
    <div className={formStyles.join(' ')}>
      <label htmlFor={label} className="control-label">
        <b> {label}</b>
      </label>
      {inputGroup}
    </div>
    ) : inputGroup;
};

export default InputComponent;
