export const FETCH_SINGLE_ACCOUNT_START = 'FETCH_SINGLE_ACCOUNT_START';
export const FETCH_SINGLE_ACCOUNT_SUCCESS = 'FETCH_SINGLE_ACCOUNT_SUCCESS';
export const FETCH_SINGLE_ACCOUNT_FAIL = 'FETCH_SINGLE_ACCOUNT_FAIL';
export const CLEAR_SINGLE_ACCOUNT = 'CLEAR_SINGLE_ACCOUNT';

export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL';

export const CREATE_SINGLE_ACCOUNT_START = 'CREATE_SINGLE_ACCOUNT_START';
export const CREATE_SINGLE_ACCOUNT_SUCCESS = 'CREATE_SINGLE_ACCOUNT_SUCCESS';
export const CREATE_SINGLE_ACCOUNT_FAIL = 'CREATE_SINGLE_ACCOUNT_FAIL';

export const UPDATE_SINGLE_ACCOUNT_START = 'UPDATE_SINGLE_ACCOUNT_START';
export const UPDATE_SINGLE_ACCOUNT_SUCCESS = 'UPDATE_SINGLE_ACCOUNT_SUCCESS';
export const UPDATE_SINGLE_ACCOUNT_FAIL = 'UPDATE_SINGLE_ACCOUNT_FAIL';

export const DELETE_SINGLE_ACCOUNT_START = 'DELETE_SINGLE_ACCOUNT_START';
export const DELETE_SINGLE_ACCOUNT_SUCCESS = 'DELETE_SINGLE_ACCOUNT_SUCCESS';
export const DELETE_SINGLE_ACCOUNT_FAIL = 'DELETE_SINGLE_ACCOUNT_FAIL';

export const SET_ACCOUNT_LIST_REDIRECT_PATH = 'SET_ACCOUNT_LIST_REDIRECT_PATH';

export interface IAccountState {
  currentAccount: {} | null;
  accountsList: [],
  accountListRedirectPath: string | null,
  accountsPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleAccountStartAction {
  type: typeof FETCH_SINGLE_ACCOUNT_START;
}

export interface IFetchSingleAccountSuccessAction {
  type: typeof FETCH_SINGLE_ACCOUNT_SUCCESS;
  currentAccount: {};
}

export interface IFetchSingleAccountFailAction {
  type: typeof FETCH_SINGLE_ACCOUNT_FAIL;
  error: object;
}

export interface ICreateSingleAccountStartAction {
  type: typeof CREATE_SINGLE_ACCOUNT_START;
}

export interface ICreateSingleAccountSuccessAction {
  type: typeof CREATE_SINGLE_ACCOUNT_SUCCESS;
  currentAccount: {};
}

export interface ICreateSingleAccountFailAction {
  type: typeof CREATE_SINGLE_ACCOUNT_FAIL;
  error: object;
}

export interface IUpdateSingleAccountStartAction {
  type: typeof UPDATE_SINGLE_ACCOUNT_START;
}

export interface IUpdateSingleAccountSuccessAction {
  type: typeof UPDATE_SINGLE_ACCOUNT_SUCCESS;
  currentAccount: {};
}

export interface IUpdateSingleAccountFailAction {
  type: typeof UPDATE_SINGLE_ACCOUNT_FAIL;
  error: object;
}

export interface IDeleteSingleAccountStartAction {
  type: typeof DELETE_SINGLE_ACCOUNT_START;
}

export interface IDeleteSingleAccountSuccessAction {
  type: typeof DELETE_SINGLE_ACCOUNT_SUCCESS;
}

export interface IDeleteSingleAccountFailAction {
  type: typeof DELETE_SINGLE_ACCOUNT_FAIL;
  error: object;
}

export interface IClearSingleAccountAction {
  type: typeof CLEAR_SINGLE_ACCOUNT;
}

export interface IFetchAccountsStartAction {
  type: typeof FETCH_ACCOUNTS_START;
}

export interface IFetchAccountsSuccessAction {
  type: typeof FETCH_ACCOUNTS_SUCCESS;
  accountsList: [];
  accountsPages: number,
  index: number;
}

export interface IFetchAccountsFailAction {
  type: typeof FETCH_ACCOUNTS_FAIL;
  error: object;
}

export interface ISetAccountListRedirectPath {
  type: typeof SET_ACCOUNT_LIST_REDIRECT_PATH;
  path: string;
}

export type IAccountActionTypes = IFetchSingleAccountStartAction | IFetchSingleAccountSuccessAction | IFetchSingleAccountFailAction
  | IFetchAccountsStartAction | IFetchAccountsSuccessAction | IFetchAccountsFailAction | IClearSingleAccountAction
  | IDeleteSingleAccountStartAction | IDeleteSingleAccountSuccessAction | IDeleteSingleAccountFailAction | ICreateSingleAccountStartAction
  | ICreateSingleAccountSuccessAction | ICreateSingleAccountFailAction | IUpdateSingleAccountStartAction | IUpdateSingleAccountSuccessAction
  | IUpdateSingleAccountFailAction | ISetAccountListRedirectPath;
