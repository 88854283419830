
export const formatDeviceActive = {
  'true': 'Yes',
  'false': 'No'
};

export type IDeviceActive = typeof formatDeviceActive

export const searchByOptions = [
  {
    label: 'ID',
    value: 'id'
  },
  {
    label: 'Description',
    value: 'description'
  },
  {
    label: 'Brand',
    value: 'brand'
  },
  {
    label: 'Model',
    value: 'model'
  },
];
