import * as React from 'react';
import styles from './Table.module.css';

interface ITable {
  headers: Array<string | number | Date | boolean>;
  rows: Array<Array<string | number | Date | boolean>>;
  onRowClick(row: any): any;
}

const table: React.FunctionComponent<ITable> = (props) => {
  const { headers, rows, onRowClick } = props;

  const emptyMessage = !rows.length? (
     <span className={styles.EmptyMessage}>
      No rows to display!
    </span>
  ): null;

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            {
              headers.map((header, index) =>
                <th scope="col" key={index}> { header } </th>)
            }
          </tr>
        </thead>
        <tbody>
        {
            rows.map((row, index) => {
            return (
              <tr key={index} onClick={() => onRowClick(row)} >
              {
                row.map((cell, cellIndex) => <td key={cellIndex}> {cell}</td>)
              }
              </tr>
            );
          })
        }
        </tbody>
      </table>
      {emptyMessage}
    </>
  );
};

export default table;
