import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleAccount, getAccountById, updateAccount } from '../../../store/accounts';
import AccountForm from '../AccountForm/AccountForm';
import styles from './AccountEdit.module.css';
import * as _ from 'lodash';
import * as AccountValidator from '../../../utils/accounts/AccountValidator';

interface IAccountEditProps {
  token: string;
  account: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  accountsError: any;
  accountListRedirectPath: string;
  onUpdateAccount(token: string, account: any, id: string): any;
  onGetAccountById(token: string, id: string): any;
  onClearSingleAccount(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    account: state.accounts.currentAccount,
    index: state.accounts.index,
    loading: loadingAppSelector(state),
    accountListRedirectPath: state.accounts.accountListRedirectPath,
    accountsError: state.accounts.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateAccount: (token: string, account: any, id: string) => dispatch(updateAccount(token, account, id)),
    onGetAccountById: (token: string, id: string) => dispatch(getAccountById(id, token)),
    onClearSingleAccount: () => dispatch(clearSingleAccount()),
  }
};

class AccountEdit extends React.Component<IAccountEditProps>{

  async componentDidMount() {
    const { match, onGetAccountById, token } = this.props;
    await onGetAccountById(token, match.params.id);
  }

  onSubmitAccount = async (accountPayload: any) => {
    const { onUpdateAccount, account, token, history } = this.props;
    const accountChanges = AccountValidator.identifyAccountChanges(account, accountPayload);
    if (_.isEmpty(accountChanges)) {
      history.push(`/accounts/${account.id}/details`);
      return;
    }
    await onUpdateAccount(token, accountChanges, account.id);
    const { accountsError } = this.props;
    if (!accountsError) {
      history.push(`/accounts/${account.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleAccount } = this.props;
    onClearSingleAccount();
  }

  render() {
    const { account, match, loading, index, accountListRedirectPath } = this.props;
    let accountForm = null;
    if (account && account.id.toString() === match.params.id) {
      accountForm = <AccountForm onSubmitAccount={this.onSubmitAccount} accountFromProps={account}  />;
    }
    const accountListURL = accountListRedirectPath? accountListRedirectPath : `/accounts?&page=${index? index : 1}`;

    return (
      <div className={styles.AccountEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={accountListURL} exact>
                Accounts
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/accounts/${match.params.id}/details`} exact>
                {account? account.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/accounts/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { accountForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(AccountEdit);
