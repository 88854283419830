import {
  CLEAR_SINGLE_TEST_CASE,
  CREATE_SINGLE_TEST_CASE_FAIL,
  CREATE_SINGLE_TEST_CASE_START,
  CREATE_SINGLE_TEST_CASE_SUCCESS,
  DELETE_SINGLE_TEST_CASE_FAIL,
  DELETE_SINGLE_TEST_CASE_START,
  DELETE_SINGLE_TEST_CASE_SUCCESS,
  FETCH_SINGLE_TEST_CASE_FAIL,
  FETCH_SINGLE_TEST_CASE_START,
  FETCH_SINGLE_TEST_CASE_SUCCESS,
  FETCH_TEST_CASES_FAIL,
  FETCH_TEST_CASES_START,
  FETCH_TEST_CASES_SUCCESS,
  IClearSingleTestCaseAction,
  ICreateSingleTestCaseFailAction,
  ICreateSingleTestCaseStartAction,
  ICreateSingleTestCaseSuccessAction,
  IDeleteSingleTestCaseFailAction,
  IDeleteSingleTestCaseStartAction,
  IDeleteSingleTestCaseSuccessAction,
  IFetchSingleTestCaseFailAction,
  IFetchSingleTestCaseStartAction,
  IFetchSingleTestCaseSuccessAction,
  IFetchTestCasesFailAction,
  IFetchTestCasesStartAction,
  IFetchTestCasesSuccessAction,
  ISetTestCaseListRedirectPath,
  ITestCaseActionTypes,
  ITestCaseState,
  IUpdateSingleTestCaseFailAction,
  IUpdateSingleTestCaseStartAction,
  IUpdateSingleTestCaseSuccessAction,
  SET_TEST_CASE_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_TEST_CASE_FAIL,
  UPDATE_SINGLE_TEST_CASE_START,
  UPDATE_SINGLE_TEST_CASE_SUCCESS,
} from './types';

const initialState: ITestCaseState = {
  currentTestCase: null,
  testCasesList: [],
  testCasesPages: 0,
  index: 0,
  testCaseListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleTestCaseStart = (state: ITestCaseState, action: IFetchSingleTestCaseStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleTestCaseSuccess = (state: ITestCaseState, action: IFetchSingleTestCaseSuccessAction) => {
  return {
    ...state,
    currentTestCase: action.currentTestCase,
    error: null,
    loading: false
  };
};

const fetchSingleTestCaseFail = (state: ITestCaseState, action: IFetchSingleTestCaseFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleTestCase = (state: ITestCaseState, action: IClearSingleTestCaseAction) => {
  return {
    ...state,
    currentTestCase: null
  };
};

const createSingleTestCaseStart = (state: ITestCaseState, action: ICreateSingleTestCaseStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleTestCaseSuccess = (state: ITestCaseState, action: ICreateSingleTestCaseSuccessAction) => {
  return {
    ...state,
    currentTestCase: action.currentTestCase,
    error: null,
    loading: false
  };
};

const createSingleTestCaseFail = (state: ITestCaseState, action: ICreateSingleTestCaseFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleTestCaseStart = (state: ITestCaseState, action: IUpdateSingleTestCaseStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleTestCaseSuccess = (state: ITestCaseState, action: IUpdateSingleTestCaseSuccessAction) => {
  return {
    ...state,
    currentTestCase: action.currentTestCase,
    error: null,
    loading: false
  };
};

const updateSingleTestCaseFail = (state: ITestCaseState, action: IUpdateSingleTestCaseFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleTestCaseStart = (state: ITestCaseState, action: IDeleteSingleTestCaseStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleTestCaseSuccess = (state: ITestCaseState, action: IDeleteSingleTestCaseSuccessAction) => {
  return {
    ...state,
    currentTestCase: null,
    error: null,
    loading: false
  };
};

const deleteSingleTestCaseFail = (state: ITestCaseState, action: IDeleteSingleTestCaseFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchTestCasesStart = (state: ITestCaseState, action: IFetchTestCasesStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchTestCasesSuccess = (state: ITestCaseState, action: IFetchTestCasesSuccessAction) => {
  return {
    ...state,
    testCasesList: action.testCasesList,
    error: null,
    loading: false,
    testCasesPages: action.testCasesPages,
    index: action.index,
  };
};

const fetchTestCasesFail = (state: ITestCaseState, action: IFetchTestCasesFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setTestCaseListRedirectPath = (state: ITestCaseState, action: ISetTestCaseListRedirectPath) => {
  return {
    ...state,
    testCaseListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: ITestCaseActionTypes): ITestCaseState => {
  switch ( action.type ) {
    case FETCH_SINGLE_TEST_CASE_START: return fetchSingleTestCaseStart(state, action);
    case FETCH_SINGLE_TEST_CASE_SUCCESS: return fetchSingleTestCaseSuccess(state, action);
    case FETCH_SINGLE_TEST_CASE_FAIL: return fetchSingleTestCaseFail(state, action);
    case CREATE_SINGLE_TEST_CASE_START: return createSingleTestCaseStart(state, action);
    case CREATE_SINGLE_TEST_CASE_SUCCESS: return createSingleTestCaseSuccess(state, action);
    case CREATE_SINGLE_TEST_CASE_FAIL: return createSingleTestCaseFail(state, action);
    case UPDATE_SINGLE_TEST_CASE_START: return updateSingleTestCaseStart(state, action);
    case UPDATE_SINGLE_TEST_CASE_SUCCESS: return updateSingleTestCaseSuccess(state, action);
    case UPDATE_SINGLE_TEST_CASE_FAIL: return updateSingleTestCaseFail(state, action);
    case DELETE_SINGLE_TEST_CASE_START: return deleteSingleTestCaseStart(state, action);
    case DELETE_SINGLE_TEST_CASE_SUCCESS: return deleteSingleTestCaseSuccess(state, action);
    case DELETE_SINGLE_TEST_CASE_FAIL: return deleteSingleTestCaseFail(state, action);
    case CLEAR_SINGLE_TEST_CASE: return clearSingleTestCase(state, action);
    case FETCH_TEST_CASES_START: return fetchTestCasesStart(state, action);
    case FETCH_TEST_CASES_SUCCESS: return fetchTestCasesSuccess(state, action);
    case FETCH_TEST_CASES_FAIL: return fetchTestCasesFail(state, action);
    case SET_TEST_CASE_LIST_REDIRECT_PATH: return setTestCaseListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
