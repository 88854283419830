import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import * as React from 'react';

interface IModal {
  open: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'md' | 'lg'| 'xl';
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  onToggle?(): any;
}

const modal: React.FunctionComponent<IModal> = (props) => {
  const { open, maxWidth, onToggle, disableBackdropClick, disableEscapeKeyDown } = props;
    return (
      <Dialog
        open={open}
        maxWidth={maxWidth}
        onClose={onToggle}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogContent>
          { props.children }
        </DialogContent>
      </Dialog>
    );
};

export default modal;
