import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../../store/global/selector';
import { clearSingleTestCase, getTestCaseById, updateTestCase } from '../../../../store/test_cases';
import TestCaseForm from '../TestCaseForm/TestCaseForm';
import styles from './TestCaseEdit.module.css';
import * as _ from 'lodash';
import * as TestCaseValidator from '../../../../utils/test_cases/TestCaseValidator';
import { getTestReportById } from '../../../../store/test_reports';
import TestStatusValues from '../../../../utils/test_status/TestStatusValues';

interface ITestCaseEditProps {
  currentTestReport: any;
  token: string;
  testCase: any;
  index: number;
  testReportIndex: number;
  history: any;
  loading: boolean;
  match: any;
  testCasesError: any;
  testCaseListRedirectPath: any;
  testReportListRedirectPath: any;
  onUpdateTestCase(token: string, testCase: any, id: string, testReportId: string): any;
  onGetTestCaseById(token: string, id: string, testReportId: string): any;
  onClearSingleTestCase(): any;
  onGetTestReportById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    currentTestReport: state.testReports.currentTestReport,
    token: state.login.token,
    index: state.testCases.index,
    testReportIndex: state.testReports.index,
    testCase: state.testCases.currentTestCase,
    testCaseListRedirectPath: state.testCases.testCaseListRedirectPath,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    loading: loadingAppSelector(state),
    testCasesError: state.testCases.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateTestCase: (token: string, testCase: any, id: string, testReportId: string) => dispatch(updateTestCase(token, testCase, id, testReportId)),
    onGetTestCaseById: (token: string, id: string, testReportId: string) => dispatch(getTestCaseById(id, +testReportId, token)),
    onClearSingleTestCase: () => dispatch(clearSingleTestCase()),
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
  }
};

class TestCaseEdit extends React.Component<ITestCaseEditProps>{
  async componentDidMount() {
    const { match, onGetTestCaseById, token, onGetTestReportById } = this.props;
    await onGetTestCaseById(token, match.params.id, match.params.testReportId);
    await onGetTestReportById(match.params.testReportId, token);
  }

  componentDidUpdate() {
    const { history, currentTestReport } = this.props;

    if(currentTestReport && currentTestReport.testStatusId === TestStatusValues.ARCHIVED) {
      history.push(`/testReports/${currentTestReport.id}/testCases`);
    }
  }

  onSubmitTestCase = async (testCasePayload: any) => {
    const { onUpdateTestCase, testCase, token, history, match } = this.props;
    const testCaseChanges = TestCaseValidator.identifyTestCaseChanges(testCase, testCasePayload);
    if (_.isEmpty(testCaseChanges)) {
      history.push(`/testReports/${match.params.testReportId}/testCases/${testCase.id}/details`);
      return;
    }
    await onUpdateTestCase(token, testCaseChanges, testCase.id, match.params.testReportId);
    const { testCasesError } = this.props;
    if (!testCasesError) {
      history.push(`/testReports/${match.params.testReportId}/testCases/${testCase.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleTestCase } = this.props;
    onClearSingleTestCase();
  }

  render() {
    const { testCase, match, loading, index, testReportIndex, testCaseListRedirectPath, testReportListRedirectPath } = this.props;
    let testCaseForm = null;
    if (testCase && testCase.id.toString() === match.params.id) {
      testCaseForm = <TestCaseForm onSubmitTestCase={this.onSubmitTestCase} testCaseFromProps={testCase}  />;
    }
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${testReportIndex? testReportIndex : 1}`;
    const testCaseListURL = testCaseListRedirectPath? testCaseListRedirectPath : `/testReports/${match.params.testReportId}/testCases?&page=${index? index : 1}`;

    return (
      <div className={styles.TestCaseEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
                <NavigationItem link={testReportListURL} exact>
                  Test Reports
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section> 
                <NavigationItem link={`/testReports/${match.params.testReportId}/details`} exact>
                  {match.params.testReportId} 
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testCaseListURL} exact>
                TestCases
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/testReports/${match.params.testReportId}/testCases/${match.params.id}/details`} exact>
                {testCase? testCase.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
                Edit
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/testReports/${match.params.testReportId}/testCases/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>
        { testCaseForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestCaseEdit);
