import {
  CREATE_SINGLE_TEST_STATUS_START,
  CREATE_SINGLE_TEST_STATUS_SUCCESS,
  CREATE_SINGLE_TEST_STATUS_FAIL,
  // DELETE_SINGLE_BROWSER_FAIL,
  // DELETE_SINGLE_BROWSER_START,
  // DELETE_SINGLE_BROWSER_SUCCESS,
  FETCH_TEST_STATUS_START,
  FETCH_TEST_STATUS_SUCCESS,
  FETCH_TEST_STATUS_FAIL,
  FETCH_SINGLE_TEST_STATUS_START,
  FETCH_SINGLE_TEST_STATUS_SUCCESS,
  FETCH_SINGLE_TEST_STATUS_FAIL,
  CLEAR_SINGLE_TEST_STATUS,
  UPDATE_SINGLE_TEST_STATUS_START,
  UPDATE_SINGLE_TEST_STATUS_SUCCESS,
  UPDATE_SINGLE_TEST_STATUS_FAIL,
  SET_TEST_STATUS_LIST_REDIRECT_PATH,
  ITestStatusActionTypes,
  ITestStatusState,
  ICreateSingleTestStatusStartAction,
  ICreateSingleTestStatusSuccessAction,
  ICreateSingleTestStatusFailAction,
  // IDeleteSingleBrowserFailAction,
  // IDeleteSingleBrowserStartAction,
  // IDeleteSingleBrowserSuccessAction,
  IFetchTestStatusStartAction,
  IFetchTestStatusSuccessAction,
  IFetchTestStatusFailAction,
  IFetchSingleTestStatusStartAction,
  IFetchSingleTestStatusFailAction,
  IFetchSingleTestStatusSuccessAction,
  IClearSingleTestStatusAction,
  IUpdateSingleTestStatusStartAction,
  IUpdateSingleTestStatusSuccessAction,
  IUpdateSingleTestStatusFailAction,
  ISetTestStatusListRedirectPath,
} from './types';

const initialState: ITestStatusState = {
  current: null,
  list: [],
  page: 0,
  index: 0,
  listRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleTestStatusStart = (state: ITestStatusState, action: IFetchSingleTestStatusStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleTestStatusSuccess = (state: ITestStatusState, action: IFetchSingleTestStatusSuccessAction) => {
  return {
    ...state,
    current: action.currentTestStatus,
    error: null,
    loading: false
  };
};

const fetchSingleTestStatusFail = (state: ITestStatusState, action: IFetchSingleTestStatusFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleTestStatus = (state: ITestStatusState, action: IClearSingleTestStatusAction) => {
  return {
    ...state,
    currentBrowser: null
  };
};

const createSingleTestStatusStart = (state: ITestStatusState, action: ICreateSingleTestStatusStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleTestStatusSuccess = (state: ITestStatusState, action: ICreateSingleTestStatusSuccessAction) => {
  return {
    ...state,
    current: action.currentTestStatus,
    error: null,
    loading: false
  };
};

const createSingleTestStatusFail = (state: ITestStatusState, action: ICreateSingleTestStatusFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleTestStatusStart = (state: ITestStatusState, action: IUpdateSingleTestStatusStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleTestStatusSuccess = (state: ITestStatusState, action: IUpdateSingleTestStatusSuccessAction) => {
  return {
    ...state,
    current: action.currentTestStatus,
    error: null,
    loading: false
  };
};

const updateSingleTestStatusFail = (state: ITestStatusState, action: IUpdateSingleTestStatusFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


// const deleteSingleBrowserStart = (state: IBrowserState, action: IDeleteSingleBrowserStartAction) => {
//   return {
//     ...state,
//     error: null,
//     loading: true
//   };
// };

// const deleteSingleBrowserSuccess = (state: IBrowserState, action: IDeleteSingleBrowserSuccessAction) => {
//   return {
//     ...state,
//     currentBrowser: null,
//     error: null,
//     loading: false
//   };
// };

// const deleteSingleBrowserFail = (state: IBrowserState, action: IDeleteSingleBrowserFailAction) => {
//   return {
//     ...state,
//     error: action.error,
//     loading: false
//   };
// };

const fetchBrowsersStart = (state: ITestStatusState, action: IFetchTestStatusStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchBrowsersSuccess = (state: ITestStatusState, action: IFetchTestStatusSuccessAction) => {
  return {
    ...state,
    list: action.list,
    error: null,
    loading: false,
    page: action.page,
    index: action.index,
  };
};

const fetchBrowsersFail = (state: ITestStatusState, action: IFetchTestStatusFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setBrowserListRedirectPath = (state: ITestStatusState, action: ISetTestStatusListRedirectPath) => {
  return {
    ...state,
    browserListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: ITestStatusActionTypes): ITestStatusState => {
  switch ( action.type ) {
    case FETCH_SINGLE_TEST_STATUS_START: return fetchSingleTestStatusStart(state, action);
    case FETCH_SINGLE_TEST_STATUS_SUCCESS: return fetchSingleTestStatusSuccess(state, action);
    case FETCH_SINGLE_TEST_STATUS_FAIL: return fetchSingleTestStatusFail(state, action);
    case CLEAR_SINGLE_TEST_STATUS: return clearSingleTestStatus(state, action);
    case CREATE_SINGLE_TEST_STATUS_START: return createSingleTestStatusStart(state, action);
    case CREATE_SINGLE_TEST_STATUS_SUCCESS: return createSingleTestStatusSuccess(state, action);
    case CREATE_SINGLE_TEST_STATUS_FAIL: return createSingleTestStatusFail(state, action);
    case UPDATE_SINGLE_TEST_STATUS_START: return updateSingleTestStatusStart(state, action);
    case UPDATE_SINGLE_TEST_STATUS_SUCCESS: return updateSingleTestStatusSuccess(state, action);
    case UPDATE_SINGLE_TEST_STATUS_FAIL: return updateSingleTestStatusFail(state, action);
    // case DELETE_SINGLE_BROWSER_START: return deleteSingleBrowserStart(state, action);
    // case DELETE_SINGLE_BROWSER_SUCCESS: return deleteSingleBrowserSuccess(state, action);
    // case DELETE_SINGLE_BROWSER_FAIL: return deleteSingleBrowserFail(state, action);
    case FETCH_TEST_STATUS_START: return fetchBrowsersStart(state, action);
    case FETCH_TEST_STATUS_SUCCESS: return fetchBrowsersSuccess(state, action);
    case FETCH_TEST_STATUS_FAIL: return fetchBrowsersFail(state, action);
    case SET_TEST_STATUS_LIST_REDIRECT_PATH: return setBrowserListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
