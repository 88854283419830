
export const formatUserActive = {
  'true': 'Yes',
  'false': 'No'
};

export type IUserActive = typeof formatUserActive

export const formatUserType = {
  'dev': 'Developer',
  'qa': 'QA'
};

export type IUserTpe = typeof formatUserType

export const formatUserRole = {
  'admin': 'Administrator',
  'user': 'User'
};

export type IUserRole = typeof formatUserRole

export const searchByOptions = [
  {
    label: 'ID',
    value: 'id'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Name',
    value: 'name'
  },
  {
    label: 'Last Name',
    value: 'lastName'
  },{
    label: 'Type',
    value: 'type'
  },
  {
    label: 'Role',
    value: 'role'
  },
];
