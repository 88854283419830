export const FETCH_SINGLE_PROFILE_START = 'FETCH_SINGLE_PROFILE_START';
export const FETCH_SINGLE_PROFILE_SUCCESS = 'FETCH_SINGLE_PROFILE_SUCCESS';
export const FETCH_SINGLE_PROFILE_FAIL = 'FETCH_SINGLE_PROFILE_FAIL';
export const CLEAR_SINGLE_PROFILE = 'CLEAR_SINGLE_PROFILE';

export const FETCH_PROFILES_START = 'FETCH_PROFILES_START';
export const FETCH_PROFILES_SUCCESS = 'FETCH_PROFILES_SUCCESS';
export const FETCH_PROFILES_FAIL = 'FETCH_PROFILES_FAIL';

export const CREATE_SINGLE_PROFILE_START = 'CREATE_SINGLE_PROFILE_START';
export const CREATE_SINGLE_PROFILE_SUCCESS = 'CREATE_SINGLE_PROFILE_SUCCESS';
export const CREATE_SINGLE_PROFILE_FAIL = 'CREATE_SINGLE_PROFILE_FAIL';

export const UPDATE_SINGLE_PROFILE_START = 'UPDATE_SINGLE_PROFILE_START';
export const UPDATE_SINGLE_PROFILE_SUCCESS = 'UPDATE_SINGLE_PROFILE_SUCCESS';
export const UPDATE_SINGLE_PROFILE_FAIL = 'UPDATE_SINGLE_PROFILE_FAIL';

export const DELETE_SINGLE_PROFILE_START = 'DELETE_SINGLE_PROFILE_START';
export const DELETE_SINGLE_PROFILE_SUCCESS = 'DELETE_SINGLE_PROFILE_SUCCESS';
export const DELETE_SINGLE_PROFILE_FAIL = 'DELETE_SINGLE_PROFILE_FAIL';

export const SET_PROFILE_LIST_REDIRECT_PATH = 'SET_PROFILE_LIST_REDIRECT_PATH';

export interface IProfileState {
  currentProfile: {} | null;
  profilesList: [],
  profileListRedirectPath: string | null,
  profilesPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleProfileStartAction {
  type: typeof FETCH_SINGLE_PROFILE_START;
}

export interface IFetchSingleProfileSuccessAction {
  type: typeof FETCH_SINGLE_PROFILE_SUCCESS;
  currentProfile: {};
}

export interface IFetchSingleProfileFailAction {
  type: typeof FETCH_SINGLE_PROFILE_FAIL;
  error: object;
}

export interface ICreateSingleProfileStartAction {
  type: typeof CREATE_SINGLE_PROFILE_START;
}

export interface ICreateSingleProfileSuccessAction {
  type: typeof CREATE_SINGLE_PROFILE_SUCCESS;
  currentProfile: {};
}

export interface ICreateSingleProfileFailAction {
  type: typeof CREATE_SINGLE_PROFILE_FAIL;
  error: object;
}

export interface IUpdateSingleProfileStartAction {
  type: typeof UPDATE_SINGLE_PROFILE_START;
}

export interface IUpdateSingleProfileSuccessAction {
  type: typeof UPDATE_SINGLE_PROFILE_SUCCESS;
  currentProfile: {};
}

export interface IUpdateSingleProfileFailAction {
  type: typeof UPDATE_SINGLE_PROFILE_FAIL;
  error: object;
}

export interface IDeleteSingleProfileStartAction {
  type: typeof DELETE_SINGLE_PROFILE_START;
}

export interface IDeleteSingleProfileSuccessAction {
  type: typeof DELETE_SINGLE_PROFILE_SUCCESS;
}

export interface IDeleteSingleProfileFailAction {
  type: typeof DELETE_SINGLE_PROFILE_FAIL;
  error: object;
}

export interface IClearSingleProfileAction {
  type: typeof CLEAR_SINGLE_PROFILE;
}

export interface IFetchProfilesStartAction {
  type: typeof FETCH_PROFILES_START;
}

export interface IFetchProfilesSuccessAction {
  type: typeof FETCH_PROFILES_SUCCESS;
  profilesList: [];
  profilesPages: number,
  index: number;
}

export interface IFetchProfilesFailAction {
  type: typeof FETCH_PROFILES_FAIL;
  error: object;
}

export interface ISetProfileListRedirectPath {
  type: typeof SET_PROFILE_LIST_REDIRECT_PATH;
  path: string;
}

export type IProfileActionTypes = IFetchSingleProfileStartAction | IFetchSingleProfileSuccessAction | IFetchSingleProfileFailAction
  | IFetchProfilesStartAction | IFetchProfilesSuccessAction | IFetchProfilesFailAction | IClearSingleProfileAction
  | IDeleteSingleProfileStartAction | IDeleteSingleProfileSuccessAction | IDeleteSingleProfileFailAction | ICreateSingleProfileStartAction
  | ICreateSingleProfileSuccessAction | ICreateSingleProfileFailAction | IUpdateSingleProfileStartAction | IUpdateSingleProfileSuccessAction
  | IUpdateSingleProfileFailAction | ISetProfileListRedirectPath;
