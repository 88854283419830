import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Search from '../../../components/UI/Search/Search';
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from '../../../components/UI/Table/Table';
import { loadingAppSelector } from '../../../store/global/selector';
import { getTestReports, setTestReportListRedirectPath } from '../../../store/test_reports';
import { setLoginRedirectPath } from '../../../store/login';
import styles from './TestReportList.module.css';
import { searchByOptions } from '../../../utils/test_reports/Formatters';
import Pagination from '../../../components/UI/Pagination/Pagination';
import * as StringURLHelper from '../../../utils/StringURLHelper';
import { formatOptionalField } from '../../../utils/FieldFormatHelper';
import PaginationItemsSelector from '../../../components/UI/PaginationItemsSelector/PaginationItemsSelector';

interface ITestReportListProps {
  token: string;
  history: any;
  location: any;
  testReports: [];
  pages: number;
  index: number;
  loading: boolean;
  onSetLoginRedirectPath(path: string): any;
  onSetTestReportListRedirectPath(path: string): any;
  onGetTestReports(token: string, index: number, items: number, searchBy: any, search: any): any;
}

const TestReportListHeaders = [
  'ID',
  'Issue',
  'Project',
  'Account',
  'Tester',
  'Developer',
  'QA Status',
  'Status'
];

const createDataRow = (testReport: any) => {
  return [
    testReport.id,
    testReport.issueId,
    formatOptionalField(testReport.project),
    formatOptionalField(testReport.account.description),
    formatOptionalField(testReport.reviewerUser.id ? `${testReport.reviewerUser.name} ${testReport.reviewerUser.lastName}` : ''),
    formatOptionalField(testReport.reviewedUser.id ? `${testReport.reviewedUser.name} ${testReport.reviewedUser.lastName}` : ''),
    formatOptionalField(testReport.status),
    formatOptionalField(testReport.testStatus.description),
  ];
};

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    testReports: state.testReports.testReportsList,
    pages: state.testReports.testReportsPages,
    index: state.testReports.index,
    loading: loadingAppSelector(state)
  }
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
    onGetTestReports: (token: string, index: number, items: number, searchBy: any, search: any) => dispatch(getTestReports(token, index, items, searchBy, search)),
    onSetTestReportListRedirectPath: (path: string) => dispatch(setTestReportListRedirectPath(path)),
  }
};

class TestReportList extends React.Component<ITestReportListProps> {
  state = {
    testReports: []
  };

  componentDidMount(): void {
    const { token, onSetLoginRedirectPath, onGetTestReports, location, onSetTestReportListRedirectPath } = this.props;
    onSetLoginRedirectPath('/testReports');
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    const items = queries.items ? parseInt(queries.items as any, 10) : 10;
    onSetTestReportListRedirectPath(`/testReports?${queryString.stringify(queries)}`);
    onGetTestReports(token, page, items, queries.searchBy, queries.search);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const { token, onSetLoginRedirectPath, onGetTestReports, location, onSetTestReportListRedirectPath } = this.props;
      onSetLoginRedirectPath('/testReports');
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      const items = queries.items ? parseInt(queries.items as any, 10) : 10;
      onSetTestReportListRedirectPath(`/testReports?${queryString.stringify(queries)}`);
      onGetTestReports(token, page, items, queries.searchBy, queries.search);
    }
  }

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath(`/testReports/${row[0]}/details`);
    this.props.history.push(`/testReports/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    this.props.history.push(`/testReports/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  }

  render() {
    const { testReports, loading, location, pages, index, history } = this.props;
    const testReportsRows = testReports.map(testReport => {
      return createDataRow(testReport);
    });
    const spinner = loading? <Spinner type="linear" /> : null;
    const page = index? parseInt(index as any, 10) : 1;
    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Test Reports </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        {/* <div className={`d-flex justify-content-end ${styles.AddButton}`}>
          <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
        </div> */}
        <div className={styles.Table}>
        <div className={`d-flex justify-content-between`}>

          <Search
            location={location}
            searchByOptions={searchByOptions}
            history={history}
          />
          <PaginationItemsSelector location={location} onPageRedirection={(qStr: string) => this.onPageRedirection(qStr)} />
        </div>
          <Table headers={TestReportListHeaders} rows={testReportsRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(TestReportList);
