import { FETCH_PROJECTS_FAIL,
  FETCH_PROJECTS_START, FETCH_PROJECTS_SUCCESS,
  IFetchProjectsFailAction, IFetchProjectsStartAction, IFetchProjectsSuccessAction, IProjectActionTypes, IProjectState, } from './types';

const initialState: IProjectState = {
  projectsList: [],
  error: null,
  loading: false,
};

const fetchProjectsStart = (state: IProjectState, action: IFetchProjectsStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchProjectsSuccess = (state: IProjectState, action: IFetchProjectsSuccessAction) => {
  return {
    ...state,
    projectsList: action.projectsList,
    error: null,
    loading: false,
  };
};

const fetchProjectsFail = (state: IProjectState, action: IFetchProjectsFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const reducer = (state = initialState, action: IProjectActionTypes): IProjectState => {
  switch ( action.type ) {
    case FETCH_PROJECTS_START: return fetchProjectsStart(state, action);
    case FETCH_PROJECTS_SUCCESS: return fetchProjectsSuccess(state, action);
    case FETCH_PROJECTS_FAIL: return fetchProjectsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
