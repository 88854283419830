import * as api from '../../services/api';

import {
  ITestReportActionTypes,
  CLEAR_SINGLE_TEST_REPORT,
  CREATE_SINGLE_TEST_REPORT_FAIL,
  CREATE_SINGLE_TEST_REPORT_START,
  CREATE_SINGLE_TEST_REPORT_SUCCESS,
  DELETE_SINGLE_TEST_REPORT_FAIL,
  DELETE_SINGLE_TEST_REPORT_START,
  DELETE_SINGLE_TEST_REPORT_SUCCESS,
  FETCH_SINGLE_TEST_REPORT_FAIL,
  FETCH_SINGLE_TEST_REPORT_START,
  FETCH_SINGLE_TEST_REPORT_SUCCESS,
  FETCH_TEST_REPORTS_FAIL,
  FETCH_TEST_REPORTS_START,
  FETCH_TEST_REPORTS_SUCCESS,
  SET_TEST_REPORT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_TEST_REPORT_FAIL,
  UPDATE_SINGLE_TEST_REPORT_START,
  UPDATE_SINGLE_TEST_REPORT_SUCCESS,
  ARCHIVE_SINGLE_TEST_REPORT_START,
  ARCHIVE_SINGLE_TEST_REPORT_SUCCESS,
  ARCHIVE_SINGLE_TEST_REPORT_FAIL,
  UNARCHIVE_SINGLE_TEST_REPORT_START,
  UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS,
  UNARCHIVE_SINGLE_TEST_REPORT_FAIL,
} from './types';

export const fetchSingleTestReportStart = (): ITestReportActionTypes => {
    return {
      type: FETCH_SINGLE_TEST_REPORT_START
    };
};

export const fetchSingleTestReportSuccess = (currentTestReport: {}): ITestReportActionTypes => {
    return {
      type: FETCH_SINGLE_TEST_REPORT_SUCCESS,
      currentTestReport,
    };
};

export const fetchSingleTestReportFail = (error: Error): ITestReportActionTypes => {
  return {
    type: FETCH_SINGLE_TEST_REPORT_FAIL,
    error,
  };
};

export const clearSingleTestReport = (): ITestReportActionTypes => {
  return {
    type: CLEAR_SINGLE_TEST_REPORT,
  };
};

export const createSingleTestReportStart = (): ITestReportActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_REPORT_START
  };
};

export const createSingleTestReportSuccess = (currentTestReport: {}): ITestReportActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_REPORT_SUCCESS,
    currentTestReport,
  };
};

export const createSingleTestReportFail = (error: Error): ITestReportActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_REPORT_FAIL,
    error,
  };
};

export const updateSingleTestReportStart = (): ITestReportActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_REPORT_START
  };
};

export const updateSingleTestReportSuccess = (currentTestReport: {}): ITestReportActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_REPORT_SUCCESS,
    currentTestReport,
  };
};

export const updateSingleTestReportFail = (error: Error): ITestReportActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_REPORT_FAIL,
    error,
  };
};

export const deleteSingleTestReportStart = (): ITestReportActionTypes => {
  return {
    type: DELETE_SINGLE_TEST_REPORT_START,
  };
};

export const deleteSingleTestReportSuccess = (): ITestReportActionTypes => {
  return {
    type: DELETE_SINGLE_TEST_REPORT_SUCCESS,
  };
};

export const deleteSingleTestReportFail= (error: Error): ITestReportActionTypes => {
  return {
    type: DELETE_SINGLE_TEST_REPORT_FAIL,
    error
  };
};

export const fetchTestReportsStart = (): ITestReportActionTypes => {
  return {
    type: FETCH_TEST_REPORTS_START
  };
};

export const fetchTestReportsSuccess = (testReportsList: [], testReportsPages: number, index: number): ITestReportActionTypes => {
  return {
    type: FETCH_TEST_REPORTS_SUCCESS,
    testReportsList,
    testReportsPages,
    index,
  };
};

export const fetchTestReportsFail = (error: Error): ITestReportActionTypes => {
  return {
    type: FETCH_TEST_REPORTS_FAIL,
    error,
  };
};

export const setTestReportListRedirectPath = (path: string): ITestReportActionTypes => {
  return {
    type: SET_TEST_REPORT_LIST_REDIRECT_PATH,
    path
  };
};

export const archiveSingleTestReportStart = (): ITestReportActionTypes => {
  return {
    type: ARCHIVE_SINGLE_TEST_REPORT_START
  };
};

export const archiveSingleTestReportSuccess = (currentTestReport: {}): ITestReportActionTypes => {
  return {
    type: ARCHIVE_SINGLE_TEST_REPORT_SUCCESS,
    currentTestReport,
  };
};

export const archiveSingleTestReportFail = (error: Error): ITestReportActionTypes => {
  return {
    type: ARCHIVE_SINGLE_TEST_REPORT_FAIL,
    error,
  };
};

export const unarchiveSingleTestReportStart = (): ITestReportActionTypes => {
  return {
    type: UNARCHIVE_SINGLE_TEST_REPORT_START
  };
};

export const unarchiveSingleTestReportSuccess = (currentTestReport: {}): ITestReportActionTypes => {
  return {
    type: UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS,
    currentTestReport,
  };
};

export const unarchiveSingleTestReportFail = (error: Error): ITestReportActionTypes => {
  return {
    type: UNARCHIVE_SINGLE_TEST_REPORT_FAIL,
    error,
  };
};


export const getTestReportById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleTestReportStart());
    return api.getTestReportById(token, id)
      .then(response => {
        dispatch(fetchSingleTestReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleTestReportFail(error));
      });
  }
};

export const deleteTestReportById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleTestReportStart());
     return api.deleteTestReportById(token, id)
        .then(response => {
          dispatch(deleteSingleTestReportSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleTestReportFail(error));
        });
  }
};

export const getTestReports = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchTestReportsStart());
    return api.getTestReports(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchTestReportsSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchTestReportsFail(error));
      });
  }
};

export const createTestReport = (token: string, testReport: any) => {
  return (dispatch: any) => {
    dispatch(createSingleTestReportStart());
    return api.createTestReport(token, testReport)
      .then(response => {
        dispatch(createSingleTestReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleTestReportFail(error));
      });
  }
};

export const updateTestReport = (token: string, testReport: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleTestReportStart());
    return api.updateTestReport(token, testReport, id)
      .then(response => {
        dispatch(updateSingleTestReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleTestReportFail(error));
      });
  }
};

export const archiveTestReportById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(archiveSingleTestReportStart());
    return api.archiveTestReportById(token, id)
      .then(response => {
        dispatch(archiveSingleTestReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(archiveSingleTestReportFail(error));
      });
  }
}

export const unarchiveTestReportById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(unarchiveSingleTestReportStart());
    return api.unarchiveTestReportById(token, id)
      .then(response => {
        dispatch(unarchiveSingleTestReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(unarchiveSingleTestReportFail(error));
      });
  }
}