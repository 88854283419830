import * as api from '../../services/api';

import {
  CLEAR_SINGLE_BROWSER,
  CREATE_SINGLE_BROWSER_FAIL,
  CREATE_SINGLE_BROWSER_START,
  CREATE_SINGLE_BROWSER_SUCCESS,
  DELETE_SINGLE_BROWSER_FAIL,
  DELETE_SINGLE_BROWSER_START,
  DELETE_SINGLE_BROWSER_SUCCESS,
  FETCH_BROWSERS_FAIL,
  FETCH_BROWSERS_START,
  FETCH_BROWSERS_SUCCESS,
  FETCH_SINGLE_BROWSER_FAIL,
  FETCH_SINGLE_BROWSER_START,
  FETCH_SINGLE_BROWSER_SUCCESS,
  IBrowserActionTypes,
  SET_BROWSER_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_BROWSER_FAIL,
  UPDATE_SINGLE_BROWSER_START,
  UPDATE_SINGLE_BROWSER_SUCCESS,
} from './types';

export const fetchSingleBrowserStart = (): IBrowserActionTypes => {
    return {
      type: FETCH_SINGLE_BROWSER_START
    };
};

export const fetchSingleBrowserSuccess = (currentBrowser: {}): IBrowserActionTypes => {
    return {
      type: FETCH_SINGLE_BROWSER_SUCCESS,
      currentBrowser,
    };
};

export const fetchSingleBrowserFail = (error: Error): IBrowserActionTypes => {
  return {
    type: FETCH_SINGLE_BROWSER_FAIL,
    error,
  };
};

export const clearSingleBrowser = (): IBrowserActionTypes => {
  return {
    type: CLEAR_SINGLE_BROWSER,
  };
};

export const createSingleBrowserStart = (): IBrowserActionTypes => {
  return {
    type: CREATE_SINGLE_BROWSER_START
  };
};

export const createSingleBrowserSuccess = (currentBrowser: {}): IBrowserActionTypes => {
  return {
    type: CREATE_SINGLE_BROWSER_SUCCESS,
    currentBrowser,
  };
};

export const createSingleBrowserFail = (error: Error): IBrowserActionTypes => {
  return {
    type: CREATE_SINGLE_BROWSER_FAIL,
    error,
  };
};

export const updateSingleBrowserStart = (): IBrowserActionTypes => {
  return {
    type: UPDATE_SINGLE_BROWSER_START
  };
};

export const updateSingleBrowserSuccess = (currentBrowser: {}): IBrowserActionTypes => {
  return {
    type: UPDATE_SINGLE_BROWSER_SUCCESS,
    currentBrowser,
  };
};

export const updateSingleBrowserFail = (error: Error): IBrowserActionTypes => {
  return {
    type: UPDATE_SINGLE_BROWSER_FAIL,
    error,
  };
};

export const deleteSingleBrowserStart = (): IBrowserActionTypes => {
  return {
    type: DELETE_SINGLE_BROWSER_START,
  };
};

export const deleteSingleBrowserSuccess = (): IBrowserActionTypes => {
  return {
    type: DELETE_SINGLE_BROWSER_SUCCESS,
  };
};

export const deleteSingleBrowserFail= (error: Error): IBrowserActionTypes => {
  return {
    type: DELETE_SINGLE_BROWSER_FAIL,
    error
  };
};

export const fetchBrowsersStart = (): IBrowserActionTypes => {
  return {
    type: FETCH_BROWSERS_START
  };
};

export const fetchBrowsersSuccess = (browsersList: [], browsersPages: number, index: number): IBrowserActionTypes => {
  return {
    type: FETCH_BROWSERS_SUCCESS,
    browsersList,
    browsersPages,
    index,
  };
};

export const fetchBrowsersFail = (error: Error): IBrowserActionTypes => {
  return {
    type: FETCH_BROWSERS_FAIL,
    error,
  };
};

export const setBrowserListRedirectPath = (path: string): IBrowserActionTypes => {
  return {
    type: SET_BROWSER_LIST_REDIRECT_PATH,
    path
  };
};

export const getBrowserById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleBrowserStart());
    return api.getBrowserById(token, id)
      .then(response => {
        dispatch(fetchSingleBrowserSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleBrowserFail(error));
      });
  }
};

export const deleteBrowserById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleBrowserStart());
     return api.deleteBrowserById(token, id)
        .then(response => {
          dispatch(deleteSingleBrowserSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleBrowserFail(error));
        });
  }
};

export const getBrowsers = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchBrowsersStart());
    return api.getBrowsers(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchBrowsersSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchBrowsersFail(error));
      });
  }
};

export const createBrowser = (token: string, browser: any) => {
  return (dispatch: any) => {
    dispatch(createSingleBrowserStart());
    return api.createBrowser(token, browser)
      .then(response => {
        dispatch(createSingleBrowserSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleBrowserFail(error));
      });
  }
};

export const updateBrowser = (token: string, browser: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleBrowserStart());
    return api.updateBrowser(token, browser, id)
      .then(response => {
        dispatch(updateSingleBrowserSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleBrowserFail(error));
      });
  }
};
