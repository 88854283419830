import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Dropdown from '../Dropdown/Dropdown';
import { loadingAppSelector } from '../../../store/global/selector';
import styles from './PaginationItemsSelector.module.css';
import * as StringURLHelper from '../../../utils/StringURLHelper';

interface IPaginationItemsSelectorProps {
  loading: boolean;
  location: any;
  onPageRedirection(qStr: string): any;
};

const itemsOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '30',
    value: 30
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '200',
    value: 200
  },
];

const initialState = {
  formControls: {
     items: '',
  },
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    loading: loadingAppSelector(state),
  };
}
class PaginationItemsSelector extends React.Component<IPaginationItemsSelectorProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { location } = this.props;
    const queries = queryString.parse(location.search);
    if (queries.items) {
      this.setState({
        ...this.state,
        formControls: {
          ...this.state.formControls,
          items: queries.items,
        },
      });
    }
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const { value } = event;
  
    this.setState({
      ...this.state,
      formControls: {
        ...this.state.formControls,
        items: value
      },
    }, this.submitPaginationItemsChange);
  };

  submitPaginationItemsChange = () => {
    const { location, onPageRedirection } = this.props;
    const { items } = this.state.formControls;
    const queryParams = [
      {
        key: 'items',
        value: items,
      },
    ];
    const queryAsString = StringURLHelper.appendToURl(location, queryParams);
    const query = queryString.parse(queryAsString);
    query.page = '1';
    onPageRedirection(queryString.stringify(query));
  };

  render() {
    const { loading, } = this.props;
    const { items, } = this.state.formControls;
    return (
      <div className="form-row" style={{ width: '20%', }}>
          <div className={styles.PaginationItemsSelectorInputContainer}>
          <div className={styles.PaginationItemsDropdown}>
            <Dropdown
            value={items}
            disabled={loading}
            options={itemsOptions}
            placeholder="Entries to Display"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event)}
          />
          </div>
          </div>
      </div>  
    );
  };
}

export default connect(matchStateToProps, null)(PaginationItemsSelector);

