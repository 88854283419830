import axios from '../axios-instance';

export const getTestReports = (token: string, index: number, items: number, searchBy: any, search: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  let path = '/submit';
  if (index !== -1) {
    path += `?items=${items? items: 10}&index=${index}`;
    path += search?  `&search=${search}` : '';
  }
  return axios.get(path, options);
};

export const getTestReportById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.get(`/submit/${id}`, options);
};

export const createTestReport = (token: string, testReport: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.post('/submit/adm', testReport, options);
};

export const updateTestReport = (token: string, testReport: any, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.put(`/submit/${id}`, testReport, options);
};

export const deleteTestReportById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.delete(`/submit/${id}`, options);
};

export const archiveTestReportById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.put(`/submit/${id}/archive`, {}, options);
}

export const unarchiveTestReportById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.put(`/submit/${id}/unarchive`, {}, options);
}