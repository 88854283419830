export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';

export interface IProjectState {
  projectsList: [],
  error: any;
  loading: boolean;
}

export interface IFetchProjectsStartAction {
  type: typeof FETCH_PROJECTS_START;
}

export interface IFetchProjectsSuccessAction {
  type: typeof FETCH_PROJECTS_SUCCESS;
  projectsList: [];
}

export interface IFetchProjectsFailAction {
  type: typeof FETCH_PROJECTS_FAIL;
  error: object;
}

export type IProjectActionTypes = IFetchProjectsStartAction | IFetchProjectsSuccessAction | IFetchProjectsFailAction;
