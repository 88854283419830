import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleBrowser, getBrowserById, updateBrowser } from '../../../store/browsers';
import BrowserForm from '../BrowserForm/BrowserForm';
import styles from './BrowserEdit.module.css';
import * as _ from 'lodash';
import * as BrowserValidator from '../../../utils/browsers/BrowserValidator';

interface IBrowserEditProps {
  token: string;
  browser: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  browsersError: any;
  browserListRedirectPath: string;
  onUpdateBrowser(token: string, browser: any, id: string): any;
  onGetBrowserById(token: string, id: string): any;
  onClearSingleBrowser(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    browser: state.browsers.currentBrowser,
    index: state.browsers.index,
    loading: loadingAppSelector(state),
    browserListRedirectPath: state.browsers.browserListRedirectPath,
    browsersError: state.browsers.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateBrowser: (token: string, browser: any, id: string) => dispatch(updateBrowser(token, browser, id)),
    onGetBrowserById: (token: string, id: string) => dispatch(getBrowserById(id, token)),
    onClearSingleBrowser: () => dispatch(clearSingleBrowser()),
  }
};

class BrowserEdit extends React.Component<IBrowserEditProps>{

  async componentDidMount() {
    const { match, onGetBrowserById, token } = this.props;
    await onGetBrowserById(token, match.params.id);
  }

  onSubmitBrowser = async (browserPayload: any) => {
    const { onUpdateBrowser, browser, token, history } = this.props;
    const browserChanges = BrowserValidator.identifyBrowserChanges(browser, browserPayload);
    if (_.isEmpty(browserChanges)) {
      history.push(`/browsers/${browser.id}/details`);
      return;
    }
    await onUpdateBrowser(token, browserChanges, browser.id);
    const { browsersError } = this.props;
    if (!browsersError) {
      history.push(`/browsers/${browser.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleBrowser } = this.props;
    onClearSingleBrowser();
  }

  render() {
    const { browser, match, loading, index, browserListRedirectPath } = this.props;
    console.log('BROWSER', browser);
    let browserForm = null;
    if (browser && browser.id.toString() === match.params.id) {
      browserForm = <BrowserForm onSubmitBrowser={this.onSubmitBrowser} browserFromProps={browser}  />;
    }
    const browserListURL = browserListRedirectPath? browserListRedirectPath : `/browsers?&page=${index? index : 1}`;        
    
    return (
      <div className={styles.BrowserEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={browserListURL} exact>
                Browsers
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/browsers/${match.params.id}/details`} exact>
                {browser? browser.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/browsers/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { browserForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(BrowserEdit);
