import * as React from 'react';
import { Container, Menu } from'semantic-ui-react';
import Logo from '../../Logo/Logo';
import NavigationItem from './NavigationItem/NavigationItem';
import Can from '../../Can/Can';

interface INavigationItems {
  classes?: string;
}

const renderAdminNavigationItems = () => {
  return (
    <>
      <NavigationItem link="/" exact><Logo height="34px" style={{marginRight: '1.5em'}}/> QA Metrics ADM </NavigationItem>
      <NavigationItem link="/" exact>Test Reports</NavigationItem>
      <NavigationItem link="/users" exact>Users</NavigationItem>
      <NavigationItem link="/profiles" exact>Profiles</NavigationItem>
      <NavigationItem link="/accounts" exact>Accounts</NavigationItem>
      <NavigationItem link="/environments" exact>Environments</NavigationItem>
      <NavigationItem link="/devices" exact>Devices</NavigationItem>
      <NavigationItem link="/browsers" exact>Browsers</NavigationItem>
      <NavigationItem link="/status" exact>Status</NavigationItem>
      <NavigationItem link="/logout" position="right">Logout</NavigationItem>
    </>
  )
};

const renderNonAdminNavigationItems = () => {
  return (
    <>
      <NavigationItem link="/" exact><Logo height="34px" style={{marginRight: '1.5em'}}/> QA Metrics ADM </NavigationItem>
      <NavigationItem link="/" exact>Test Reports</NavigationItem>
      <NavigationItem link="/users" exact>Users</NavigationItem>
      <NavigationItem link="/logout" position="right">Logout</NavigationItem>
    </>
  );
};

const navigationItems: React.FunctionComponent<INavigationItems> = (props) => {
  const { classes } = props;


  return (
    <div className={classes}>
      <Menu fixed="top" inverted style={{backgroundColor: '#062F6E'}}>
        <Container style={{width: '75%'}}>
          <Can
            perform="adminRoutes"
            data={null}
            yes={renderAdminNavigationItems}
            no={renderNonAdminNavigationItems}
          />
        </Container>
      </Menu>
    </div>
  );
};

export default navigationItems;