interface IBrowserUpdateData {
  description?: string;
  active?: boolean;

}

export const identifyBrowserChanges = (prevBrowser: any, newBrowser: any) => {
  const changes: IBrowserUpdateData = {};
  if (newBrowser.description && (prevBrowser.description !== newBrowser.description)) {
    changes.description = newBrowser.description;
  }

  if (prevBrowser.active !== newBrowser.active) {
    changes.active = newBrowser.active;
  }

  return changes;
}