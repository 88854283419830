import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Table from '../../../../components/UI/Table/Table';
import { loadingAppSelector } from '../../../../store/global/selector';
import { getTestCases, setTestCaseListRedirectPath } from '../../../../store/test_cases';
import { setLoginRedirectPath } from '../../../../store/login';
import styles from './TestCaseList.module.css';
import Pagination from '../../../../components/UI/Pagination/Pagination';
import * as StringURLHelper from '../../../../utils/StringURLHelper';
import { getTestReportById } from '../../../../store/test_reports';
import TestStatusValues from '../../../../utils/test_status/TestStatusValues';

interface ITestCaseListProps {
  currentTestReport: any;
  token: string;
  history: any;
  location: any;
  testCases: [];
  pages: number;
  match: any;
  testReportIndex: number;
  index: number;
  loading: boolean;
  testReportListRedirectPath: string;
  onSetLoginRedirectPath(path: string): any;
  onSetTestCaseListRedirectPath(path: string): any;
  onGetTestCases(token: string, testReportId: number, index: number, searchBy: any, search: any): any;
  onGetTestReportById(id: string, token: string): any;
}

const TestCaseListHeaders = [
  'ID',
  'Description',
  'Status',
];

const createDataRow = (testCase: any) => {
  return [
    testCase.id,
    testCase.description,
    testCase.status,
  ];
};

const matchStateToProps = (state: any) => {
  return {
    currentTestReport: state.testReports.currentTestReport,
    token: state.login.token,
    testCases: state.testCases.testCasesList,
    pages: state.testCases.testCasesPages,
    testReportIndex: state.testReports.index,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    index: state.testCases.index,
    loading: loadingAppSelector(state)
  }
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
    onGetTestCases: (token: string, testReportId: number, index: number, searchBy: any, search: any) => dispatch(getTestCases(token, testReportId, index, searchBy, search)),
    onSetTestCaseListRedirectPath: (path: string) => dispatch(setTestCaseListRedirectPath(path)),
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
  }
};

class TestCaseList extends React.Component<ITestCaseListProps> {
  state = {
    testCases: []
  };

  componentDidMount(): void {
    const { token, match, onSetLoginRedirectPath, onGetTestCases, location, onSetTestCaseListRedirectPath, onGetTestReportById } = this.props;
    onSetLoginRedirectPath(`/testReports/${match.params.id}/testCases`);
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    onSetTestCaseListRedirectPath(`/testReports/${match.params.id}/testCases?${queryString.stringify(queries)}`);
    onGetTestCases(token, match.params.id, page, queries.searchBy, queries.search);
    onGetTestReportById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const { token, onSetLoginRedirectPath, onGetTestCases, location, match, onSetTestCaseListRedirectPath, onGetTestReportById } = this.props;
      onSetLoginRedirectPath(`/testReports/${match.params.id}/testCases`);
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      onSetTestCaseListRedirectPath(`/testReports/${match.params.id}/testCases?${queryString.stringify(queries)}`);
      onGetTestCases(token, match.params.id, page, queries.searchBy, queries.search);
      onGetTestReportById(match.params.id, token);
    }
  }

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath, match } = this.props;
    onSetLoginRedirectPath(`/testReports/${match.params.id}/testCases/${row[0]}/details`);
    this.props.history.push(`/testReports/${match.params.id}/testCases/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    const { onSetLoginRedirectPath, match } = this.props;
    onSetLoginRedirectPath(`/testReports/${match.params.id}/testCases/create `);
    this.props.history.push(`/testReports/${match.params.id}/testCases/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  }

  onSearch = (searchString: string, searchBy: string) => {
    const { location } = this.props;
    const queryParams = [
      {
        key: 'search',
        value: searchString,
      },
      {
        key: 'searchBy',
        value: searchBy,
      },
    ];
    const queries = StringURLHelper.appendToURl(location, queryParams);
    this.onPageRedirection(queries);
  };

  render() {
    const { testCases, loading, location, pages, index, match, testReportIndex, testReportListRedirectPath, currentTestReport } = this.props;
    const testCasesRows = testCases.map(testCase => {
      return createDataRow(testCase);
    });
    const spinner = loading? <Spinner type="linear" /> : null;
    const page = index? parseInt(index as any, 10) : 1;
    const testReportListURL = testReportListRedirectPath ? testReportListRedirectPath : `/testReports?&page=${testReportIndex? testReportIndex : 1}`;

    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section>
                <NavigationItem link={testReportListURL} exact>
                  Test Reports
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section>
                <NavigationItem link={`/testReports/${match.params.id}/details`} exact>
                  {match.params.id}
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Test Cases </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/testReports/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          {currentTestReport && currentTestReport.testStatusId !== TestStatusValues.ARCHIVED &&
            (
              <div className={`d-flex justify-content-end ${styles.AddButton}`}>
                <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
              </div>
            )
          }
        </div>
        <div className={styles.Table}>
        {/* <Search
          location={location}
          searchByOptions={searchByOptions}
          onSearch={(searchString: string, searchBy: string) => this.onSearch(searchString, searchBy)}
          defaultRedirection={() => this.onPageRedirection('')}
        /> */}

          <Table headers={TestCaseListHeaders} rows={testCasesRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(TestCaseList);
