import * as React from 'react';
import { connect } from 'react-redux';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import Input from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { IReduxState } from '../../../store/global/types';
import { inputValidator } from '../../../utils';
import styles from './BrowserForm.module.css';
import * as _ from 'lodash';

interface IBrowserFormProps {
  token: string;
  browser: any;
  browserFromProps?: any;
  loading: boolean;
  onSubmitBrowser(browserPayload: any): any;
}

const browserActiveOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const initialState = {
  formControls: {
    description: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 255,
      },
      valid: false,
      touched: false
    },
    active: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
  },
  formIsValid: false,
  showModal: false,
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: IReduxState) => {
  return {
    token: state.login.token,
    browser: state.browsers.currentBrowser,
    loading: loadingAppSelector(state),
  };
};

const formatBrowserFromProps = (browserFromProps: any): any => {
  return {
    description: browserFromProps.description? browserFromProps.description : '',
    active: browserFromProps.active,
  };
};

class BrowserForm extends React.Component<IBrowserFormProps>{
  constructor(props: IBrowserFormProps) {
    super(props);
    this.state = initialState;
  }
  state: State;


  componentDidMount(): void {
    const { browserFromProps } = this.props;
    if (browserFromProps) {
      const formattedBrowser = formatBrowserFromProps(browserFromProps);
      const { formControls } = this.state;
      let updatedFormControls = { ...formControls };

      Object.keys(formControls).forEach((key: string) => {
        const updatedFormInput= {
          ...updatedFormControls[key as FormControlKey],
          value: formattedBrowser[key],
          valid: inputValidator(formattedBrowser[key], formControls[key as FormControlKey].validation),
          touched: true,
        };
        updatedFormControls = {
          ...updatedFormControls,
          [key]: updatedFormInput,
        };
      });
      this.setState({ formControls: updatedFormControls });
    }
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { value } = isSelect? event : event.target;

    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value,
      valid: inputValidator(value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state.
    const { formControls } = this.state;
    const { value } = isSelect? event : event.target;
    let validations = {};
    if (!isSelect) {
      const curedValue= !_.isEmpty(value.toString().trim())? value : '';
      validations = {
        valid: inputValidator(curedValue, formControls[inputIdentifier].validation),
        value: curedValue,
      };
    };

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
      ...validations
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };
    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  browserSubmitHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { onSubmitBrowser } = this.props;
    const { description, active } = this.state.formControls
    await onSubmitBrowser({
      description: description.value,
      // @ts-ignore
      active: active.value === false ? false : true,
    });
  };

  render() {
    const { loading, browserFromProps } = this.props;
    const { description, active } = this.state.formControls;
    const { formIsValid } = this.state;
    const spinner = loading? <Spinner type="linear" /> : null;

    return (
        <div className={styles.Form}>
          <div className={styles.Spinner}>
            { spinner }
          </div>
          <form>
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type a description', autoFocus: true}}
              value={description.value}
              shouldValidate={true}
              valid={description.valid}
              touched={description.touched}
              label="Description"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'description', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'description', false)}
            />
            <Dropdown
              value={active.value}
              label="Active"
              disabled={loading}
              options={browserActiveOptions}
              shouldValidate={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'active', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'active', true)}
            />
            <div className={`d-flex justify-content-center ${styles.Buttons}`}>
              <Button type="submit" color="success" disabled={!formIsValid || loading} onClick={(event: React.MouseEvent<HTMLElement>) => this.browserSubmitHandler(event)}>
                Save
              </Button>
              <div className={styles.Divider}/>
              <NavigationItem link={browserFromProps? `/browsers/${browserFromProps.id}/details` : '/browsers'} exact>
                <Button type="submit" color="danger" disabled={loading}>
                  Cancel
                </Button>
              </NavigationItem>
            </div>
          </form>
        </div>
    );
  }

}

export default connect(matchStateToProps, null)(BrowserForm);
