import {
  CLEAR_SINGLE_PROFILE,
  CREATE_SINGLE_PROFILE_FAIL,
  CREATE_SINGLE_PROFILE_START,
  CREATE_SINGLE_PROFILE_SUCCESS,
  DELETE_SINGLE_PROFILE_FAIL,
  DELETE_SINGLE_PROFILE_START,
  DELETE_SINGLE_PROFILE_SUCCESS,
  FETCH_PROFILES_FAIL,
  FETCH_PROFILES_START,
  FETCH_PROFILES_SUCCESS,
  FETCH_SINGLE_PROFILE_FAIL,
  FETCH_SINGLE_PROFILE_START,
  FETCH_SINGLE_PROFILE_SUCCESS,
  IClearSingleProfileAction,
  ICreateSingleProfileFailAction,
  ICreateSingleProfileStartAction,
  ICreateSingleProfileSuccessAction,
  IDeleteSingleProfileFailAction,
  IDeleteSingleProfileStartAction,
  IDeleteSingleProfileSuccessAction,
  IFetchProfilesFailAction,
  IFetchProfilesStartAction,
  IFetchProfilesSuccessAction,
  IFetchSingleProfileFailAction,
  IFetchSingleProfileStartAction,
  IFetchSingleProfileSuccessAction,
  IProfileActionTypes,
  IProfileState,
  ISetProfileListRedirectPath,
  IUpdateSingleProfileFailAction,
  IUpdateSingleProfileStartAction,
  IUpdateSingleProfileSuccessAction,
  SET_PROFILE_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_PROFILE_FAIL,
  UPDATE_SINGLE_PROFILE_START,
  UPDATE_SINGLE_PROFILE_SUCCESS,
} from './types';

const initialState: IProfileState = {
  currentProfile: null,
  profilesList: [],
  profilesPages: 0,
  index: 0,
  profileListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleProfileStart = (state: IProfileState, action: IFetchSingleProfileStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleProfileSuccess = (state: IProfileState, action: IFetchSingleProfileSuccessAction) => {
  return {
    ...state,
    currentProfile: action.currentProfile,
    error: null,
    loading: false
  };
};

const fetchSingleProfileFail = (state: IProfileState, action: IFetchSingleProfileFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleProfile = (state: IProfileState, action: IClearSingleProfileAction) => {
  return {
    ...state,
    currentProfile: null
  };
};

const createSingleProfileStart = (state: IProfileState, action: ICreateSingleProfileStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleProfileSuccess = (state: IProfileState, action: ICreateSingleProfileSuccessAction) => {
  return {
    ...state,
    currentProfile: action.currentProfile,
    error: null,
    loading: false
  };
};

const createSingleProfileFail = (state: IProfileState, action: ICreateSingleProfileFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleProfileStart = (state: IProfileState, action: IUpdateSingleProfileStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleProfileSuccess = (state: IProfileState, action: IUpdateSingleProfileSuccessAction) => {
  return {
    ...state,
    currentProfile: action.currentProfile,
    error: null,
    loading: false
  };
};

const updateSingleProfileFail = (state: IProfileState, action: IUpdateSingleProfileFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleProfileStart = (state: IProfileState, action: IDeleteSingleProfileStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleProfileSuccess = (state: IProfileState, action: IDeleteSingleProfileSuccessAction) => {
  return {
    ...state,
    currentProfile: null,
    error: null,
    loading: false
  };
};

const deleteSingleProfileFail = (state: IProfileState, action: IDeleteSingleProfileFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchProfilesStart = (state: IProfileState, action: IFetchProfilesStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchProfilesSuccess = (state: IProfileState, action: IFetchProfilesSuccessAction) => {
  return {
    ...state,
    profilesList: action.profilesList,
    error: null,
    loading: false,
    profilesPages: action.profilesPages,
    index: action.index,
  };
};

const fetchProfilesFail = (state: IProfileState, action: IFetchProfilesFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setProfileListRedirectPath = (state: IProfileState, action: ISetProfileListRedirectPath) => {
  return {
    ...state,
    profileListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IProfileActionTypes): IProfileState => {
  switch ( action.type ) {
    case FETCH_SINGLE_PROFILE_START: return fetchSingleProfileStart(state, action);
    case FETCH_SINGLE_PROFILE_SUCCESS: return fetchSingleProfileSuccess(state, action);
    case FETCH_SINGLE_PROFILE_FAIL: return fetchSingleProfileFail(state, action);
    case CREATE_SINGLE_PROFILE_START: return createSingleProfileStart(state, action);
    case CREATE_SINGLE_PROFILE_SUCCESS: return createSingleProfileSuccess(state, action);
    case CREATE_SINGLE_PROFILE_FAIL: return createSingleProfileFail(state, action);
    case UPDATE_SINGLE_PROFILE_START: return updateSingleProfileStart(state, action);
    case UPDATE_SINGLE_PROFILE_SUCCESS: return updateSingleProfileSuccess(state, action);
    case UPDATE_SINGLE_PROFILE_FAIL: return updateSingleProfileFail(state, action);
    case DELETE_SINGLE_PROFILE_START: return deleteSingleProfileStart(state, action);
    case DELETE_SINGLE_PROFILE_SUCCESS: return deleteSingleProfileSuccess(state, action);
    case DELETE_SINGLE_PROFILE_FAIL: return deleteSingleProfileFail(state, action);
    case CLEAR_SINGLE_PROFILE: return clearSingleProfile(state, action);
    case FETCH_PROFILES_START: return fetchProfilesStart(state, action);
    case FETCH_PROFILES_SUCCESS: return fetchProfilesSuccess(state, action);
    case FETCH_PROFILES_FAIL: return fetchProfilesFail(state, action);
    case SET_PROFILE_LIST_REDIRECT_PATH: return setProfileListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
