import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';


interface IAuthenticatedRouteProps  {
  isAuthenticated: boolean;
}

const matchStateToProps = (state: any) => {
  return {
    isAuthenticated: state.login.token !== null,
  };
};

const authenticatedRoute: React.FunctionComponent<IAuthenticatedRouteProps & any> = (props) => {
  const { isAuthenticated} = props;

  return (
    isAuthenticated? <Route {...props} /> : <Redirect to="/login" />
  );
};

export default connect(matchStateToProps, null) (authenticatedRoute);
