import axios from '../axios-instance';

export const getTestStatus = (token: string, index: number, items: number, searchBy: any, search: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  let path = '/status';
  
  if (index !== -1) {
    path += `?items=${items ? items : 10}&index=${index}`;
    path += search ? `&search=${search}` : '';
  }

  return axios.get(path, options);
};

export const getTestStatusById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.get(`/status/${id}`, options);
};

export const createTestStatus = (token: string, testStatus: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.post('/status', testStatus, options);
};

export const updateTestStatus = (token: string, testStatus: any, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.put(`/status/${id}`, testStatus, options);
};

// export const deleteBrowserById = (token: string, id: string) => {
//   const options = {
//     headers: {
//       'content-type': 'application/json',
//       'Authorization': `Token ${token}`
//     },
//   };

//   return axios.delete(`/browsers/${id}`, options);
// };
