export const FETCH_SINGLE_USER_START = 'FETCH_SINGLE_USER_START';
export const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';
export const FETCH_SINGLE_USER_FAIL = 'FETCH_SINGLE_USER_FAIL';
export const CLEAR_SINGLE_USER = 'CLEAR_SINGLE_USER';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const CREATE_SINGLE_USER_START = 'CREATE_SINGLE_USER_START';
export const CREATE_SINGLE_USER_SUCCESS = 'CREATE_SINGLE_USER_SUCCESS';
export const CREATE_SINGLE_USER_FAIL = 'CREATE_SINGLE_USER_FAIL';

export const UPDATE_SINGLE_USER_START = 'UPDATE_SINGLE_USER_START';
export const UPDATE_SINGLE_USER_SUCCESS = 'UPDATE_SINGLE_USER_SUCCESS';
export const UPDATE_SINGLE_USER_FAIL = 'UPDATE_SINGLE_USER_FAIL';

export const DELETE_SINGLE_USER_START = 'DELETE_SINGLE_USER_START';
export const DELETE_SINGLE_USER_SUCCESS = 'DELETE_SINGLE_USER_SUCCESS';
export const DELETE_SINGLE_USER_FAIL = 'DELETE_SINGLE_USER_FAIL';

export const SET_USER_LIST_REDIRECT_PATH = 'SET_USER_LIST_REDIRECT_PATH';

export interface IUserState {
  currentUser: {} | null;
  usersList: [],
  userListRedirectPath: string | null,
  usersPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleUserStartAction {
  type: typeof FETCH_SINGLE_USER_START;
}

export interface IFetchSingleUserSuccessAction {
  type: typeof FETCH_SINGLE_USER_SUCCESS;
  currentUser: {};
}

export interface IFetchSingleUserFailAction {
  type: typeof FETCH_SINGLE_USER_FAIL;
  error: object;
}

export interface ICreateSingleUserStartAction {
  type: typeof CREATE_SINGLE_USER_START;
}

export interface ICreateSingleUserSuccessAction {
  type: typeof CREATE_SINGLE_USER_SUCCESS;
  currentUser: {};
}

export interface ICreateSingleUserFailAction {
  type: typeof CREATE_SINGLE_USER_FAIL;
  error: object;
}

export interface IUpdateSingleUserStartAction {
  type: typeof UPDATE_SINGLE_USER_START;
}

export interface IUpdateSingleUserSuccessAction {
  type: typeof UPDATE_SINGLE_USER_SUCCESS;
  currentUser: {};
}

export interface IUpdateSingleUserFailAction {
  type: typeof UPDATE_SINGLE_USER_FAIL;
  error: object;
}

export interface IDeleteSingleUserStartAction {
  type: typeof DELETE_SINGLE_USER_START;
}

export interface IDeleteSingleUserSuccessAction {
  type: typeof DELETE_SINGLE_USER_SUCCESS;
}

export interface IDeleteSingleUserFailAction {
  type: typeof DELETE_SINGLE_USER_FAIL;
  error: object;
}

export interface IClearSingleUserAction {
  type: typeof CLEAR_SINGLE_USER;
}

export interface IFetchUsersStartAction {
  type: typeof FETCH_USERS_START;
}

export interface IFetchUsersSuccessAction {
  type: typeof FETCH_USERS_SUCCESS;
  usersList: [];
  usersPages: number,
  index: number;
}

export interface IFetchUsersFailAction {
  type: typeof FETCH_USERS_FAIL;
  error: object;
}

export interface ISetUserListRedirectPath {
  type: typeof SET_USER_LIST_REDIRECT_PATH;
  path: string;
}

export type IUserActionTypes = IFetchSingleUserStartAction | IFetchSingleUserSuccessAction | IFetchSingleUserFailAction
  | IFetchUsersStartAction | IFetchUsersSuccessAction | IFetchUsersFailAction | IClearSingleUserAction
  | IDeleteSingleUserStartAction | IDeleteSingleUserSuccessAction | IDeleteSingleUserFailAction | ICreateSingleUserStartAction
  | ICreateSingleUserSuccessAction | ICreateSingleUserFailAction | IUpdateSingleUserStartAction | IUpdateSingleUserSuccessAction
  | IUpdateSingleUserFailAction | ISetUserListRedirectPath;
