import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {logout, setLoginRedirectPath} from '../../../store/login';

interface ILogout {
  onLogout(): any;
  onSetLoginRedirectPath(path: string): any;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(logout()),
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
  };
};

class Logout extends React.Component<ILogout> {
  componentDidMount () {
    const { onLogout, onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath('/');
    onLogout();
  }

  render () {
    return <Redirect to="/login"/>;
  }
}

export default connect(null, mapDispatchToProps)(Logout);
