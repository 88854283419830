import { Dispatch } from 'redux';
import * as api from '../../services/api';

import { ILoginActionTypes, LOGIN_FAIL, LOGIN_START, LOGIN_SUCCESS, LOGOUT, SET_LOGIN_REDIRECT_PATH } from './types';

export const loginStart = (): ILoginActionTypes => {
    return {
      type: LOGIN_START
    };
};

export const loginSuccess = (token: string, userId: any, role: string, tokenExpirationDate: string): ILoginActionTypes => {
    return {
      type: LOGIN_SUCCESS,
      token,
      userId,
      role,
      tokenExpirationDate
    };
};

export const loginFail = (error: Error): ILoginActionTypes => {
  return {
    type: LOGIN_FAIL,
    error,
  };
};

export const logout = (): ILoginActionTypes => {
  return {
    type: LOGOUT
  };
};

export const setLoginRedirectPath = (path: string): ILoginActionTypes => {
  return {
    type: SET_LOGIN_REDIRECT_PATH,
    path
  };
};

export const checkLoginTimeout = (expirationDate: Date) => {
  return (dispatch: Dispatch) => {
    if (expirationDate <= new Date()) {
      dispatch(logout());
    }
  };
};

export const login = (email: string, password: string) => {
  return (dispatch: any) => {
    dispatch(loginStart());
    api.loginToAPI(email, password)
      .then(response => {
        const expirationDate = new Date(response.data.user.expiresIn * 1000);
        dispatch(loginSuccess(response.data.user.token, response.data.user.id, response.data.user.role, expirationDate.toString()));
      })
      .catch(error => {
        dispatch(loginFail(error));
      });
  }
};
