interface ITestReportUpdateData {
  accountId?: boolean;
  reviewerProfileId?: boolean;
  reviewedProfileId?: boolean;
  project?: string;
  projectKey?: string;
  formType?: string;
  issueId?: string;
  environmentId?: string;
  deviceId?: string;
  browserId?: string;
  version?: string;
  build?: string;
  os?: string;
  regression?: boolean;
  applications?: string;
  url?: string;
  returned?: boolean;
  status?: boolean;
  notes?: string;
}

export const identifyTestReportChanges = (prevTestReport: any, newTestReport: any) => {
  const changes: ITestReportUpdateData = {};
  if (newTestReport.accountId && prevTestReport.account.id !== newTestReport.accountId) {
    changes.accountId = newTestReport.accountId;
  }
  if (newTestReport.reviewerProfileId && prevTestReport.reviewerProfile.id !== newTestReport.reviewerProfileId) {
    changes.reviewerProfileId = newTestReport.reviewerProfileId;
  }
  if (newTestReport.reviewedProfileId && prevTestReport.reviewedProfile.id !== newTestReport.reviewedProfileId) {
    changes.reviewedProfileId = newTestReport.reviewedProfileId;
  }
  if (newTestReport.project && prevTestReport.project !== newTestReport.project) {
    changes.project = newTestReport.project;
  }
  if (newTestReport.projectKey && prevTestReport.projectKey !== newTestReport.projectKey) {
    changes.projectKey = newTestReport.projectKey;
  }
  if (prevTestReport.formType !== newTestReport.formType) {
    changes.formType = newTestReport.formType;
  }
  if (newTestReport.issueId && prevTestReport.issueId !== newTestReport.issueId) {
    changes.issueId = newTestReport.issueId;
  }
  if (prevTestReport.environmentId !== newTestReport.environmentId) {
    changes.environmentId = newTestReport.environmentId;
  }
  if (prevTestReport.deviceId !== newTestReport.deviceId) {
    changes.deviceId = newTestReport.deviceId;
  }
  if (prevTestReport.browserId !== newTestReport.browserId) {
    changes.browserId = newTestReport.browserId;
  }
  if (prevTestReport.version !== newTestReport.version) {
    changes.version = newTestReport.version;
  }
  if (prevTestReport.build !== newTestReport.build) {
    changes.build = newTestReport.build;
  }
  if (prevTestReport.os !== newTestReport.os) {
    changes.os = newTestReport.os;
  }
  if (prevTestReport.regression !== newTestReport.regression) {
    changes.regression = newTestReport.regression;
  }
  if (prevTestReport.applications !== newTestReport.applications) {
    changes.applications = newTestReport.applications;
  }
  if (prevTestReport.url !== newTestReport.url) {
    changes.url = newTestReport.url;
  }
  if (prevTestReport.returned !== newTestReport.returned) {
    changes.returned = newTestReport.returned;
  }
  if (prevTestReport.status !== newTestReport.status) {
    changes.status = newTestReport.status;
  }
  if (newTestReport.notes && prevTestReport.notes !== newTestReport.notes) {
    changes.notes = newTestReport.notes;
  }
  return changes;
}