export const FETCH_SINGLE_TEST_REPORT_START = 'FETCH_SINGLE_TEST_REPORT_START';
export const FETCH_SINGLE_TEST_REPORT_SUCCESS = 'FETCH_SINGLE_TEST_REPORT_SUCCESS';
export const FETCH_SINGLE_TEST_REPORT_FAIL = 'FETCH_SINGLE_TEST_REPORT_FAIL';
export const CLEAR_SINGLE_TEST_REPORT = 'CLEAR_SINGLE_TEST_REPORT';

export const FETCH_TEST_REPORTS_START = 'FETCH_TEST_REPORTS_START';
export const FETCH_TEST_REPORTS_SUCCESS = 'FETCH_TEST_REPORTS_SUCCESS';
export const FETCH_TEST_REPORTS_FAIL = 'FETCH_TEST_REPORTS_FAIL';

export const CREATE_SINGLE_TEST_REPORT_START = 'CREATE_SINGLE_TEST_REPORT_START';
export const CREATE_SINGLE_TEST_REPORT_SUCCESS = 'CREATE_SINGLE_TEST_REPORT_SUCCESS';
export const CREATE_SINGLE_TEST_REPORT_FAIL = 'CREATE_SINGLE_TEST_REPORT_FAIL';

export const UPDATE_SINGLE_TEST_REPORT_START = 'UPDATE_SINGLE_TEST_REPORT_START';
export const UPDATE_SINGLE_TEST_REPORT_SUCCESS = 'UPDATE_SINGLE_TEST_REPORT_SUCCESS';
export const UPDATE_SINGLE_TEST_REPORT_FAIL = 'UPDATE_SINGLE_TEST_REPORT_FAIL';

export const DELETE_SINGLE_TEST_REPORT_START = 'DELETE_SINGLE_TEST_REPORT_START';
export const DELETE_SINGLE_TEST_REPORT_SUCCESS = 'DELETE_SINGLE_TEST_REPORT_SUCCESS';
export const DELETE_SINGLE_TEST_REPORT_FAIL = 'DELETE_SINGLE_TEST_REPORT_FAIL';

export const ARCHIVE_SINGLE_TEST_REPORT_START = 'ARCHIVE_SINGLE_TEST_REPORT_START';
export const ARCHIVE_SINGLE_TEST_REPORT_SUCCESS = 'ARCHIVE_SINGLE_TEST_REPORT_SUCCESS';
export const ARCHIVE_SINGLE_TEST_REPORT_FAIL = 'ARCHIVE_SINGLE_TEST_REPORT_FAIL';

export const UNARCHIVE_SINGLE_TEST_REPORT_START = 'UNARCHIVE_SINGLE_TEST_REPORT_START';
export const UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS = 'UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS';
export const UNARCHIVE_SINGLE_TEST_REPORT_FAIL = 'UNARCHIVE_SINGLE_TEST_REPORT_FAIL';

export const SET_TEST_REPORT_LIST_REDIRECT_PATH = 'SET_TEST_REPORT_LIST_REDIRECT_PATH';

export interface ITestReportState {
  currentTestReport: {} | null;
  testReportsList: [],
  testReportListRedirectPath: string | null,
  testReportsPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleTestReportStartAction {
  type: typeof FETCH_SINGLE_TEST_REPORT_START;
}

export interface IFetchSingleTestReportSuccessAction {
  type: typeof FETCH_SINGLE_TEST_REPORT_SUCCESS;
  currentTestReport: {};
}

export interface IFetchSingleTestReportFailAction {
  type: typeof FETCH_SINGLE_TEST_REPORT_FAIL;
  error: object;
}

export interface ICreateSingleTestReportStartAction {
  type: typeof CREATE_SINGLE_TEST_REPORT_START;
}

export interface ICreateSingleTestReportSuccessAction {
  type: typeof CREATE_SINGLE_TEST_REPORT_SUCCESS;
  currentTestReport: {};
}

export interface ICreateSingleTestReportFailAction {
  type: typeof CREATE_SINGLE_TEST_REPORT_FAIL;
  error: object;
}

export interface IUpdateSingleTestReportStartAction {
  type: typeof UPDATE_SINGLE_TEST_REPORT_START;
}

export interface IUpdateSingleTestReportSuccessAction {
  type: typeof UPDATE_SINGLE_TEST_REPORT_SUCCESS;
  currentTestReport: {};
}

export interface IUpdateSingleTestReportFailAction {
  type: typeof UPDATE_SINGLE_TEST_REPORT_FAIL;
  error: object;
}

export interface IDeleteSingleTestReportStartAction {
  type: typeof DELETE_SINGLE_TEST_REPORT_START;
}

export interface IDeleteSingleTestReportSuccessAction {
  type: typeof DELETE_SINGLE_TEST_REPORT_SUCCESS;
}

export interface IDeleteSingleTestReportFailAction {
  type: typeof DELETE_SINGLE_TEST_REPORT_FAIL;
  error: object;
}

export interface IClearSingleTestReportAction {
  type: typeof CLEAR_SINGLE_TEST_REPORT;
}

export interface IFetchTestReportsStartAction {
  type: typeof FETCH_TEST_REPORTS_START;
}

export interface IFetchTestReportsSuccessAction {
  type: typeof FETCH_TEST_REPORTS_SUCCESS;
  testReportsList: [];
  testReportsPages: number,
  index: number;
}

export interface IFetchTestReportsFailAction {
  type: typeof FETCH_TEST_REPORTS_FAIL;
  error: object;
}

export interface ISetTestReportListRedirectPath {
  type: typeof SET_TEST_REPORT_LIST_REDIRECT_PATH;
  path: string;
}

export interface IArchiveSingleTestReportStartAction {
  type: typeof ARCHIVE_SINGLE_TEST_REPORT_START;
}

export interface IArchiveSingleTestReportSuccessAction {
  type: typeof ARCHIVE_SINGLE_TEST_REPORT_SUCCESS;
  currentTestReport: {};
}

export interface IArchiveSingleTestReportFailAction {
  type: typeof ARCHIVE_SINGLE_TEST_REPORT_FAIL;
  error: object;
}

export interface IUnarchiveSingleTestReportStartAction {
  type: typeof UNARCHIVE_SINGLE_TEST_REPORT_START;
}

export interface IUnarchiveSingleTestReportSuccessAction {
  type: typeof UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS;
  currentTestReport: {};
}

export interface IUnarchiveSingleTestReportFailAction {
  type: typeof UNARCHIVE_SINGLE_TEST_REPORT_FAIL;
  error: object;
}

export type ITestReportActionTypes =
  | IFetchSingleTestReportStartAction
  | IFetchSingleTestReportSuccessAction
  | IFetchSingleTestReportFailAction
  | IFetchTestReportsStartAction
  | IFetchTestReportsSuccessAction
  | IFetchTestReportsFailAction
  | IClearSingleTestReportAction
  | IDeleteSingleTestReportStartAction
  | IDeleteSingleTestReportSuccessAction
  | IDeleteSingleTestReportFailAction
  | ICreateSingleTestReportStartAction
  | ICreateSingleTestReportSuccessAction
  | ICreateSingleTestReportFailAction
  | IUpdateSingleTestReportStartAction
  | IUpdateSingleTestReportSuccessAction
  | IUpdateSingleTestReportFailAction
  | ISetTestReportListRedirectPath
  | IArchiveSingleTestReportStartAction
  | IArchiveSingleTestReportSuccessAction
  | IArchiveSingleTestReportFailAction
  | IUnarchiveSingleTestReportStartAction
  | IUnarchiveSingleTestReportSuccessAction
  | IUnarchiveSingleTestReportFailAction;
