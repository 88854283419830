import {
  CLEAR_SINGLE_DEFECT,
  CREATE_SINGLE_DEFECT_FAIL,
  CREATE_SINGLE_DEFECT_START,
  CREATE_SINGLE_DEFECT_SUCCESS,
  DELETE_SINGLE_DEFECT_FAIL,
  DELETE_SINGLE_DEFECT_START,
  DELETE_SINGLE_DEFECT_SUCCESS,
  FETCH_DEFECTS_FAIL,
  FETCH_DEFECTS_START,
  FETCH_DEFECTS_SUCCESS,
  FETCH_SINGLE_DEFECT_FAIL,
  FETCH_SINGLE_DEFECT_START,
  FETCH_SINGLE_DEFECT_SUCCESS,
  IClearSingleDefectAction,
  ICreateSingleDefectFailAction,
  ICreateSingleDefectStartAction,
  ICreateSingleDefectSuccessAction,
  IDefectActionTypes,
  IDefectState,
  IDeleteSingleDefectFailAction,
  IDeleteSingleDefectStartAction,
  IDeleteSingleDefectSuccessAction,
  IFetchDefectsFailAction,
  IFetchDefectsStartAction,
  IFetchDefectsSuccessAction,
  IFetchSingleDefectFailAction,
  IFetchSingleDefectStartAction,
  IFetchSingleDefectSuccessAction,
  ISetDefectListRedirectPath,
  IUpdateSingleDefectFailAction,
  IUpdateSingleDefectStartAction,
  IUpdateSingleDefectSuccessAction,
  SET_DEFECT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_DEFECT_FAIL,
  UPDATE_SINGLE_DEFECT_START,
  UPDATE_SINGLE_DEFECT_SUCCESS,
} from './types';

const initialState: IDefectState = {
  currentDefect: null,
  defectsList: [],
  defectsPages: 0,
  index: 0,
  defectListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleDefectStart = (state: IDefectState, action: IFetchSingleDefectStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleDefectSuccess = (state: IDefectState, action: IFetchSingleDefectSuccessAction) => {
  return {
    ...state,
    currentDefect: action.currentDefect,
    error: null,
    loading: false
  };
};

const fetchSingleDefectFail = (state: IDefectState, action: IFetchSingleDefectFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleDefect = (state: IDefectState, action: IClearSingleDefectAction) => {
  return {
    ...state,
    currentDefect: null
  };
};

const createSingleDefectStart = (state: IDefectState, action: ICreateSingleDefectStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleDefectSuccess = (state: IDefectState, action: ICreateSingleDefectSuccessAction) => {
  return {
    ...state,
    currentDefect: action.currentDefect,
    error: null,
    loading: false
  };
};

const createSingleDefectFail = (state: IDefectState, action: ICreateSingleDefectFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleDefectStart = (state: IDefectState, action: IUpdateSingleDefectStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleDefectSuccess = (state: IDefectState, action: IUpdateSingleDefectSuccessAction) => {
  return {
    ...state,
    currentDefect: action.currentDefect,
    error: null,
    loading: false
  };
};

const updateSingleDefectFail = (state: IDefectState, action: IUpdateSingleDefectFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleDefectStart = (state: IDefectState, action: IDeleteSingleDefectStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleDefectSuccess = (state: IDefectState, action: IDeleteSingleDefectSuccessAction) => {
  return {
    ...state,
    currentDefect: null,
    error: null,
    loading: false
  };
};

const deleteSingleDefectFail = (state: IDefectState, action: IDeleteSingleDefectFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchDefectsStart = (state: IDefectState, action: IFetchDefectsStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchDefectsSuccess = (state: IDefectState, action: IFetchDefectsSuccessAction) => {
  return {
    ...state,
    defectsList: action.defectsList,
    error: null,
    loading: false,
    defectsPages: action.defectsPages,
    index: action.index,
  };
};

const fetchDefectsFail = (state: IDefectState, action: IFetchDefectsFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setDefectListRedirectPath = (state: IDefectState, action: ISetDefectListRedirectPath) => {
  return {
    ...state,
    defectListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IDefectActionTypes): IDefectState => {
  switch ( action.type ) {
    case FETCH_SINGLE_DEFECT_START: return fetchSingleDefectStart(state, action);
    case FETCH_SINGLE_DEFECT_SUCCESS: return fetchSingleDefectSuccess(state, action);
    case FETCH_SINGLE_DEFECT_FAIL: return fetchSingleDefectFail(state, action);
    case CREATE_SINGLE_DEFECT_START: return createSingleDefectStart(state, action);
    case CREATE_SINGLE_DEFECT_SUCCESS: return createSingleDefectSuccess(state, action);
    case CREATE_SINGLE_DEFECT_FAIL: return createSingleDefectFail(state, action);
    case UPDATE_SINGLE_DEFECT_START: return updateSingleDefectStart(state, action);
    case UPDATE_SINGLE_DEFECT_SUCCESS: return updateSingleDefectSuccess(state, action);
    case UPDATE_SINGLE_DEFECT_FAIL: return updateSingleDefectFail(state, action);
    case DELETE_SINGLE_DEFECT_START: return deleteSingleDefectStart(state, action);
    case DELETE_SINGLE_DEFECT_SUCCESS: return deleteSingleDefectSuccess(state, action);
    case DELETE_SINGLE_DEFECT_FAIL: return deleteSingleDefectFail(state, action);
    case CLEAR_SINGLE_DEFECT: return clearSingleDefect(state, action);
    case FETCH_DEFECTS_START: return fetchDefectsStart(state, action);
    case FETCH_DEFECTS_SUCCESS: return fetchDefectsSuccess(state, action);
    case FETCH_DEFECTS_FAIL: return fetchDefectsFail(state, action);
    case SET_DEFECT_LIST_REDIRECT_PATH: return setDefectListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
