export const FETCH_SINGLE_DEFECT_START = 'FETCH_SINGLE_DEFECT_START';
export const FETCH_SINGLE_DEFECT_SUCCESS = 'FETCH_SINGLE_DEFECT_SUCCESS';
export const FETCH_SINGLE_DEFECT_FAIL = 'FETCH_SINGLE_DEFECT_FAIL';
export const CLEAR_SINGLE_DEFECT = 'CLEAR_SINGLE_DEFECT';

export const FETCH_DEFECTS_START = 'FETCH_DEFECTS_START';
export const FETCH_DEFECTS_SUCCESS = 'FETCH_DEFECTS_SUCCESS';
export const FETCH_DEFECTS_FAIL = 'FETCH_DEFECTS_FAIL';

export const CREATE_SINGLE_DEFECT_START = 'CREATE_SINGLE_DEFECT_START';
export const CREATE_SINGLE_DEFECT_SUCCESS = 'CREATE_SINGLE_DEFECT_SUCCESS';
export const CREATE_SINGLE_DEFECT_FAIL = 'CREATE_SINGLE_DEFECT_FAIL';

export const UPDATE_SINGLE_DEFECT_START = 'UPDATE_SINGLE_DEFECT_START';
export const UPDATE_SINGLE_DEFECT_SUCCESS = 'UPDATE_SINGLE_DEFECT_SUCCESS';
export const UPDATE_SINGLE_DEFECT_FAIL = 'UPDATE_SINGLE_DEFECT_FAIL';

export const DELETE_SINGLE_DEFECT_START = 'DELETE_SINGLE_DEFECT_START';
export const DELETE_SINGLE_DEFECT_SUCCESS = 'DELETE_SINGLE_DEFECT_SUCCESS';
export const DELETE_SINGLE_DEFECT_FAIL = 'DELETE_SINGLE_DEFECT_FAIL';

export const SET_DEFECT_LIST_REDIRECT_PATH = 'SET_DEFECT_LIST_REDIRECT_PATH';

export interface IDefectState {
  currentDefect: {} | null;
  defectsList: [],
  defectListRedirectPath: string | null,
  defectsPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleDefectStartAction {
  type: typeof FETCH_SINGLE_DEFECT_START;
}

export interface IFetchSingleDefectSuccessAction {
  type: typeof FETCH_SINGLE_DEFECT_SUCCESS;
  currentDefect: {};
}

export interface IFetchSingleDefectFailAction {
  type: typeof FETCH_SINGLE_DEFECT_FAIL;
  error: object;
}

export interface ICreateSingleDefectStartAction {
  type: typeof CREATE_SINGLE_DEFECT_START;
}

export interface ICreateSingleDefectSuccessAction {
  type: typeof CREATE_SINGLE_DEFECT_SUCCESS;
  currentDefect: {};
}

export interface ICreateSingleDefectFailAction {
  type: typeof CREATE_SINGLE_DEFECT_FAIL;
  error: object;
}

export interface IUpdateSingleDefectStartAction {
  type: typeof UPDATE_SINGLE_DEFECT_START;
}

export interface IUpdateSingleDefectSuccessAction {
  type: typeof UPDATE_SINGLE_DEFECT_SUCCESS;
  currentDefect: {};
}

export interface IUpdateSingleDefectFailAction {
  type: typeof UPDATE_SINGLE_DEFECT_FAIL;
  error: object;
}

export interface IDeleteSingleDefectStartAction {
  type: typeof DELETE_SINGLE_DEFECT_START;
}

export interface IDeleteSingleDefectSuccessAction {
  type: typeof DELETE_SINGLE_DEFECT_SUCCESS;
}

export interface IDeleteSingleDefectFailAction {
  type: typeof DELETE_SINGLE_DEFECT_FAIL;
  error: object;
}

export interface IClearSingleDefectAction {
  type: typeof CLEAR_SINGLE_DEFECT;
}

export interface IFetchDefectsStartAction {
  type: typeof FETCH_DEFECTS_START;
}

export interface IFetchDefectsSuccessAction {
  type: typeof FETCH_DEFECTS_SUCCESS;
  defectsList: [];
  defectsPages: number,
  index: number;
}

export interface IFetchDefectsFailAction {
  type: typeof FETCH_DEFECTS_FAIL;
  error: object;
}

export interface ISetDefectListRedirectPath {
  type: typeof SET_DEFECT_LIST_REDIRECT_PATH;
  path: string;
}

export type IDefectActionTypes = IFetchSingleDefectStartAction | IFetchSingleDefectSuccessAction | IFetchSingleDefectFailAction
  | IFetchDefectsStartAction | IFetchDefectsSuccessAction | IFetchDefectsFailAction | IClearSingleDefectAction
  | IDeleteSingleDefectStartAction | IDeleteSingleDefectSuccessAction | IDeleteSingleDefectFailAction | ICreateSingleDefectStartAction
  | ICreateSingleDefectSuccessAction | ICreateSingleDefectFailAction | IUpdateSingleDefectStartAction | IUpdateSingleDefectSuccessAction
  | IUpdateSingleDefectFailAction | ISetDefectListRedirectPath;
