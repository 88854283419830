import {
  CLEAR_SINGLE_TEST_REPORT,
  CREATE_SINGLE_TEST_REPORT_FAIL,
  CREATE_SINGLE_TEST_REPORT_START,
  CREATE_SINGLE_TEST_REPORT_SUCCESS,
  DELETE_SINGLE_TEST_REPORT_FAIL,
  DELETE_SINGLE_TEST_REPORT_START,
  DELETE_SINGLE_TEST_REPORT_SUCCESS,
  FETCH_SINGLE_TEST_REPORT_FAIL,
  FETCH_SINGLE_TEST_REPORT_START,
  FETCH_SINGLE_TEST_REPORT_SUCCESS,
  FETCH_TEST_REPORTS_FAIL,
  FETCH_TEST_REPORTS_START,
  FETCH_TEST_REPORTS_SUCCESS,
  IClearSingleTestReportAction,
  ICreateSingleTestReportFailAction,
  ICreateSingleTestReportStartAction,
  ICreateSingleTestReportSuccessAction,
  IDeleteSingleTestReportFailAction,
  IDeleteSingleTestReportStartAction,
  IDeleteSingleTestReportSuccessAction,
  IFetchSingleTestReportFailAction,
  IFetchSingleTestReportStartAction,
  IFetchSingleTestReportSuccessAction,
  IFetchTestReportsFailAction,
  IFetchTestReportsStartAction,
  IFetchTestReportsSuccessAction,
  ISetTestReportListRedirectPath,
  ITestReportActionTypes,
  ITestReportState,
  IUpdateSingleTestReportFailAction,
  IUpdateSingleTestReportStartAction,
  IUpdateSingleTestReportSuccessAction,
  SET_TEST_REPORT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_TEST_REPORT_FAIL,
  UPDATE_SINGLE_TEST_REPORT_START,
  UPDATE_SINGLE_TEST_REPORT_SUCCESS,
  ARCHIVE_SINGLE_TEST_REPORT_START,
  ARCHIVE_SINGLE_TEST_REPORT_SUCCESS,
  ARCHIVE_SINGLE_TEST_REPORT_FAIL,
  IArchiveSingleTestReportStartAction,
  IArchiveSingleTestReportSuccessAction,
  IArchiveSingleTestReportFailAction,
  UNARCHIVE_SINGLE_TEST_REPORT_START,
  UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS,
  UNARCHIVE_SINGLE_TEST_REPORT_FAIL,
  IUnarchiveSingleTestReportStartAction,
  IUnarchiveSingleTestReportSuccessAction,
  IUnarchiveSingleTestReportFailAction
} from './types';

const initialState: ITestReportState = {
  currentTestReport: null,
  testReportsList: [],
  testReportsPages: 0,
  index: 0,
  testReportListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleTestReportStart = (state: ITestReportState, action: IFetchSingleTestReportStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleTestReportSuccess = (state: ITestReportState, action: IFetchSingleTestReportSuccessAction) => {
  return {
    ...state,
    currentTestReport: action.currentTestReport,
    error: null,
    loading: false
  };
};

const fetchSingleTestReportFail = (state: ITestReportState, action: IFetchSingleTestReportFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleTestReport = (state: ITestReportState, action: IClearSingleTestReportAction) => {
  return {
    ...state,
    currentTestReport: null
  };
};

const createSingleTestReportStart = (state: ITestReportState, action: ICreateSingleTestReportStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleTestReportSuccess = (state: ITestReportState, action: ICreateSingleTestReportSuccessAction) => {
  return {
    ...state,
    currentTestReport: action.currentTestReport,
    error: null,
    loading: false
  };
};

const createSingleTestReportFail = (state: ITestReportState, action: ICreateSingleTestReportFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleTestReportStart = (state: ITestReportState, action: IUpdateSingleTestReportStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleTestReportSuccess = (state: ITestReportState, action: IUpdateSingleTestReportSuccessAction) => {
  return {
    ...state,
    currentTestReport: action.currentTestReport,
    error: null,
    loading: false
  };
};

const updateSingleTestReportFail = (state: ITestReportState, action: IUpdateSingleTestReportFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleTestReportStart = (state: ITestReportState, action: IDeleteSingleTestReportStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleTestReportSuccess = (state: ITestReportState, action: IDeleteSingleTestReportSuccessAction) => {
  return {
    ...state,
    currentTestReport: null,
    error: null,
    loading: false
  };
};

const deleteSingleTestReportFail = (state: ITestReportState, action: IDeleteSingleTestReportFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchTestReportsStart = (state: ITestReportState, action: IFetchTestReportsStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchTestReportsSuccess = (state: ITestReportState, action: IFetchTestReportsSuccessAction) => {
  return {
    ...state,
    testReportsList: action.testReportsList,
    error: null,
    loading: false,
    testReportsPages: action.testReportsPages,
    index: action.index,
  };
};

const fetchTestReportsFail = (state: ITestReportState, action: IFetchTestReportsFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setTestReportListRedirectPath = (state: ITestReportState, action: ISetTestReportListRedirectPath) => {
  return {
    ...state,
    testReportListRedirectPath: action.path
  };
};

const archiveSingleTestReportStart = (state: ITestReportState, action: IArchiveSingleTestReportStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const archiveSingleTestReportSuccess = (state: ITestReportState, action: IArchiveSingleTestReportSuccessAction) => {
  return {
    ...state,
    currentTestReport: action.currentTestReport,
    error: null,
    loading: false
  };
};

const archiveSingleTestReportFail = (state: ITestReportState, action: IArchiveSingleTestReportFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const unarchiveSingleTestReportStart = (state: ITestReportState, action: IUnarchiveSingleTestReportStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const unarchiveSingleTestReportSuccess = (state: ITestReportState, action: IUnarchiveSingleTestReportSuccessAction) => {
  return {
    ...state,
    currentTestReport: action.currentTestReport,
    error: null,
    loading: false
  };
};

const unarchiveSingleTestReportFail = (state: ITestReportState, action: IUnarchiveSingleTestReportFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const reducer = (state = initialState, action: ITestReportActionTypes): ITestReportState => {
  switch ( action.type ) {
    case FETCH_SINGLE_TEST_REPORT_START: return fetchSingleTestReportStart(state, action);
    case FETCH_SINGLE_TEST_REPORT_SUCCESS: return fetchSingleTestReportSuccess(state, action);
    case FETCH_SINGLE_TEST_REPORT_FAIL: return fetchSingleTestReportFail(state, action);
    case CREATE_SINGLE_TEST_REPORT_START: return createSingleTestReportStart(state, action);
    case CREATE_SINGLE_TEST_REPORT_SUCCESS: return createSingleTestReportSuccess(state, action);
    case CREATE_SINGLE_TEST_REPORT_FAIL: return createSingleTestReportFail(state, action);
    case UPDATE_SINGLE_TEST_REPORT_START: return updateSingleTestReportStart(state, action);
    case UPDATE_SINGLE_TEST_REPORT_SUCCESS: return updateSingleTestReportSuccess(state, action);
    case UPDATE_SINGLE_TEST_REPORT_FAIL: return updateSingleTestReportFail(state, action);
    case DELETE_SINGLE_TEST_REPORT_START: return deleteSingleTestReportStart(state, action);
    case DELETE_SINGLE_TEST_REPORT_SUCCESS: return deleteSingleTestReportSuccess(state, action);
    case DELETE_SINGLE_TEST_REPORT_FAIL: return deleteSingleTestReportFail(state, action);
    case CLEAR_SINGLE_TEST_REPORT: return clearSingleTestReport(state, action);
    case FETCH_TEST_REPORTS_START: return fetchTestReportsStart(state, action);
    case FETCH_TEST_REPORTS_SUCCESS: return fetchTestReportsSuccess(state, action);
    case FETCH_TEST_REPORTS_FAIL: return fetchTestReportsFail(state, action);
    case SET_TEST_REPORT_LIST_REDIRECT_PATH: return setTestReportListRedirectPath(state,action);
    case ARCHIVE_SINGLE_TEST_REPORT_START: return archiveSingleTestReportStart(state, action);
    case ARCHIVE_SINGLE_TEST_REPORT_SUCCESS: return archiveSingleTestReportSuccess(state, action);
    case ARCHIVE_SINGLE_TEST_REPORT_FAIL: return archiveSingleTestReportFail(state, action);
    case UNARCHIVE_SINGLE_TEST_REPORT_START: return unarchiveSingleTestReportStart(state, action);
    case UNARCHIVE_SINGLE_TEST_REPORT_SUCCESS: return unarchiveSingleTestReportSuccess(state, action);
    case UNARCHIVE_SINGLE_TEST_REPORT_FAIL: return unarchiveSingleTestReportFail(state, action);
    default:
      return state;
  }
};

export default reducer;
