
export const formatAccountActive = {
  'true': 'Yes',
  'false': 'No'
};

export type IAccountActive = typeof formatAccountActive

export const searchByOptions = [
  {
    label: 'ID',
    value: 'id'
  },
  {
    label: 'Description',
    value: 'description'
  },
  {
    label: 'Domain Name',
    value: 'domain_name'
  },
];
