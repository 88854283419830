import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Input from '../Input/Input';
import IconButton from '../IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { inputValidator } from '../../../utils/inputValidator';
import styles from './Search.module.css';

interface ISearchProps {
  loading: boolean;
  location: any;
  history: any;
  searchByOptions: any[];
};

const initialState = {
  formControls: {
    description: {
      value: '',
      validation: {
        maxLength: 255,
      },
      valid: false,
      touched: false
    },
  },
  formIsValid: false,
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    loading: loadingAppSelector(state),
  };
}
class Search extends React.Component<ISearchProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { location } = this.props;
    const queries = queryString.parse(location.search);
    const { description, } = this.state.formControls;
    if (queries.search) {
      this.setState({
        ...this.state,
        formControls: {
          ...this.state.formControls,
          description: {
            ...description,
            value: queries.search,
          },
        },
        formIsValid: true,
      });
    }
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { value } = isSelect? event : event.target;
    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value,
      valid: inputValidator(value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement>, inputIdentifier: FormControlKey) => {
    // Gets formControls from current state.
    const { formControls } = this.state;

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls });
  };


  searchSubmitHandler = async (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    const { description } = this.state.formControls;
    const { history, location} = this.props;
    const qStr = queryString.parse(location.search);
    qStr.search = description.value;
    qStr.page = "1";
    history.push(`?${queryString.stringify(qStr)}`);
  };

  clearSubmitHandler = async (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    const { description, } = this.state.formControls;
    this.setState({
      ...this.state,
      formControls: {
        ...this.state.formControls,
        description: {
          ...description,
          value: '',
          valid: false,
        touched: false
        },
      },
      formIsValid: false,
    });
    this.defaultRedirection();
  };

  defaultRedirection = () => {
    const { history, location} = this.props;
    const qStr = queryString.parse(location.search);
    qStr.search = undefined;
    qStr.searchBy = undefined;
    qStr.page = "1";
    history.push(`?${queryString.stringify(qStr)}`);
  };

  render() {
    const { loading } = this.props;
    const { description, } = this.state.formControls;
    const { formIsValid } = this.state;
    const disabled = !formIsValid || loading;
    return (
      <>
        <div className="form-row" style={{ width: '60%', }}>
          <div className={styles.SearchInputContainer}>
              <Input
                elementType="input"
                elementConfig={{type: 'text', placeholder: 'Type to search', autoFocus: true, }}
                value={description.value}
                shouldValidate={false}
                noLabel
                valid={description.valid}
                touched={description.touched}
                disabled={loading}
                onEnterKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => (event.which === 13) && !disabled? this.searchSubmitHandler(event) : null}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'description', false)}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'description')}
              />
          </div>
          <div className={`${styles.SearchButton}`}>
              <IconButton type="search" color="default" disabled={disabled} onClick={(event: React.MouseEvent<HTMLElement>) => this.searchSubmitHandler(event)} />
              <IconButton type="clear" color="default" onClick={(event: React.MouseEvent<HTMLElement>) => this.clearSubmitHandler(event)} />
          </div>
        </div>
      </>
    );
};
}

export default connect(matchStateToProps, null)(Search);

