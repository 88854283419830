interface IAccountUpdateData {
  description?: string;
  domainName?: string;
  active?: boolean;

}

export const identifyAccountChanges = (prevAccount: any, newAccount: any) => {
  const changes: IAccountUpdateData = {};
  if (newAccount.description && (prevAccount.description !== newAccount.description)) {
    changes.description = newAccount.description;
  }
  if (newAccount.domainName && prevAccount.domainName !== newAccount.domainName) {
    changes.domainName = newAccount.domainName;
  }
  if (prevAccount.active !== newAccount.active) {
    changes.active = newAccount.active;
  }

  return changes;
}