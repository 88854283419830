import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleBrowser, deleteBrowserById, getBrowserById } from '../../../store/browsers';
import { formatBrowserActive, IBrowserActive } from '../../../utils/browsers/Formatters';
import styles from './BrowserDetails.module.css';
import * as _ from 'lodash';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IBrowserDetailsProps {
  token: string;
  match: any;
  browser: any;
  index: number;
  location: any;
  history: any;
  loading: boolean;
  browserListRedirectPath: string;
  onGetBrowserById(id: string, token: string): any;
  onClearSingleBrowser(): any;
  onDeleteBrowserById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    browser: state.browsers.currentBrowser,
    index: state.browsers.index,
    browserListRedirectPath: state.browsers.browserListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetBrowserById: (id: string, token: string) => dispatch(getBrowserById(id, token)),
    onClearSingleBrowser: () => dispatch(clearSingleBrowser()),
    onDeleteBrowserById: (id: string, token: string) => dispatch(deleteBrowserById(id, token)),
  }
};
const formatOptionalField = (field: any) => !(_.isNull(field) || _.isUndefined(field))? field : '-'; 

const formatBrowserDetails = (browser: any) => {
  return {
    ID: browser.id,
    Description: formatOptionalField(browser.description),
    'Active': formatOptionalField(formatBrowserActive[browser.active as keyof IBrowserActive]),
  }
};

class BrowserDetails extends React.Component<IBrowserDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetBrowserById } = this.props;
    onGetBrowserById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetBrowserById } = this.props;
      onGetBrowserById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleBrowser } = this.props;
    onClearSingleBrowser();
  }

  onEditButtonClickHandler = () => {
    const { history, browser } = this.props;
    history.push(`/browsers/${browser.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteBrowserById, browser, token, history } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteBrowserById(browser.id, token);
    history.push('/');
  };

  render() {
    const { browser, match, loading, index, browserListRedirectPath } = this.props;
    const { showModal } = this.state;
    const browserDetails = browser? <DescriptionList entity={formatBrowserDetails(browser)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const browserListURL = browserListRedirectPath? browserListRedirectPath : `/browsers?&page=${index? index : 1}`;   

    return (
      <div className={styles.BrowserDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={browserListURL} exact>
              Browsers
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={browserListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.BrowserDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {browserDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this browser?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(BrowserDetails);
