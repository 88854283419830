import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleAccount, deleteAccountById, getAccountById } from '../../../store/accounts';
import { formatAccountActive, IAccountActive } from '../../../utils/accounts/Formatters';
import styles from './AccountDetails.module.css';
import * as _ from 'lodash';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IAccountDetailsProps {
  token: string;
  match: any;
  account: any;
  index: number;
  location: any;
  history: any;
  loading: boolean;
  accountListRedirectPath: string;
  onGetAccountById(id: string, token: string): any;
  onClearSingleAccount(): any;
  onDeleteAccountById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    account: state.accounts.currentAccount,
    index: state.accounts.index,
    accountListRedirectPath: state.accounts.accountListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetAccountById: (id: string, token: string) => dispatch(getAccountById(id, token)),
    onClearSingleAccount: () => dispatch(clearSingleAccount()),
    onDeleteAccountById: (id: string, token: string) => dispatch(deleteAccountById(id, token)),
  }
};
const formatOptionalField = (field: any) => !(_.isNull(field) || _.isUndefined(field))? field : '-'; 

const formatAccountDetails = (account: any) => {
  return {
    ID: account.id,
    Description: formatOptionalField(account.description),
    'Domain': formatOptionalField(account.domainName),
    'Active': formatAccountActive[account.active as keyof IAccountActive],
  }
};

class AccountDetails extends React.Component<IAccountDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetAccountById } = this.props;
    onGetAccountById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetAccountById } = this.props;
      onGetAccountById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleAccount } = this.props;
    onClearSingleAccount();
  }

  onEditButtonClickHandler = () => {
    const { history, account } = this.props;
    history.push(`/accounts/${account.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteAccountById, account, token, history, } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteAccountById(account.id, token);
    history.push('/');
  };

  render() {
    const { account, match, loading, index, accountListRedirectPath } = this.props;
    const { showModal } = this.state;
    const accountDetails = account? <DescriptionList entity={formatAccountDetails(account)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const accountListURL = accountListRedirectPath? accountListRedirectPath : `/accounts?&page=${index? index : 1}`;

    return (
      <div className={styles.AccountDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={accountListURL} exact>
              Accounts
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={accountListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.AccountDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {accountDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this account?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(AccountDetails);
