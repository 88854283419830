
interface IRules {
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  isEmail?: boolean;
  isURL?: boolean;
  isNumeric?: boolean;
}

// Utility for validation check
export const inputValidator = (value: string, rules: IRules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (!rules.isRequired && !value.length) {
    return true;
  }

  if (rules.isRequired) {
    isValid = value.toString().trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isURL) {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};
