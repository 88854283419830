import * as api from '../../services/api';

import {
  CLEAR_SINGLE_PROFILE,
  CREATE_SINGLE_PROFILE_FAIL,
  CREATE_SINGLE_PROFILE_START,
  CREATE_SINGLE_PROFILE_SUCCESS,
  DELETE_SINGLE_PROFILE_FAIL,
  DELETE_SINGLE_PROFILE_START,
  DELETE_SINGLE_PROFILE_SUCCESS,
  FETCH_PROFILES_FAIL,
  FETCH_PROFILES_START,
  FETCH_PROFILES_SUCCESS,
  FETCH_SINGLE_PROFILE_FAIL,
  FETCH_SINGLE_PROFILE_START,
  FETCH_SINGLE_PROFILE_SUCCESS,
  IProfileActionTypes,
  SET_PROFILE_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_PROFILE_FAIL,
  UPDATE_SINGLE_PROFILE_START,
  UPDATE_SINGLE_PROFILE_SUCCESS,
} from './types';

export const fetchSingleProfileStart = (): IProfileActionTypes => {
    return {
      type: FETCH_SINGLE_PROFILE_START
    };
};

export const fetchSingleProfileSuccess = (currentProfile: {}): IProfileActionTypes => {
    return {
      type: FETCH_SINGLE_PROFILE_SUCCESS,
      currentProfile,
    };
};

export const fetchSingleProfileFail = (error: Error): IProfileActionTypes => {
  return {
    type: FETCH_SINGLE_PROFILE_FAIL,
    error,
  };
};

export const clearSingleProfile = (): IProfileActionTypes => {
  return {
    type: CLEAR_SINGLE_PROFILE,
  };
};

export const createSingleProfileStart = (): IProfileActionTypes => {
  return {
    type: CREATE_SINGLE_PROFILE_START
  };
};

export const createSingleProfileSuccess = (currentProfile: {}): IProfileActionTypes => {
  return {
    type: CREATE_SINGLE_PROFILE_SUCCESS,
    currentProfile,
  };
};

export const createSingleProfileFail = (error: Error): IProfileActionTypes => {
  return {
    type: CREATE_SINGLE_PROFILE_FAIL,
    error,
  };
};

export const updateSingleProfileStart = (): IProfileActionTypes => {
  return {
    type: UPDATE_SINGLE_PROFILE_START
  };
};

export const updateSingleProfileSuccess = (currentProfile: {}): IProfileActionTypes => {
  return {
    type: UPDATE_SINGLE_PROFILE_SUCCESS,
    currentProfile,
  };
};

export const updateSingleProfileFail = (error: Error): IProfileActionTypes => {
  return {
    type: UPDATE_SINGLE_PROFILE_FAIL,
    error,
  };
};

export const deleteSingleProfileStart = (): IProfileActionTypes => {
  return {
    type: DELETE_SINGLE_PROFILE_START,
  };
};

export const deleteSingleProfileSuccess = (): IProfileActionTypes => {
  return {
    type: DELETE_SINGLE_PROFILE_SUCCESS,
  };
};

export const deleteSingleProfileFail= (error: Error): IProfileActionTypes => {
  return {
    type: DELETE_SINGLE_PROFILE_FAIL,
    error
  };
};

export const fetchProfilesStart = (): IProfileActionTypes => {
  return {
    type: FETCH_PROFILES_START
  };
};

export const fetchProfilesSuccess = (profilesList: [], profilesPages: number, index: number): IProfileActionTypes => {
  return {
    type: FETCH_PROFILES_SUCCESS,
    profilesList,
    profilesPages,
    index,
  };
};

export const fetchProfilesFail = (error: Error): IProfileActionTypes => {
  return {
    type: FETCH_PROFILES_FAIL,
    error,
  };
};

export const setProfileListRedirectPath = (path: string): IProfileActionTypes => {
  return {
    type: SET_PROFILE_LIST_REDIRECT_PATH,
    path
  };
};

export const getProfileById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleProfileStart());
    return api.getProfileById(token, id)
      .then(response => {
        dispatch(fetchSingleProfileSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleProfileFail(error));
      });
  }
};

export const deleteProfileById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleProfileStart());
     return api.deleteProfileById(token, id)
        .then(response => {
          dispatch(deleteSingleProfileSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleProfileFail(error));
        });
  }
};

export const getProfiles = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchProfilesStart());
    return api.getProfiles(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchProfilesSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchProfilesFail(error));
      });
  }
};

export const createProfile = (token: string, profile: any) => {
  return (dispatch: any) => {
    dispatch(createSingleProfileStart());
    return api.createProfile(token, profile)
      .then(response => {
        dispatch(createSingleProfileSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleProfileFail(error));
      });
  }
};

export const updateProfile = (token: string, profile: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleProfileStart());
    return api.updateProfile(token, profile, id)
      .then(response => {
        dispatch(updateSingleProfileSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleProfileFail(error));
      });
  }
};
