import * as React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Breadcrumb } from "semantic-ui-react";
import NavigationItem from "../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem";
import IconButton from "../../../components/UI/IconButton/IconButton";
import Search from "../../../components/UI/Search/Search";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from "../../../components/UI/Table/Table";
import { loadingAppSelector } from "../../../store/global/selector";
import {
  getTestStatus,
  setTestStatusListRedirectPath,
} from "../../../store/test_status";
import { setLoginRedirectPath } from "../../../store/login";
import styles from "./TestStatusList.module.css";
import {
  formatTestStatusActive,
  ITestStatusActive,
  searchByOptions,
} from "../../../utils/test_status/Formatters";
import Pagination from "../../../components/UI/Pagination/Pagination";
import * as StringURLHelper from "../../../utils/StringURLHelper";
import PaginationItemsSelector from "../../../components/UI/PaginationItemsSelector/PaginationItemsSelector";

interface ITestStatusListProps {
  token: string;
  history: any;
  location: any;
  testStatus: [];
  pages: number;
  index: number;
  loading: boolean;
  onSetLoginRedirectPath(path: string): any;
  onSetTestStatusRedirectPath(path: string): any;
  onGetTestStatus(
    token: string,
    index: number,
    items: number,
    searchBy: any,
    search: any
  ): any;
}

const TableHeaders = ["ID", "Description", "Active In Metabase"];

const createDataRow = (testStatus: any) => {
  return [
    testStatus.id,
    testStatus.description,
    formatTestStatusActive[testStatus.isActiveInMetabase as keyof ITestStatusActive]
  ];
};

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    testStatus: state.testStatus.list,
    pages: state.testStatus.page,
    index: state.testStatus.index,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) =>
      dispatch(setLoginRedirectPath(path)),
    onGetTestStatus: (
      token: string,
      index: number,
      items: number,
      searchBy: any,
      search: any
    ) => dispatch(getTestStatus(token, index, items, searchBy, search)),
    onSetTestStatusRedirectPath: (path: string) =>
      dispatch(setTestStatusListRedirectPath(path)),
  }; 
};

class TestStatusList extends React.Component<ITestStatusListProps> {
  componentDidMount() {
    const {
      token,
      onSetLoginRedirectPath,
      onGetTestStatus,
      location,
      onSetTestStatusRedirectPath,
    } = this.props;

    onSetLoginRedirectPath("/status");
    
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    const items = queries.items ? parseInt(queries.items as any, 10) : 10;

    onSetTestStatusRedirectPath(`/status?${queryString.stringify(queries)}`);
    onGetTestStatus(token, page, items, queries.searchBy, queries.search);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const {
        token,
        onSetLoginRedirectPath,
        onGetTestStatus,
        location,
        onSetTestStatusRedirectPath,
      } = this.props;

      onSetLoginRedirectPath("/status");
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      const items = queries.items ? parseInt(queries.items as any, 10) : 10;
      onSetTestStatusRedirectPath(
        `/status?${queryString.stringify(queries)}`
      );
      onGetTestStatus(token, page, items, queries.searchBy, queries.search);
    }
  }

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath(`/status/${row[0]}/details`);
    this.props.history.push(`/status/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    this.props.history.push(`/status/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  };

  render() {
    const { testStatus, loading, location, pages, index, history } = this.props;

    const tableRows = testStatus.map(status => {
      return createDataRow(status);
    });
    const spinner = loading ? <Spinner type="linear" /> : null;
    const page = index ? parseInt(index as any, 10) : 1;

    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Status </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        <div className={styles.Table}>
          <div className={`d-flex justify-content-between`}>
            <Search 
              location={location}
              searchByOptions={searchByOptions}
              history={history}
            />
            <PaginationItemsSelector location={location} onPageRedirection={(qStr: string) => this.onPageRedirection(qStr)} />
            <div className={styles.AddButton}>
              <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
            </div>
          </div>

          <Table headers={TableHeaders} rows={tableRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(TestStatusList);
