export const FETCH_SINGLE_ENVIRONMENT_START = 'FETCH_SINGLE_ENVIRONMENT_START';
export const FETCH_SINGLE_ENVIRONMENT_SUCCESS = 'FETCH_SINGLE_ENVIRONMENT_SUCCESS';
export const FETCH_SINGLE_ENVIRONMENT_FAIL = 'FETCH_SINGLE_ENVIRONMENT_FAIL';
export const CLEAR_SINGLE_ENVIRONMENT = 'CLEAR_SINGLE_ENVIRONMENT';

export const FETCH_ENVIRONMENTS_START = 'FETCH_ENVIRONMENTS_START';
export const FETCH_ENVIRONMENTS_SUCCESS = 'FETCH_ENVIRONMENTS_SUCCESS';
export const FETCH_ENVIRONMENTS_FAIL = 'FETCH_ENVIRONMENTS_FAIL';

export const CREATE_SINGLE_ENVIRONMENT_START = 'CREATE_SINGLE_ENVIRONMENT_START';
export const CREATE_SINGLE_ENVIRONMENT_SUCCESS = 'CREATE_SINGLE_ENVIRONMENT_SUCCESS';
export const CREATE_SINGLE_ENVIRONMENT_FAIL = 'CREATE_SINGLE_ENVIRONMENT_FAIL';

export const UPDATE_SINGLE_ENVIRONMENT_START = 'UPDATE_SINGLE_ENVIRONMENT_START';
export const UPDATE_SINGLE_ENVIRONMENT_SUCCESS = 'UPDATE_SINGLE_ENVIRONMENT_SUCCESS';
export const UPDATE_SINGLE_ENVIRONMENT_FAIL = 'UPDATE_SINGLE_ENVIRONMENT_FAIL';

export const DELETE_SINGLE_ENVIRONMENT_START = 'DELETE_SINGLE_ENVIRONMENT_START';
export const DELETE_SINGLE_ENVIRONMENT_SUCCESS = 'DELETE_SINGLE_ENVIRONMENT_SUCCESS';
export const DELETE_SINGLE_ENVIRONMENT_FAIL = 'DELETE_SINGLE_ENVIRONMENT_FAIL';

export const SET_ENVIRONMENT_LIST_REDIRECT_PATH = 'SET_ENVIRONMENT_LIST_REDIRECT_PATH';

export interface IEnvironmentState {
  currentEnvironment: {} | null;
  environmentsList: [],
  environmentListRedirectPath: string | null,
  environmentsPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleEnvironmentStartAction {
  type: typeof FETCH_SINGLE_ENVIRONMENT_START;
}

export interface IFetchSingleEnvironmentSuccessAction {
  type: typeof FETCH_SINGLE_ENVIRONMENT_SUCCESS;
  currentEnvironment: {};
}

export interface IFetchSingleEnvironmentFailAction {
  type: typeof FETCH_SINGLE_ENVIRONMENT_FAIL;
  error: object;
}

export interface ICreateSingleEnvironmentStartAction {
  type: typeof CREATE_SINGLE_ENVIRONMENT_START;
}

export interface ICreateSingleEnvironmentSuccessAction {
  type: typeof CREATE_SINGLE_ENVIRONMENT_SUCCESS;
  currentEnvironment: {};
}

export interface ICreateSingleEnvironmentFailAction {
  type: typeof CREATE_SINGLE_ENVIRONMENT_FAIL;
  error: object;
}

export interface IUpdateSingleEnvironmentStartAction {
  type: typeof UPDATE_SINGLE_ENVIRONMENT_START;
}

export interface IUpdateSingleEnvironmentSuccessAction {
  type: typeof UPDATE_SINGLE_ENVIRONMENT_SUCCESS;
  currentEnvironment: {};
}

export interface IUpdateSingleEnvironmentFailAction {
  type: typeof UPDATE_SINGLE_ENVIRONMENT_FAIL;
  error: object;
}

export interface IDeleteSingleEnvironmentStartAction {
  type: typeof DELETE_SINGLE_ENVIRONMENT_START;
}

export interface IDeleteSingleEnvironmentSuccessAction {
  type: typeof DELETE_SINGLE_ENVIRONMENT_SUCCESS;
}

export interface IDeleteSingleEnvironmentFailAction {
  type: typeof DELETE_SINGLE_ENVIRONMENT_FAIL;
  error: object;
}

export interface IClearSingleEnvironmentAction {
  type: typeof CLEAR_SINGLE_ENVIRONMENT;
}

export interface IFetchEnvironmentsStartAction {
  type: typeof FETCH_ENVIRONMENTS_START;
}

export interface IFetchEnvironmentsSuccessAction {
  type: typeof FETCH_ENVIRONMENTS_SUCCESS;
  environmentsList: [];
  environmentsPages: number,
  index: number;
}

export interface IFetchEnvironmentsFailAction {
  type: typeof FETCH_ENVIRONMENTS_FAIL;
  error: object;
}

export interface ISetEnvironmentListRedirectPath {
  type: typeof SET_ENVIRONMENT_LIST_REDIRECT_PATH;
  path: string;
}

export type IEnvironmentActionTypes = IFetchSingleEnvironmentStartAction | IFetchSingleEnvironmentSuccessAction | IFetchSingleEnvironmentFailAction
  | IFetchEnvironmentsStartAction | IFetchEnvironmentsSuccessAction | IFetchEnvironmentsFailAction | IClearSingleEnvironmentAction
  | IDeleteSingleEnvironmentStartAction | IDeleteSingleEnvironmentSuccessAction | IDeleteSingleEnvironmentFailAction | ICreateSingleEnvironmentStartAction
  | ICreateSingleEnvironmentSuccessAction | ICreateSingleEnvironmentFailAction | IUpdateSingleEnvironmentStartAction | IUpdateSingleEnvironmentSuccessAction
  | IUpdateSingleEnvironmentFailAction | ISetEnvironmentListRedirectPath;
