import axios from '../axios-instance';

export const getTestCases = (token: string, testReportId: number, index: number, searchBy: any, search: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  let path = `/submit/${testReportId}/testCases`;
  if (index !== -1) {
    path += `?items=10&index=${index}`;
  }
  return axios.get(path, options);
};

export const getTestCaseById = (token: string, testReportId: number, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.get(`/submit/${testReportId}/testCases/${id}`, options);
};

export const createTestCase = (token: string, testCase: any, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.post(`/submit/${testReportId}/testCases`, testCase, options);
};

export const updateTestCase = (token: string, testCase: any, id: string, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.put(`/submit/${testReportId}/testCases/${id}`, testCase, options);
};

export const deleteTestCaseById = (token: string, id: string, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.delete(`/submit/${testReportId}/testCases/${id}`, options);
};
