import { createSelector } from "reselect";

export const loadingAppSelector = createSelector(
  (state: any) => state.users.loading,
  (state: any) => state.profiles.loading,
  (state: any) => state.accounts.loading,
  (state: any) => state.environments.loading,
  (state: any) => state.testReports.loading,
  (state: any) => state.browsers.loading,
  (state: any) => state.defects.loading,
  (state: any) => state.testCases.loading,
  (state: any) => state.testStatus.loading,
  (
    loadingUsers,
    loadingProfile,
    loadingAccounts,
    loadingEnvironments,
    loadingTestReports,
    loadingBrowsers,
    loadingDefects,
    loadingTestCases,
    loadingTestStatus
  ) => {
    return (
      loadingUsers ||
      loadingProfile ||
      loadingAccounts ||
      loadingEnvironments ||
      loadingTestReports ||
      loadingBrowsers ||
      loadingDefects ||
      loadingTestCases ||
      loadingTestStatus
    );
  }
);