import axios from '../axios-instance';

export const getDefects = (token: string, testReportId: number, index: number, searchBy: any, search: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  let path = `/submit/${testReportId}/defects`;
  if (index !== -1) {
    path += `?items=10&index=${index}`;
  }
  return axios.get(path, options);
};

export const getDefectById = (token: string, id: string, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.get(`/submit/${testReportId}/defects/${id}`, options);
};

export const createDefect = (token: string, defect: any, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.post(`/submit/${testReportId}/defects`, defect, options);
};

export const updateDefect = (token: string, defect: any, id: string, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.put(`/submit/${testReportId}/defects/${id}`, defect, options);
};

export const deleteDefectById = (token: string, id: string, testReportId: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.delete(`/submit/${testReportId}/defects/${id}`, options);
};
