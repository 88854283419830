import * as api from '../../services/api';

import {
  CLEAR_SINGLE_DEFECT,
  CREATE_SINGLE_DEFECT_FAIL,
  CREATE_SINGLE_DEFECT_START,
  CREATE_SINGLE_DEFECT_SUCCESS,
  DELETE_SINGLE_DEFECT_FAIL,
  DELETE_SINGLE_DEFECT_START,
  DELETE_SINGLE_DEFECT_SUCCESS,
  FETCH_DEFECTS_FAIL,
  FETCH_DEFECTS_START,
  FETCH_DEFECTS_SUCCESS,
  FETCH_SINGLE_DEFECT_FAIL,
  FETCH_SINGLE_DEFECT_START,
  FETCH_SINGLE_DEFECT_SUCCESS,
  IDefectActionTypes,
  SET_DEFECT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_DEFECT_FAIL,
  UPDATE_SINGLE_DEFECT_START,
  UPDATE_SINGLE_DEFECT_SUCCESS,
} from './types';

export const fetchSingleDefectStart = (): IDefectActionTypes => {
    return {
      type: FETCH_SINGLE_DEFECT_START
    };
};

export const fetchSingleDefectSuccess = (currentDefect: {}): IDefectActionTypes => {
    return {
      type: FETCH_SINGLE_DEFECT_SUCCESS,
      currentDefect,
    };
};

export const fetchSingleDefectFail = (error: Error): IDefectActionTypes => {
  return {
    type: FETCH_SINGLE_DEFECT_FAIL,
    error,
  };
};

export const clearSingleDefect = (): IDefectActionTypes => {
  return {
    type: CLEAR_SINGLE_DEFECT,
  };
};

export const createSingleDefectStart = (): IDefectActionTypes => {
  return {
    type: CREATE_SINGLE_DEFECT_START
  };
};

export const createSingleDefectSuccess = (currentDefect: {}): IDefectActionTypes => {
  return {
    type: CREATE_SINGLE_DEFECT_SUCCESS,
    currentDefect,
  };
};

export const createSingleDefectFail = (error: Error): IDefectActionTypes => {
  return {
    type: CREATE_SINGLE_DEFECT_FAIL,
    error,
  };
};

export const updateSingleDefectStart = (): IDefectActionTypes => {
  return {
    type: UPDATE_SINGLE_DEFECT_START
  };
};

export const updateSingleDefectSuccess = (currentDefect: {}): IDefectActionTypes => {
  return {
    type: UPDATE_SINGLE_DEFECT_SUCCESS,
    currentDefect,
  };
};

export const updateSingleDefectFail = (error: Error): IDefectActionTypes => {
  return {
    type: UPDATE_SINGLE_DEFECT_FAIL,
    error,
  };
};

export const deleteSingleDefectStart = (): IDefectActionTypes => {
  return {
    type: DELETE_SINGLE_DEFECT_START,
  };
};

export const deleteSingleDefectSuccess = (): IDefectActionTypes => {
  return {
    type: DELETE_SINGLE_DEFECT_SUCCESS,
  };
};

export const deleteSingleDefectFail= (error: Error): IDefectActionTypes => {
  return {
    type: DELETE_SINGLE_DEFECT_FAIL,
    error
  };
};

export const fetchDefectsStart = (): IDefectActionTypes => {
  return {
    type: FETCH_DEFECTS_START
  };
};

export const fetchDefectsSuccess = (defectsList: [], defectsPages: number, index: number): IDefectActionTypes => {
  return {
    type: FETCH_DEFECTS_SUCCESS,
    defectsList,
    defectsPages,
    index,
  };
};

export const fetchDefectsFail = (error: Error): IDefectActionTypes => {
  return {
    type: FETCH_DEFECTS_FAIL,
    error,
  };
};

export const setDefectListRedirectPath = (path: string): IDefectActionTypes => {
  return {
    type: SET_DEFECT_LIST_REDIRECT_PATH,
    path
  };
};

export const getDefectById = (id: string, testReportId: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleDefectStart());
    return api.getDefectById(token, id, testReportId)
      .then(response => {
        dispatch(fetchSingleDefectSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleDefectFail(error));
      });
  }
};

export const deleteDefectById = (id: string, testReportId: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleDefectStart());
     return api.deleteDefectById(token, id, testReportId)
        .then(response => {
          dispatch(deleteSingleDefectSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleDefectFail(error));
        });
  }
};

export const getDefects = (token: string, testReportId: number, index: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchDefectsStart());
    return api.getDefects(token, testReportId, index, searchBy, search)
      .then(response => {
        dispatch(fetchDefectsSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchDefectsFail(error));
      });
  }
};

export const createDefect = (token: string, defect: any, testReportId: string) => {
  return (dispatch: any) => {
    dispatch(createSingleDefectStart());
    return api.createDefect(token, defect, testReportId)
      .then(response => {
        dispatch(createSingleDefectSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleDefectFail(error));
      });
  }
};

export const updateDefect = (token: string, defect: any, id: string, testReportId: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleDefectStart());
    return api.updateDefect(token, defect, id, testReportId)
      .then(response => {
        dispatch(updateSingleDefectSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleDefectFail(error));
      });
  }
};
