import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../../store/global/selector';
import { clearSingleDefect, createDefect } from '../../../../store/defects';
import DefectForm from '../DefectForm/DefectForm';
import styles from './DefectCreate.module.css';
import { getTestReportById } from '../../../../store/test_reports';
import TestStatusValues from '../../../../utils/test_status/TestStatusValues';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IDefectCreateProps {
  currentTestReport: any;
  token: string;
  defect: any;
  history: any;
  index: number;
  testReportIndex: number;
  loading: boolean;
  match: any;
  defectListRedirectPath: any;
  testReportListRedirectPath: any;
  onCreateDefect(token: string, defect: any, testReportId: string): any;
  onClearSingleDefect(): any;
  onGetTestReportById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    currentTestReport: state.testReports.currentTestReport,
    token: state.login.token,
    defect: state.defects.currentDefect,
    index: state.defects.index,
    testReportIndex: state.testReports.index,
    defectListRedirectPath: state.defects.defectListRedirectPath,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateDefect: (token: string, defect: any, testReportId: string) => dispatch(createDefect(token, defect, testReportId)),
    onClearSingleDefect: () => dispatch(clearSingleDefect()),
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
  }
};

class DefectCreate extends React.Component<IDefectCreateProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetTestReportById } = this.props;
    onGetTestReportById(match.params.testReportId, token);
  }

  componentDidUpdate() {
    const { history, currentTestReport } = this.props;

    if(currentTestReport && currentTestReport.testStatusId === TestStatusValues.ARCHIVED) {
      history.push(`/testReports/${currentTestReport.id}/defects`);
    }
  }


  onSubmitDefect = async (defectPayload: any) => {
    const { onCreateDefect, token, history, match } = this.props;
    await onCreateDefect(token, defectPayload, match.params.testReportId);
    const { defect } = this.props;
    if (defect) {
      history.push(`/testReports/${match.params.testReportId}/defects/${defect.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleDefect } = this.props;
    onClearSingleDefect();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, match, index, testReportIndex, testReportListRedirectPath, defectListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${testReportIndex? testReportIndex : 1}`;
    const defectListURL = defectListRedirectPath? defectListRedirectPath : `/testReports/${match.params.testReportId}/defects?&page=${index? index : 1}`;

    return (
      <div className={styles.DefectCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testReportListURL} exact>
                Test Reports
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section> 
              <NavigationItem link={`/testReports/${match.params.testReportId}/details`} exact>
                {match.params.testReportId} 
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={defectListURL} exact>
                Defects
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              Create
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={defectListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <DefectForm onSubmitDefect={this.onSubmitDefect} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(DefectCreate);
