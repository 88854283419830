import * as React from 'react';
import styles from './DescriptionList.module.css';

interface IDescriptionList {
  entity: any;
}

const descriptionList: React.FunctionComponent<IDescriptionList> = (props) => {
  const { entity } = props;
  return (
    <dl className="row">
      {
        Object.keys(entity).map((key, index) => (
          <React.Fragment key={index}>
            <dt className={`col-md-6 col-sm-12 text-right ${styles.DescriptionList}`}>{key}</dt>
            <dd className={`col-md-6 col-sm-12  ${styles.DescriptionList}`}>{entity[key]}</dd>
          </React.Fragment>
        ))
      }
    </dl>
  );
};

export default descriptionList;
