import lightBlue from '@material-ui/core/colors/lightBlue';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotFound from './components/NotFound/NotFound';
import Login from './containers/Login/Login';
import Logout from './containers/Login/Logout/Logout';
import AuthenticatedRoute from './hoc/AuthenticatedRoute/AuthenticatedRoute';
import Layout from './hoc/Layout/Layout';
import withErrorHandling from './hoc/withErrorHandling/withErrorHandling';
import axios from './services/api/axios-instance';
import { checkLoginTimeout, logout } from './store/login';
import UserList from './containers/User/UserList/UserList';
import UserDetails from './containers/User/UserDetails/UserDetails';
import UserEdit from './containers/User/UserEdit/UserEdit';
import UserCreate from './containers/User/UserCreate/UserCreate';
import ProfileList from './containers/Profile/ProfileList/ProfileList';
import ProfileDetails from './containers/Profile/ProfileDetails/ProfileDetails';
import ProfileEdit from './containers/Profile/ProfileEdit/ProfileEdit';
import ProfileCreate from './containers/Profile/ProfileCreate/ProfileCreate';
import TestReportList from './containers/TestReport/TestReportList/TestReportList';
import TestReportDetails from './containers/TestReport/TestReportDetails/TestReportDetails';
import TestReportEdit from './containers/TestReport/TestReportEdit/TestReportEdit';
import TestCaseList from './containers/TestReport/TestCase/TestCaseList/TestCaseList';
import TestCaseDetails from './containers/TestReport/TestCase/TestCaseDetails/TestCaseDetails';
import TestCaseEdit from './containers/TestReport/TestCase/TestCaseEdit/TestCaseEdit';
import TestCaseCreate from './containers/TestReport/TestCase/TestCaseCreate/TestCaseCreate';
import DefectList from './containers/TestReport/Defect/DefectList/DefectList';
import DefectEdit from './containers/TestReport/Defect/DefectEdit/DefectEdit';
import DefectDetails from './containers/TestReport/Defect/DefectDetails/DefectDetails';
import DefectCreate from './containers/TestReport/Defect/DefectCreate/DefectCreate';
import AccountList from './containers/Account/AccountList/AccountList';
import AccountDetails from './containers/Account/AccountDetails/AccountDetails';
import AccountEdit from './containers/Account/AccountEdit/AccountEdit';
import AccountCreate from './containers/Account/AccountCreate/AccountCreate';
import EnvironmentList from './containers/Environment/EnvironmentList/EnvironmentList';
import EnvironmentDetails from './containers/Environment/EnvironmentDetails/EnvironmentDetails';
import EnvironmentEdit from './containers/Environment/EnvironmentEdit/EnvironmentEdit';
import EnvironmentCreate from './containers/Environment/EnvironmentCreate/EnvironmentCreate';
import DeviceList from './containers/Device/DeviceList/DeviceList';
import DeviceDetails from './containers/Device/DeviceDetails/DeviceDetails';
import DeviceEdit from './containers/Device/DeviceEdit/DeviceEdit';
import DeviceCreate from './containers/Device/DeviceCreate/DeviceCreate';
import BrowserList from './containers/Browser/BrowserList/BrowserList';
import BrowserDetails from './containers/Browser/BrowserDetails/BrowserDetails';
import BrowserCreate from './containers/Browser/BrowserCreate/BrowserCreate';
import BrowserEdit from './containers/Browser/BrowserEdit/BrowserEdit';
import TestStatusList from './containers/TestStatus/TestStatusList/TestStatusList';
import TestStatusDetails from './containers/TestStatus/TestStatusDetails/TestStatusDetails';
import TestStatusCreate from './containers/TestStatus/TestStatusCreate/TestStatusCreate';
import TestStatusEdit from './containers/TestStatus/TestStatusEdit/TestStatusEdit';
import PasswordChange from './containers/User/PasswordChange/PasswordChange';
import Can from './components/Can/Can';


const theme = createMuiTheme({
  palette: {
    primary: lightBlue,
  },
  typography: {
    useNextVariants: true,
  },
});

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.login.token !== null,
    token: state.login.token,
    role: state.login.role,
    tokenExpirationDate: state.login.tokenExpirationDate,
    redirectPath: state.login.loginRedirectPath,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCheckLoginTimeout: (expirationDate: Date) => dispatch(checkLoginTimeout(expirationDate)),
    onLogout: () => dispatch(logout())
  };
};

interface IAppProps {
  token: string;
  isAuthenticated: boolean;
  tokenExpirationDate: Date;
  role: string;
  onCheckLoginTimeout(expirationDate: Date): any;
}

class App extends React.Component< RouteComponentProps & IAppProps> {
  componentDidMount(): void {
    const { onCheckLoginTimeout, tokenExpirationDate } = this.props;
    onCheckLoginTimeout(new Date(tokenExpirationDate));
  }

  renderAllRoutes = () => {
    return (
      <Switch>
        <Route path="/login" component={Login}/>
        <Route path="/logout" component={Logout}/>
        <AuthenticatedRoute path="/users" exact component={UserList}/>
        <AuthenticatedRoute path="/users/:id/details" exact component={UserDetails}/>
        <AuthenticatedRoute path="/users/:id/edit" exact component={UserEdit}/>
        <AuthenticatedRoute path="/users/:id/changePassword" exact component={PasswordChange}/>
        <AuthenticatedRoute path="/users/create" exact component={UserCreate}/>
        <AuthenticatedRoute path="/profiles" exact component={ProfileList}/>
        <AuthenticatedRoute path="/profiles/:id/details" exact component={ProfileDetails}/>
        <AuthenticatedRoute path="/profiles/:id/edit" exact component={ProfileEdit}/>
        <AuthenticatedRoute path="/profiles/create" exact component={ProfileCreate}/>
        <AuthenticatedRoute path="/testReports" exact component={TestReportList}/>
        <AuthenticatedRoute path="/testReports/:id/details" exact component={TestReportDetails}/>
        <AuthenticatedRoute path="/testReports/:id/edit" exact component={TestReportEdit}/>
        <AuthenticatedRoute path="/testReports/:id/testCases" exact component={TestCaseList}/>
        <AuthenticatedRoute path="/testReports/:testReportId/testCases/:id/details" exact component={TestCaseDetails}/>
        <AuthenticatedRoute path="/testReports/:testReportId/testCases/:id/edit" exact component={TestCaseEdit}/>
        <AuthenticatedRoute path="/testReports/:testReportId/testCases/create" exact component={TestCaseCreate}/>
        <AuthenticatedRoute path="/testReports/:id/defects" exact component={DefectList}/>
        <AuthenticatedRoute path="/testReports/:testReportId/defects/:id/edit" exact component={DefectEdit}/>
        <AuthenticatedRoute path="/testReports/:testReportId/defects/:id/details" exact component={DefectDetails}/>
        <AuthenticatedRoute path="/testReports/:testReportId/defects/create" exact component={DefectCreate}/>
        <AuthenticatedRoute path="/accounts" exact component={AccountList}/>
        <AuthenticatedRoute path="/accounts/:id/details" exact component={AccountDetails}/>
        <AuthenticatedRoute path="/accounts/:id/edit" exact component={AccountEdit}/>
        <AuthenticatedRoute path="/accounts/create" exact component={AccountCreate}/>
        <AuthenticatedRoute path="/environments" exact component={EnvironmentList}/>
        <AuthenticatedRoute path="/environments/:id/details" exact component={EnvironmentDetails}/>
        <AuthenticatedRoute path="/environments/:id/edit" exact component={EnvironmentEdit}/>
        <AuthenticatedRoute path="/environments/create" exact component={EnvironmentCreate}/>
        <AuthenticatedRoute path="/devices" exact component={DeviceList}/>
        <AuthenticatedRoute path="/devices/:id/details" exact component={DeviceDetails}/>
        <AuthenticatedRoute path="/devices/:id/edit" exact component={DeviceEdit}/>
        <AuthenticatedRoute path="/devices/create" exact component={DeviceCreate}/>
        <AuthenticatedRoute path="/browsers" exact component={BrowserList}/>
        <AuthenticatedRoute path="/browsers/:id/details" exact component={BrowserDetails}/>
        <AuthenticatedRoute path="/browsers/:id/edit" exact component={BrowserEdit}/>
        <AuthenticatedRoute path="/browsers/create" exact component={BrowserCreate}/>
        <AuthenticatedRoute path="/status" exact component={TestStatusList}/>
        <AuthenticatedRoute path="/status/:id/details" exact component={TestStatusDetails}/>
        <AuthenticatedRoute path="/status/:id/edit" exact component={TestStatusEdit}/>
        <AuthenticatedRoute path="/status/create" exact component={TestStatusCreate}/>
        <Redirect exact from="/" to="/testReports"/>
        <Route component={NotFound} />
      </Switch>
    )
  }

  renderNonAdminRoutes = () => {
    return (
      <Switch>
        <Route path="/login" component={Login}/>
        <Route path="/logout" component={Logout}/>
        <AuthenticatedRoute path="/users" exact component={UserList}/>
        <AuthenticatedRoute path="/users/:id/details" exact component={UserDetails}/>
        <AuthenticatedRoute path="/users/:id/changePassword" exact component={PasswordChange}/>
        <AuthenticatedRoute path="/testReports" exact component={TestReportList}/>
        <AuthenticatedRoute path="/testReports/:id/details" exact component={TestReportDetails}/>
        <AuthenticatedRoute path="/testReports/:id/edit" exact component={TestReportEdit}/>
        <AuthenticatedRoute path="/testReports/:id/testCases" exact component={TestCaseList}/>
        <AuthenticatedRoute path="/testReports/:testReportId/testCases/:id/details" exact component={TestCaseDetails}/>
        <AuthenticatedRoute path="/testReports/:testReportId/testCases/:id/edit" exact component={TestCaseEdit}/>
        <AuthenticatedRoute path="/testReports/:testReportId/testCases/create" exact component={TestCaseCreate}/>
        <AuthenticatedRoute path="/testReports/:id/defects" exact component={DefectList}/>
        <AuthenticatedRoute path="/testReports/:testReportId/defects/:id/edit" exact component={DefectEdit}/>
        <AuthenticatedRoute path="/testReports/:testReportId/defects/:id/details" exact component={DefectDetails}/>
        <AuthenticatedRoute path="/testReports/:testReportId/defects/create" exact component={DefectCreate}/>
        <Redirect exact from="/" to="/testReports"/>
        <Route component={NotFound} />
      </Switch>
    )
  }

  render() {
    const { isAuthenticated } = this.props;
    const application = (
      <Layout isAuthenticated={isAuthenticated}>
          <Can
            perform="adminRoutes"
            data={null}
            yes={this.renderAllRoutes}
            no={this.renderNonAdminRoutes}
          />
      </Layout>
    );

      return (
        <MuiThemeProvider theme={theme}>
          <div className="App">
            {application}
          </div>
        </MuiThemeProvider>
      );
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(App, axios)));
