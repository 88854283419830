import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleUser, getUserById, updateUser } from '../../../store/users';
import UserForm from '../UserForm/UserForm';
import styles from './UserEdit.module.css';
import * as _ from 'lodash';
import * as UserValidator from '../../../utils/users/UserValidator';

interface IUserEditProps {
  token: string;
  user: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  usersError: any;
  userListRedirectPath: string;
  onUpdateUser(token: string, user: any, id: string): any;
  onGetUserById(token: string, id: string): any;
  onClearSingleUser(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    index: state.users.index,
    user: state.users.currentUser,
    userListRedirectPath: state.users.userListRedirectPath,
    loading: loadingAppSelector(state),
    usersError: state.users.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateUser: (token: string, user: any, id: string) => dispatch(updateUser(token, user, id)),
    onGetUserById: (token: string, id: string) => dispatch(getUserById(id, token)),
    onClearSingleUser: () => dispatch(clearSingleUser()),
  }
};

class UserEdit extends React.Component<IUserEditProps>{

  async componentDidMount() {
    const { match, onGetUserById, token } = this.props;
    await onGetUserById(token, match.params.id);
  }

  onSubmitUser = async (userPayload: any) => {
    const { onUpdateUser, user, token, history } = this.props;
    const userChanges = UserValidator.identifyUserChanges(user, userPayload);
    if (_.isEmpty(userChanges)) {
      history.push(`/users/${user.id}/details`);
      return;
    }
    await onUpdateUser(token, userChanges, user.id);
    const { usersError } = this.props;
    if (!usersError) {
      history.push(`/users/${user.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleUser } = this.props;
    onClearSingleUser();
  }

  render() {
    const { user, match, loading, index, userListRedirectPath } = this.props;
    let userForm = null;
    if (user && user.id.toString() === match.params.id) {
      userForm = <UserForm onSubmitUser={this.onSubmitUser} userFromProps={user}  />;
    }
    const userListURL = userListRedirectPath? userListRedirectPath : `/users?&page=${index? index : 1}`;

    return (
      <div className={styles.UserEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={userListURL} exact>
                Users
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/users/${match.params.id}/details`} exact>
                {user? user.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/users/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { userForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(UserEdit);
