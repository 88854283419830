import * as React from 'react';

interface ICard {
  title: string;
  header: JSX.Element;
  classes: string;
}

const card: React.FunctionComponent<ICard> = (props) => {
  const { title, header, children, classes } = props;
  return (
    <div className={`card ${classes}`}>
      <div className="card-header">
        {header}
      </div>
      <div className="card-body">
        <h5 className="card-title text-center">{title}</h5>
        {children}
      </div>
    </div>
  );
};

export default card;