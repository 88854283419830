interface IDeviceUpdateData {
  description?: string;
  brand?: string;
  model?: string;
  active?: boolean;

}

export const identifyDeviceChanges = (prevDevice: any, newDevice: any) => {
  const changes: IDeviceUpdateData = {};
  if (newDevice.description && (prevDevice.description !== newDevice.description)) {
    changes.description = newDevice.description;
  }

  if (newDevice.brand && (prevDevice.brand !== newDevice.brand)) {
    changes.brand = newDevice.brand;
  }

  if (newDevice.model && (prevDevice.model !== newDevice.model)) {
    changes.model = newDevice.model;
  }
  
  if (prevDevice.active !== newDevice.active) {
    changes.active = newDevice.active;
  }

  return changes;
}