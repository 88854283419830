import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../../store/global/selector';
import { clearSingleDefect, getDefectById, updateDefect } from '../../../../store/defects';
import DefectForm from '../DefectForm/DefectForm';
import styles from './DefectEdit.module.css';
import * as _ from 'lodash';
import * as DefectValidator from '../../../../utils/defects/DefectValidator';
import { getTestReportById } from '../../../../store/test_reports';
import TestStatusValues from '../../../../utils/test_status/TestStatusValues';

interface IDefectEditProps {
  currentTestReport: any;
  token: string;
  defect: any;
  history: any;
  index: number;
  testReportIndex: number;
  loading: boolean;
  match: any;
  defectsError: any;
  defectListRedirectPath: any;
  testReportListRedirectPath: any;
  onUpdateDefect(token: string, defect: any, id: string, testReportId: string): any;
  onGetDefectById(token: string, id: string, testReportId: string): any;
  onClearSingleDefect(): any;
  onGetTestReportById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    currentTestReport: state.testReports.currentTestReport,
    token: state.login.token,
    defect: state.defects.currentDefect,
    index: state.defects.index,
    testReportIndex: state.testReports.index,
    loading: loadingAppSelector(state),
    defectsError: state.defects.error,
    defectListRedirectPath: state.defects.defectListRedirectPath,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateDefect: (token: string, defect: any, id: string, testReportId: string) => dispatch(updateDefect(token, defect, id, testReportId)),
    onGetDefectById: (token: string, id: string, testReportId: string) => dispatch(getDefectById(id, testReportId, token)),
    onClearSingleDefect: () => dispatch(clearSingleDefect()),
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
  }
};

class DefectEdit extends React.Component<IDefectEditProps>{
  async componentDidMount() {
    const { match, onGetDefectById, token, onGetTestReportById } = this.props;
    await onGetDefectById(token, match.params.id, match.params.testReportId);
    await onGetTestReportById(match.params.testReportId, token);
  }

  componentDidUpdate() {
    const { history, currentTestReport } = this.props;

    if(currentTestReport && currentTestReport.testStatusId === TestStatusValues.ARCHIVED) {
      history.push(`/testReports/${currentTestReport.id}/defects`);
    }
  }

  onSubmitDefect = async (defectPayload: any) => {
    const { onUpdateDefect, defect, token, history, match } = this.props;
    const defectChanges = DefectValidator.identifyDefectChanges(defect, defectPayload);
    if (_.isEmpty(defectChanges)) {
      history.push(`/testReports/${match.params.testReportId}/defects/${defect.id}/details`);
      return;
    }
    await onUpdateDefect(token, defectChanges, defect.id, match.params.testReportId);
    const { defectsError } = this.props;
    if (!defectsError) {
      history.push(`/testReports/${match.params.testReportId}/defects/${defect.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleDefect } = this.props;
    onClearSingleDefect();
  }

  render() {
    const { defect, match, loading, index, testReportIndex, testReportListRedirectPath, defectListRedirectPath } = this.props;
    let defectForm = null;
    if (defect && defect.id.toString() === match.params.id) {
      defectForm = <DefectForm onSubmitDefect={this.onSubmitDefect} defectFromProps={defect}  />;
    }
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${testReportIndex? testReportIndex : 1}`;
    const defectListURL = defectListRedirectPath? defectListRedirectPath : `/testReports/${match.params.testReportId}/defects?&page=${index? index : 1}`;

    return (
      <div className={styles.DefectEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
                <NavigationItem link={testReportListURL} exact>
                  Test Reports
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section> 
                <NavigationItem link={`/testReports/${match.params.testReportId}/details`} exact>
                  {match.params.testReportId} 
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={defectListURL} exact>
                Defects
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/testReports/${match.params.testReportId}/defects/${match.params.id}/details`} exact>
                {defect? defect.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
                Edit
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/testReports/${match.params.testReportId}/defects/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>
        { defectForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(DefectEdit);
