import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
// eslint-disable-next-line
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
// eslint-disable-next-line
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleTestStatus, getTestStatusById } from '../../../store/test_status';
import { formatTestStatusActive, ITestStatusActive } from '../../../utils/test_status/Formatters';
import styles from './TestStatusDetails.module.css';
import * as _ from 'lodash';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface ITestStatusDetailsProps {
  token: string;
  match: any;
  testStatus: any;
  index: number;
  location: any;
  history: any;
  loading: boolean;
  testStatusListRedirectPath: string;
  onGetTestStatusById(id: string, token: string): any;
  onClearSingleTestStatus(): any;
  // onDeleteBrowserById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    testStatus: state.testStatus.current,
    index: state.testStatus.index,
    testStatusListRedirectPath: state.testStatus.listRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetTestStatusById: (id: string, token: string) => dispatch(getTestStatusById(id, token)),
    onClearSingleTestStatus: () => dispatch(clearSingleTestStatus()),
    // onDeleteBrowserById: (id: string, token: string) => dispatch(deleteBrowserById(id, token)),
  }
};

const formatOptionalField = (field: any) => !(_.isNull(field) || _.isUndefined(field))? field : '-';

const formatTestStatusDetails = (testStatus: any) => {
  return {
    ID: testStatus.id,
    Description: formatOptionalField(testStatus.description),
    'Active In Metabase': formatOptionalField(formatTestStatusActive[testStatus.isActiveInMetabase as keyof ITestStatusActive]),
  }
};

class TestStatusDetails extends React.Component<ITestStatusDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetTestStatusById } = this.props;
    onGetTestStatusById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetTestStatusById } = this.props;
    onGetTestStatusById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleTestStatus } = this.props;
    onClearSingleTestStatus();
  }

  onEditButtonClickHandler = () => {
    const { history, testStatus } = this.props;
    history.push(`/status/${testStatus.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  // onConfirmDeleteHandler = async () => {
    // const { onDeleteBrowserById, browser, token, history } = this.props;
    // this.setState({
    //   showModal: false,
    // });
    // await onDeleteBrowserById(browser.id, token);
    // history.push('/');
  // };

  render() {
    const { testStatus, match, loading, index, testStatusListRedirectPath } = this.props;
    // eslint-disable-next-line
    const { showModal } = this.state;
    const testStatusDetails = testStatus ? <DescriptionList entity={formatTestStatusDetails(testStatus)}/> : null;
    const spinner = loading ? <Spinner type="linear" /> : null;
    const testStatusListURL = testStatusListRedirectPath ? testStatusListRedirectPath : `/status?&page=${index ? index : 1}`;

    return (
      <div className={styles.TestStatusDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={testStatusListURL} exact>
              Status
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={testStatusListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.BrowserDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {testStatusDetails}
          </div>
        </div>

        {/* <Modal open={showModal}>
          Are you sure you want to delete this test status?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal> */}
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestStatusDetails);
