import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../../store/global/selector';
import { clearSingleTestCase, createTestCase } from '../../../../store/test_cases';
import TestCaseForm from '../TestCaseForm/TestCaseForm';
import styles from './TestCaseCreate.module.css';
import { getTestReportById } from '../../../../store/test_reports';
import TestStatusValues from '../../../../utils/test_status/TestStatusValues';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface ITestCaseCreateProps {
  currentTestReport: any;
  token: string;
  testCase: any;
  history: any;
  index: number;
  testReportIndex: number;
  loading: boolean;
  match: any;
  testReportListRedirectPath: string;
  testCaseListRedirectPath: string;
  onCreateTestCase(token: string, testCase: any, testReportId: string): any;
  onClearSingleTestCase(): any;
  onGetTestReportById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    currentTestReport: state.testReports.currentTestReport,
    token: state.login.token,
    testCase: state.testCases.currentTestCase,
    index: state.testCases.index,
    testReportIndex: state.testReports.index,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    testCaseListRedirectPath: state.testCases.testCaseListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateTestCase: (token: string, testCase: any, testReportId: string) => dispatch(createTestCase(token, testCase, testReportId)),
    onClearSingleTestCase: () => dispatch(clearSingleTestCase()),
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
  }
};

class TestCaseCreate extends React.Component<ITestCaseCreateProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetTestReportById } = this.props;
    onGetTestReportById(match.params.testReportId, token);
  }

  componentDidUpdate() {
    const { history, currentTestReport } = this.props;

    if(currentTestReport && currentTestReport.testStatusId === TestStatusValues.ARCHIVED) {
      history.push(`/testReports/${currentTestReport.id}/testCases`);
    }
  }

  onSubmitTestCase = async (testCasePayload: any) => {
    const { onCreateTestCase, token, history, match } = this.props;
    await onCreateTestCase(token, testCasePayload, match.params.testReportId);
    const { testCase } = this.props;
    if (testCase) {
      history.push(`/testReports/${match.params.testReportId}/testCases/${testCase.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleTestCase } = this.props;
    onClearSingleTestCase();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, match, index, testReportIndex, testReportListRedirectPath, testCaseListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${testReportIndex? testReportIndex : 1}`;
    const testCaseListURL = testCaseListRedirectPath? testCaseListRedirectPath : `/testReports/${match.params.testReportId}/testCases?&page=${index? index : 1}`;

    return (
      <div className={styles.TestCaseCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testReportListURL} exact>
                Test Reports
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section> 
              <NavigationItem link={`/testReports/${match.params.testReportId}/details`} exact>
                {match.params.testReportId} 
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testCaseListURL} exact>
                TestCases
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              Create
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={testCaseListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <TestCaseForm onSubmitTestCase={this.onSubmitTestCase} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestCaseCreate);
