import * as api from '../../services/api';

import {
  CLEAR_SINGLE_USER,
  CREATE_SINGLE_USER_FAIL,
  CREATE_SINGLE_USER_START,
  CREATE_SINGLE_USER_SUCCESS,
  DELETE_SINGLE_USER_FAIL,
  DELETE_SINGLE_USER_START,
  DELETE_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_USER_FAIL,
  FETCH_SINGLE_USER_START,
  FETCH_SINGLE_USER_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  IUserActionTypes,
  SET_USER_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_USER_FAIL,
  UPDATE_SINGLE_USER_START,
  UPDATE_SINGLE_USER_SUCCESS,
} from './types';

export const fetchSingleUserStart = (): IUserActionTypes => {
    return {
      type: FETCH_SINGLE_USER_START
    };
};

export const fetchSingleUserSuccess = (currentUser: {}): IUserActionTypes => {
    return {
      type: FETCH_SINGLE_USER_SUCCESS,
      currentUser,
    };
};

export const fetchSingleUserFail = (error: Error): IUserActionTypes => {
  return {
    type: FETCH_SINGLE_USER_FAIL,
    error,
  };
};

export const clearSingleUser = (): IUserActionTypes => {
  return {
    type: CLEAR_SINGLE_USER,
  };
};

export const createSingleUserStart = (): IUserActionTypes => {
  return {
    type: CREATE_SINGLE_USER_START
  };
};

export const createSingleUserSuccess = (currentUser: {}): IUserActionTypes => {
  return {
    type: CREATE_SINGLE_USER_SUCCESS,
    currentUser,
  };
};

export const createSingleUserFail = (error: Error): IUserActionTypes => {
  return {
    type: CREATE_SINGLE_USER_FAIL,
    error,
  };
};

export const updateSingleUserStart = (): IUserActionTypes => {
  return {
    type: UPDATE_SINGLE_USER_START
  };
};

export const updateSingleUserSuccess = (currentUser: {}): IUserActionTypes => {
  return {
    type: UPDATE_SINGLE_USER_SUCCESS,
    currentUser,
  };
};

export const updateSingleUserFail = (error: Error): IUserActionTypes => {
  return {
    type: UPDATE_SINGLE_USER_FAIL,
    error,
  };
};

export const deleteSingleUserStart = (): IUserActionTypes => {
  return {
    type: DELETE_SINGLE_USER_START,
  };
};

export const deleteSingleUserSuccess = (): IUserActionTypes => {
  return {
    type: DELETE_SINGLE_USER_SUCCESS,
  };
};

export const deleteSingleUserFail= (error: Error): IUserActionTypes => {
  return {
    type: DELETE_SINGLE_USER_FAIL,
    error
  };
};

export const fetchUsersStart = (): IUserActionTypes => {
  return {
    type: FETCH_USERS_START
  };
};

export const fetchUsersSuccess = (usersList: [], usersPages: number, index: number): IUserActionTypes => {
  return {
    type: FETCH_USERS_SUCCESS,
    usersList,
    usersPages,
    index,
  };
};

export const fetchUsersFail = (error: Error): IUserActionTypes => {
  return {
    type: FETCH_USERS_FAIL,
    error,
  };
};

export const setUserListRedirectPath = (path: string): IUserActionTypes => {
  return {
    type: SET_USER_LIST_REDIRECT_PATH,
    path
  };
};

export const getUserById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleUserStart());
    return api.getUserById(token, id)
      .then(response => {
        dispatch(fetchSingleUserSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleUserFail(error));
      });
  }
};

export const deleteUserById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleUserStart());
     return api.deleteUserById(token, id)
        .then(response => {
          dispatch(deleteSingleUserSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleUserFail(error));
        });
  }
};

export const getUsers = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchUsersStart());
    return api.getUsers(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchUsersSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchUsersFail(error));
      });
  }
};

export const createUser = (token: string, user: any) => {
  return (dispatch: any) => {
    dispatch(createSingleUserStart());
    return api.createUser(token, user)
      .then(response => {
        dispatch(createSingleUserSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleUserFail(error));
      });
  }
};

export const updateUser = (token: string, user: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleUserStart());
    return api.updateUser(token, user, id)
      .then(response => {
        dispatch(updateSingleUserSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleUserFail(error));
      });
  }
};
