interface ITestStatusUpdateData {
  description?: string;
  isActiveInMetabase?: boolean;
}

export const identifyTestStatusChanges = (prevTestStatus: any, newTestStatus: any) => {
  const changes: ITestStatusUpdateData = {};
  if (newTestStatus.description && (prevTestStatus.description !== newTestStatus.description)) {
    changes.description = newTestStatus.description;
  }

  if (prevTestStatus.isActiveInMetabase !== newTestStatus.isActiveInMetabase) {
    changes.isActiveInMetabase = newTestStatus.isActiveInMetabase;
  }

  return changes;
}