const rules = {
  user: {
    static: [
      "testReports:list",
      "testReports:editPartialSelf",
      "users:list",
      "users:detailsSelf",
      "users:passwordChangeSelf",
    ],
  },
  admin: {
    static: [
      "all:all"
    ]
  }
};

export interface IRules {
  user: {
    static: string[],
    dynamic?: any
  }
  admin: {
    static: string[],
    dynamic?: any
  }
}

export default rules;