import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Search from '../../../components/UI/Search/Search';
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from '../../../components/UI/Table/Table';
import { loadingAppSelector } from '../../../store/global/selector';
import { getBrowsers, setBrowserListRedirectPath } from '../../../store/browsers';
import { setLoginRedirectPath } from '../../../store/login';
import styles from './BrowserList.module.css';
import { formatBrowserActive, IBrowserActive, searchByOptions } from '../../../utils/browsers/Formatters';
import Pagination from '../../../components/UI/Pagination/Pagination';
import * as StringURLHelper from '../../../utils/StringURLHelper';
import PaginationItemsSelector from '../../../components/UI/PaginationItemsSelector/PaginationItemsSelector';

interface IBrowserListProps {
  token: string;
  history: any;
  location: any;
  browsers: [];
  pages: number;
  index: number;
  loading: boolean;
  onSetLoginRedirectPath(path: string): any;
  onSetBrowserListRedirectPath(path: string): any;
  onGetBrowsers(token: string, index: number, items: number, searchBy: any, search: any): any;
}

const BrowserListHeaders = [
  'ID',
  'Description',
  'Active',
];

const createDataRow = (browser: any) => {
  return [
    browser.id,
    browser.description,
    formatBrowserActive[browser.active as keyof IBrowserActive]
  ];
};

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    browsers: state.browsers.browsersList,
    pages: state.browsers.browsersPages,
    index: state.browsers.index,
    loading: loadingAppSelector(state)
  }
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
    onGetBrowsers: (token: string, index: number, items: number, searchBy: any, search: any) => dispatch(getBrowsers(token, index, items, searchBy, search)),
    onSetBrowserListRedirectPath: (path: string) => dispatch(setBrowserListRedirectPath(path)),        
  }
};

class BrowserList extends React.Component<IBrowserListProps> {
  state = {
    browsers: []
  };

  componentDidMount(): void {
    const { token, onSetLoginRedirectPath, onGetBrowsers, location, onSetBrowserListRedirectPath } = this.props;
    onSetLoginRedirectPath('/browsers');
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    const items = queries.items ? parseInt(queries.items as any, 10) : 10;
    onSetBrowserListRedirectPath(`/browsers?${queryString.stringify(queries)}`);
    onGetBrowsers(token, page, items, queries.searchBy, queries.search);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const { token, onSetLoginRedirectPath, onGetBrowsers, location, onSetBrowserListRedirectPath } = this.props;
      onSetLoginRedirectPath('/browsers');
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      const items = queries.items ? parseInt(queries.items as any, 10) : 10;
      onSetBrowserListRedirectPath(`/browsers?${queryString.stringify(queries)}`);
      onGetBrowsers(token, page, items, queries.searchBy, queries.search);
    }
  } 

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath(`/browsers/${row[0]}/details`);
    this.props.history.push(`/browsers/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    this.props.history.push(`/browsers/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  }

  render() {
    const { browsers, loading, location, pages, index, history} = this.props;
    const browsersRows = browsers.map(browser => {
      return createDataRow(browser);
    });
    const spinner = loading? <Spinner type="linear" /> : null;
    const page = index? parseInt(index as any, 10) : 1;
    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Browsers </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        <div className={styles.Table}>
          <div className={`d-flex justify-content-between`}>
            <Search 
              location={location}
              searchByOptions={searchByOptions}
              history={history}
            />
            <PaginationItemsSelector location={location} onPageRedirection={(qStr: string) => this.onPageRedirection(qStr)} />
            <div className={styles.AddButton}>
              <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
            </div>
          </div>

          <Table headers={BrowserListHeaders} rows={browsersRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(BrowserList);
