import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleUser, deleteUserById, getUserById } from '../../../store/users';
import { formatUserActive, formatUserType, formatUserRole, IUserActive, IUserTpe, IUserRole } from '../../../utils/users/Formatters';
import styles from './UserDetails.module.css';
import Can from '../../../components/Can/Can';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IUserDetailsProps {
  token: string;
  match: any;
  user: any;
  index: number;
  history: any;
  userListRedirectPath: string;
  loading: boolean;
  location: any;
  onGetUserById(id: string, token: string): any;
  onClearSingleUser(): any;
  onDeleteUserById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    index: state.users.index,
    user: state.users.currentUser,
    userListRedirectPath: state.users.userListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetUserById: (id: string, token: string) => dispatch(getUserById(id, token)),
    onClearSingleUser: () => dispatch(clearSingleUser()),
    onDeleteUserById: (id: string, token: string) => dispatch(deleteUserById(id, token)),
  }
};

const formatUserDetails = (user: any) => {
  return {
    ID: user.id,
    Name: user.name,
    'Last Name': user.lastName? user.lastName : '-',
    'Email': user.email,
    'Type': user.type? formatUserType[user.type as keyof IUserTpe] : '-',
    'Role': user.role? formatUserRole[user.role as keyof IUserRole] : '-',
    'Active': formatUserActive[user.active as keyof IUserActive],
  }
};

class UserDetails extends React.Component<IUserDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetUserById } = this.props;
    onGetUserById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      const { token, match, onGetUserById } = this.props;
      onGetUserById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleUser } = this.props;
    onClearSingleUser();
  }

  onEditButtonClickHandler = () => {
    const { history, user } = this.props;
    history.push(`/users/${user.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteUserById, user, token, history } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteUserById(user.id, token);
    history.push('/');
  };

  render() {
    const { user, match, loading, index, userListRedirectPath } = this.props;
    const { showModal } = this.state;
    const userDetails = user? <DescriptionList entity={formatUserDetails(user)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const userListURL = userListRedirectPath? userListRedirectPath : `/users?&page=${index? index : 1}`;

    const editUserIconButton = (
      <>
        <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
        <div className={styles.Divider}/>
      </>
    );

    return (
      <div className={styles.UserDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={userListURL} exact>
              Users
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={userListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <Can
              perform="users:edit"
              data={null}
              yes={() => editUserIconButton}
              no={() => null}
            />
            <NavigationItem link={`/users/${match.params.id}/changePassword`} exact>
              <IconButton color="primary" type="lock" fabSize="medium" disabled={loading} />
            </NavigationItem>

            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.UserDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {userDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this user?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(UserDetails);
