
export const formatBooleanField = {
  'true': 'Yes',
  'false': 'No'
};

export type IFormatBooleanField = typeof formatBooleanField

export const searchByOptions = [
  {
    label: 'ID',
    value: 'testReport.id'
  },
  {
    label: 'Tester',
    value: 'reviewerProfile.description'
  },
  {
    label: 'Developer',
    value: 'reviewedProfile.description'
  },
  {
    label: 'Account',
    value: 'account.description'
  },
  {
    label: 'Project',
    value: 'testReport.project'
  },
  {
    label: 'Issue Key',
    value: 'testReport.issueId'
  },
];
