import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleEnvironment, createEnvironment } from '../../../store/environments';
import EnvironmentForm from '../EnvironmentForm/EnvironmentForm';
import styles from './EnvironmentCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IEnvironmentCreateProps {
  token: string;
  environment: any;
  index: number;
  history: any;
  loading: boolean;
  environmentListRedirectPath: string;
  onCreateEnvironment(token: string, environment: any): any;
  onClearSingleEnvironment(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    environment: state.environments.currentEnvironment,
    index: state.environments.index,
    environmentListRedirectPath: state.environments.environmentListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateEnvironment: (token: string, environment: any) => dispatch(createEnvironment(token, environment)),
    onClearSingleEnvironment: () => dispatch(clearSingleEnvironment()),
  }
};

class EnvironmentCreate extends React.Component<IEnvironmentCreateProps>{
  state: State = initialState;

  onSubmitEnvironment = async (environmentPayload: any) => {
    const { onCreateEnvironment, token, history } = this.props;
    await onCreateEnvironment(token, environmentPayload);
    const { environment } = this.props;
    if (environment) {
      history.push(`/environments/${environment.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleEnvironment } = this.props;
    onClearSingleEnvironment();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, environmentListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const environmentListURL = environmentListRedirectPath? environmentListRedirectPath : `/environments?&page=${index? index : 1}`;

    return (
      <div className={styles.EnvironmentCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={environmentListURL} exact>
                Environments
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={environmentListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <EnvironmentForm onSubmitEnvironment={this.onSubmitEnvironment} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(EnvironmentCreate);
