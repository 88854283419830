interface IEnvironmentUpdateData {
  description?: string;
  active?: boolean;

}

export const identifyEnvironmentChanges = (prevEnvironment: any, newEnvironment: any) => {
  const changes: IEnvironmentUpdateData = {};
  if (newEnvironment.description && (prevEnvironment.description !== newEnvironment.description)) {
    changes.description = newEnvironment.description;
  }
  if (prevEnvironment.active !== newEnvironment.active) {
    changes.active = newEnvironment.active;
  }

  return changes;
}