import {
  CLEAR_SINGLE_ACCOUNT,
  CREATE_SINGLE_ACCOUNT_FAIL,
  CREATE_SINGLE_ACCOUNT_START,
  CREATE_SINGLE_ACCOUNT_SUCCESS,
  DELETE_SINGLE_ACCOUNT_FAIL,
  DELETE_SINGLE_ACCOUNT_START,
  DELETE_SINGLE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS_FAIL,
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_SINGLE_ACCOUNT_FAIL,
  FETCH_SINGLE_ACCOUNT_START,
  FETCH_SINGLE_ACCOUNT_SUCCESS,
  IAccountActionTypes,
  IAccountState,
  IClearSingleAccountAction,
  ICreateSingleAccountFailAction,
  ICreateSingleAccountStartAction,
  ICreateSingleAccountSuccessAction,
  IDeleteSingleAccountFailAction,
  IDeleteSingleAccountStartAction,
  IDeleteSingleAccountSuccessAction,
  IFetchAccountsFailAction,
  IFetchAccountsStartAction,
  IFetchAccountsSuccessAction,
  IFetchSingleAccountFailAction,
  IFetchSingleAccountStartAction,
  IFetchSingleAccountSuccessAction,
  ISetAccountListRedirectPath,
  IUpdateSingleAccountFailAction,
  IUpdateSingleAccountStartAction,
  IUpdateSingleAccountSuccessAction,
  SET_ACCOUNT_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_ACCOUNT_FAIL,
  UPDATE_SINGLE_ACCOUNT_START,
  UPDATE_SINGLE_ACCOUNT_SUCCESS,
} from './types';

const initialState: IAccountState = {
  currentAccount: null,
  accountsList: [],
  accountsPages: 0,
  index: 0,
  accountListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleAccountStart = (state: IAccountState, action: IFetchSingleAccountStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleAccountSuccess = (state: IAccountState, action: IFetchSingleAccountSuccessAction) => {
  return {
    ...state,
    currentAccount: action.currentAccount,
    error: null,
    loading: false
  };
};

const fetchSingleAccountFail = (state: IAccountState, action: IFetchSingleAccountFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleAccount = (state: IAccountState, action: IClearSingleAccountAction) => {
  return {
    ...state,
    currentAccount: null
  };
};

const createSingleAccountStart = (state: IAccountState, action: ICreateSingleAccountStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleAccountSuccess = (state: IAccountState, action: ICreateSingleAccountSuccessAction) => {
  return {
    ...state,
    currentAccount: action.currentAccount,
    error: null,
    loading: false
  };
};

const createSingleAccountFail = (state: IAccountState, action: ICreateSingleAccountFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleAccountStart = (state: IAccountState, action: IUpdateSingleAccountStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleAccountSuccess = (state: IAccountState, action: IUpdateSingleAccountSuccessAction) => {
  return {
    ...state,
    currentAccount: action.currentAccount,
    error: null,
    loading: false
  };
};

const updateSingleAccountFail = (state: IAccountState, action: IUpdateSingleAccountFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleAccountStart = (state: IAccountState, action: IDeleteSingleAccountStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleAccountSuccess = (state: IAccountState, action: IDeleteSingleAccountSuccessAction) => {
  return {
    ...state,
    currentAccount: null,
    error: null,
    loading: false
  };
};

const deleteSingleAccountFail = (state: IAccountState, action: IDeleteSingleAccountFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchAccountsStart = (state: IAccountState, action: IFetchAccountsStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchAccountsSuccess = (state: IAccountState, action: IFetchAccountsSuccessAction) => {
  return {
    ...state,
    accountsList: action.accountsList,
    error: null,
    loading: false,
    accountsPages: action.accountsPages,
    index: action.index,
  };
};

const fetchAccountsFail = (state: IAccountState, action: IFetchAccountsFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setAccountListRedirectPath = (state: IAccountState, action: ISetAccountListRedirectPath) => {
  return {
    ...state,
    accountListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IAccountActionTypes): IAccountState => {
  switch ( action.type ) {
    case FETCH_SINGLE_ACCOUNT_START: return fetchSingleAccountStart(state, action);
    case FETCH_SINGLE_ACCOUNT_SUCCESS: return fetchSingleAccountSuccess(state, action);
    case FETCH_SINGLE_ACCOUNT_FAIL: return fetchSingleAccountFail(state, action);
    case CREATE_SINGLE_ACCOUNT_START: return createSingleAccountStart(state, action);
    case CREATE_SINGLE_ACCOUNT_SUCCESS: return createSingleAccountSuccess(state, action);
    case CREATE_SINGLE_ACCOUNT_FAIL: return createSingleAccountFail(state, action);
    case UPDATE_SINGLE_ACCOUNT_START: return updateSingleAccountStart(state, action);
    case UPDATE_SINGLE_ACCOUNT_SUCCESS: return updateSingleAccountSuccess(state, action);
    case UPDATE_SINGLE_ACCOUNT_FAIL: return updateSingleAccountFail(state, action);
    case DELETE_SINGLE_ACCOUNT_START: return deleteSingleAccountStart(state, action);
    case DELETE_SINGLE_ACCOUNT_SUCCESS: return deleteSingleAccountSuccess(state, action);
    case DELETE_SINGLE_ACCOUNT_FAIL: return deleteSingleAccountFail(state, action);
    case CLEAR_SINGLE_ACCOUNT: return clearSingleAccount(state, action);
    case FETCH_ACCOUNTS_START: return fetchAccountsStart(state, action);
    case FETCH_ACCOUNTS_SUCCESS: return fetchAccountsSuccess(state, action);
    case FETCH_ACCOUNTS_FAIL: return fetchAccountsFail(state, action);
    case SET_ACCOUNT_LIST_REDIRECT_PATH: return setAccountListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
