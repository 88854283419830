import * as React from 'react';
import { connect } from 'react-redux';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import Input from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { IReduxState } from '../../../store/global/types';
import { inputValidator } from '../../../utils';
import { getUsers } from '../../../store/users/index';
import { getAccounts } from '../../../store/accounts/index';
import styles from './ProfileForm.module.css';
import * as _ from 'lodash';
import { formatFieldToEdit } from '../../../utils/FieldFormatHelper';

interface IProfileFormProps {
  token: string;
  profile: any;
  profileFromProps?: any;
  loading: boolean;
  users: any;
  usersPages: number;
  accounts: any;
  accountsPages: number;
  onSubmitProfile(profilePayload: any): any;
  onGetUsers(token:string): any;
  onGetAccounts(token:string): any;
}

const profileActiveOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const formatUserOptions = (users: any) => {
  return users.map((user: any) => {
    return {
      label: `${user.name} ${user.lastName}`,
      value: user.id,
    }
  })
};

const formatAccountOptions = (accounts: any) => {
  return accounts.map((account: any) => {
    return {
      label: account.description,
      value: account.id,
    }
  })
};

const initialState = {
  formControls: {
    description: {
      value: '',
      validation: {
        isRequired: true,
        minLength: 2,
        maxLength: 255,
      },
      valid: false,
      touched: false
    },
    email: {
      value: '',
      validation: {
        isEmail: true,
      },
      valid: true,
      touched: false
    },
    token: {
      value: '',
      validation: {
        minLength: 2,
        maxLength: 255,
      },
      valid: true,
      touched: false
    },
    user: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    account: {
      value: '',
      validation: {
        isRequired: true,
      },
      valid: false,
      touched: false
    },
    active: {
      value: '',
      validation: {
      },
      valid: true,
      touched: false
    },
  },
  formIsValid: false,
  showModal: false,
};

type State = Readonly<typeof initialState>;
type FormControlKey = keyof typeof initialState.formControls;

const matchStateToProps = (state: IReduxState) => {
  return {
    token: state.login.token,
    profile: state.profiles.currentProfile,
    loading: loadingAppSelector(state),
    users: state.users.usersList,
    usersPages: state.users.usersPages,
    accounts: state.accounts.accountsList,
    accountsPages: state.accounts.accountsPages,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetUsers: (token: string) => dispatch(getUsers(token, -1, -1, null, null)),
    onGetAccounts: (token: string) => dispatch(getAccounts(token, -1, -1, null, null)),
  }
};

const formatProfileFromProps = (profileFromProps: any): any => {
  return {
    description: formatFieldToEdit(profileFromProps.description),
    email: formatFieldToEdit(profileFromProps.email),
    token: formatFieldToEdit(profileFromProps.token),
    user: formatFieldToEdit(profileFromProps.user.id),
    account: formatFieldToEdit(profileFromProps.account.id),
    active: formatFieldToEdit(profileFromProps.active),
  };
};

class ProfileForm extends React.Component<IProfileFormProps>{
  constructor(props: IProfileFormProps) {
    super(props);
    this.state = initialState;
  }
  state: State;


  componentDidMount(): void {
    const { profileFromProps, users, accounts } = this.props;
    if (profileFromProps) {
      const formattedProfile = formatProfileFromProps(profileFromProps);
      const { formControls } = this.state;
      let updatedFormControls = { ...formControls };

      Object.keys(formControls).forEach((key: string) => {
        const updatedFormInput= {
          ...updatedFormControls[key as FormControlKey],
          value: formattedProfile[key],
          valid: inputValidator(formattedProfile[key], formControls[key as FormControlKey].validation),
          touched: true,
        };
        updatedFormControls = {
          ...updatedFormControls,
          [key]: updatedFormInput,
        };
      });
      if (profileFromProps.user.id && !_.some(users, {id: profileFromProps.user.id})) {
        users.push(profileFromProps.user);
      }
      if (profileFromProps.account.id && !_.some(accounts, {id: profileFromProps.account.id})) {
        accounts.push(profileFromProps.account);
      }
      this.setState({ formControls: updatedFormControls });
    }
  }

  // Handles the onChange event for the inputs.
  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state
    const { formControls } =  this.state;
    const { value } = isSelect? event : event.target;

    /*
    * Creates a new formInput object with the new value, its new valid status based on
    * required validation and touched set to false.
    * */
    const updatedFormInput= {
      ...formControls[inputIdentifier],
      value,
      valid: inputValidator(value, formControls[inputIdentifier].validation),
      touched: false,
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };

    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object and the new validation status.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  // Handles the onBlur event for the inputs.
  inputBlurHandler = (event: React.ChangeEvent<HTMLInputElement> | any, inputIdentifier: FormControlKey, isSelect: boolean) => {
    // Gets formControls from current state.
    const { formControls } = this.state;
    const { value } = isSelect? event : event.target;
    let validations = {};
    if (!isSelect) {
      const curedValue= !_.isEmpty(value.toString().trim())? value : '';
      validations = {
        valid: inputValidator(curedValue, formControls[inputIdentifier].validation),
        value: curedValue,
      };
    };

    /*
    * Creates a new formInput object with touched set to  true.
    * */
    const updatedFormInput =  {
      ...formControls[inputIdentifier],
      touched: true,
      ...validations
    };

    // Creates a new formControls object with the updated input.
    const updatedFormControls = {
      ...formControls,
      [inputIdentifier]: updatedFormInput,
    };
    // Performs form validation.
    let formIsValid = true;
    Object.keys(updatedFormControls).forEach((key: string) => {
      formIsValid = updatedFormControls[key as FormControlKey].valid && formIsValid;
    });

    // Sets the new state with the new formControls object.
    this.setState({ formControls: updatedFormControls, formIsValid });
  };

  userDropdownHandler = () => {
    const { users, onGetUsers, token, usersPages} = this.props;
    if (_.isEmpty(users) || usersPages !== 1) {
      onGetUsers(token);
    }
  };

  accountDropdownHandler = () => {
    const { accounts, onGetAccounts, token, accountsPages} = this.props;
    if (_.isEmpty(accounts) || accountsPages !== 1) {
      onGetAccounts(token);
    }
  };

  profileSubmitHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { onSubmitProfile } = this.props;
    const { description, email, token, user, account, active } = this.state.formControls;
    await onSubmitProfile({
      description: description.value,
      email: email.value ? email.value : null,
      token: token.value ? token.value : null,
      userId: user.value,
      accountId: account.value,
      // @ts-ignore
      active: active.value === false ? false : true,
    });
  };

  render() {
    const { loading, profileFromProps, users, accounts } = this.props;
    const { description, email, token, user, account, active } = this.state.formControls;
    const { formIsValid } = this.state;
    const spinner = loading? <Spinner type="linear" /> : null;
    const usersOptions = formatUserOptions(users);
    const accountsOptions = formatAccountOptions(accounts);

    return (
        <div className={styles.Form}>
          <div className={styles.Spinner}>
            { spinner }
          </div>
          <form>
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type a description', autoFocus: true}}
              value={description.value}
              shouldValidate={true}
              valid={description.valid}
              touched={description.touched}
              label="Description"
              disabled={loading}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'description', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'description', false)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type an email'}}
              value={email.value}
              shouldValidate={true}
              valid={email.valid}
              touched={email.touched}
              label="Email"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'email', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'email', false)}
            />
            <Input
              elementType="input"
              elementConfig={{type: 'text', placeholder: 'Type a token'}}
              value={token.value}
              shouldValidate={true}
              valid={token.valid}
              touched={token.touched}
              label="Token"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'token', false)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'token', false)}
            />
            <Dropdown
              value={user.value}
              label="User"
              disabled={loading}
              options={usersOptions}
              shouldValidate={true}
              required
              onMenuOpen={this.userDropdownHandler}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'user', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'user', true)}
            />
            <Dropdown
              value={account.value}
              label="Account"
              disabled={loading}
              options={accountsOptions}
              shouldValidate={true}
              required
              onMenuOpen={this.accountDropdownHandler}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'account', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'account', true)}
            />
            <Dropdown
              value={active.value}
              label="Active"
              disabled={loading}
              options={profileActiveOptions}
              shouldValidate={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'active', true)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => this.inputBlurHandler(event, 'active', true)}
            />
            <div className={`d-flex justify-content-center ${styles.Buttons}`}>
              <Button type="submit" color="success" disabled={!formIsValid || loading} onClick={(event: React.MouseEvent<HTMLElement>) => this.profileSubmitHandler(event)}>
                Save
              </Button>
              <div className={styles.Divider}/>
              <NavigationItem link={profileFromProps? `/profiles/${profileFromProps.id}/details` : '/profiles'} exact>
                <Button type="submit" color="danger" disabled={loading}>
                  Cancel
                </Button>
              </NavigationItem>
            </div>
          </form>
        </div>
    );
  }

}

export default connect(matchStateToProps, matchDispatchToProps)(ProfileForm);
