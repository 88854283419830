import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleTestStatus, getTestStatusById, updateTestStatus } from '../../../store/test_status'
import TestStatusForm from '../TestStatusForm/TestStatusForm';
import styles from './TestStatusEdit.module.css';
import * as _ from 'lodash';
import * as TestStatusValidator from '../../../utils/test_status/TestStatusValidator';

interface ITestStatusEditProps {
  token: string;
  testStatus: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  testStatusError: any;
  testStatusListRedirectPath: string;
  onUpdateTestStatus(token: string, testStatus: any, id: string): any;
  onGetTestStatusById(token: string, id: string): any;
  onClearSingleTestStatus(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    testStatus: state.testStatus.current,
    index: state.testStatus.index,
    loading: loadingAppSelector(state),
    testStatusListRedirectPath: state.testStatus.listRedirectPath,
    testStatusError: state.testStatus.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateTestStatus: (token: string, testStatus: any, id: string) => dispatch(updateTestStatus(token, testStatus, id)),
    onGetTestStatusById: (token: string, id: string) => dispatch(getTestStatusById(id, token)),
    onClearSingleTestStatus: () => dispatch(clearSingleTestStatus()),
  }
};

class TestStatusEdit extends React.Component<ITestStatusEditProps>{

  async componentDidMount() {
    const { match, onGetTestStatusById, token } = this.props;
    await onGetTestStatusById(token, match.params.id);
  }

  onSubmitTestStatus = async (testStatusPayload: any) => {
    const { onUpdateTestStatus, testStatus, token, history } = this.props;
    const testStatusChanges = TestStatusValidator.identifyTestStatusChanges(testStatus, testStatusPayload);
   
    if (_.isEmpty(testStatusChanges)) {
      history.push(`/status/${testStatus.id}/details`);
      return;
    }
    
    await onUpdateTestStatus(token, testStatusChanges, testStatus.id);
    
    const { testStatusError } = this.props;

    if (!testStatusError) {
      history.push(`/status/${testStatus.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleTestStatus } = this.props;
    onClearSingleTestStatus();
  }

  render() {
    const { testStatus, match, loading, index, testStatusListRedirectPath } = this.props;
   
    let testStatusForm = null;
    if (testStatus && testStatus.id.toString() === match.params.id) {
      testStatusForm = <TestStatusForm onSubmitTestStatus={this.onSubmitTestStatus} testStatusFromProps={testStatus}  />;
    }

    const browserListURL = testStatusListRedirectPath ? testStatusListRedirectPath : `/status?&page=${index ? index : 1}`;        
    
    return (
      <div className={styles.BrowserEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={browserListURL} exact>
                Status
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/status/${match.params.id}/details`} exact>
                {testStatus ? testStatus.id : null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/status/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { testStatusForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestStatusEdit);
