
export const formatTestStatusActive = {
  'true': 'Yes',
  'false': 'No'
};

export type ITestStatusActive = typeof formatTestStatusActive

export const searchByOptions = [
  {
    label: 'ID',
    value: 'id'
  },
  {
    label: 'Description',
    value: 'description'
  },
];
