import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import 'semantic-ui-css/semantic.css';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import loginReducer from './store/login/reducer';
import usersReducer from './store/users/reducer';
import profilesReducer from './store/profiles/reducer';
import accountsReducer from './store/accounts/reducer';
import testReportsReducer from './store/test_reports/reducer';
import testCasesReducer from './store/test_cases/reducer';
import defectsReducer from './store/defects/reducer';
import environmentsReducer from './store/environments/reducer';
import devicesReducer from './store/devices/reducer';
import browsersReducer from './store/browsers/reducer';
import projectsReducer from './store/projects/reducer';
import testStatusReducer from './store/test_status/reducer';

const persistConfigRoot = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistConfigLogin = {
  key: 'login',
  storage,
  whitelist: ['token', 'userId', 'role' , 'loginRedirectPath', 'tokenExpirationDate'],
};

// @ts-ignore
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const rootReducer = combineReducers({
  login: persistReducer(persistConfigLogin, loginReducer),
  users: usersReducer,
  profiles: profilesReducer,
  accounts: accountsReducer,
  testReports: testReportsReducer,
  testCases: testCasesReducer,
  defects: defectsReducer,
  environments: environmentsReducer,
  devices: devicesReducer,
  browsers: browsersReducer,
  projects: projectsReducer,
  testStatus: testStatusReducer
});

const persistedReducer = persistReducer(persistConfigRoot, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const persistor = persistStore(store);

const app = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
