import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Search from '../../../components/UI/Search/Search';
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from '../../../components/UI/Table/Table';
import { loadingAppSelector } from '../../../store/global/selector';
import { getProfiles, setProfileListRedirectPath } from '../../../store/profiles';
import { setLoginRedirectPath } from '../../../store/login';
import styles from './ProfileList.module.css';
 import { formatProfileActive, IProfileActive, searchByOptions } from '../../../utils/profiles/Formatters';
import Pagination from '../../../components/UI/Pagination/Pagination';
import * as StringURLHelper from '../../../utils/StringURLHelper';
import { formatOptionalField } from '../../../utils/FieldFormatHelper';
import PaginationItemsSelector from '../../../components/UI/PaginationItemsSelector/PaginationItemsSelector';

interface IProfileListProps {
  token: string;
  history: any;
  location: any;
  profiles: [];
  pages: number;
  index: number;
  loading: boolean;
  onSetLoginRedirectPath(path: string): any;
  onSetProfileListRedirectPath(path: string): any;
  onGetProfiles(token: string, index: number, items: number, searchBy: any, search: any): any;
}

const ProfileListHeaders = [
  'ID',
  'Description',
  'Email',
  'User',
  'Account',
  'Active',
];

const createDataRow = (profile: any) => {
  return [
    profile.id,
    formatOptionalField(profile.description),
    formatOptionalField(profile.email),
    formatOptionalField(`${profile.user.name} ${profile.user.lastName}`),
    formatOptionalField(profile.account.description),
    formatProfileActive[profile.active as keyof IProfileActive]
  ];
};

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    profiles: state.profiles.profilesList,
    pages: state.profiles.profilesPages,
    index: state.profiles.index,
    loading: loadingAppSelector(state)
  }
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
    onGetProfiles: (token: string, index: number, items: number, searchBy: any, search: any) => dispatch(getProfiles(token, index, items, searchBy, search)),
    onSetProfileListRedirectPath: (path: string) => dispatch(setProfileListRedirectPath(path)),    
  }
};

class ProfileList extends React.Component<IProfileListProps> {
  state = {
    profiles: []
  };

  componentDidMount(): void {
    const { token, onSetLoginRedirectPath, onGetProfiles, location, onSetProfileListRedirectPath } = this.props;
    onSetLoginRedirectPath('/profiles');
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    const items = queries.items ? parseInt(queries.items as any, 10) : 10;
    onSetProfileListRedirectPath(`/profiles?${queryString.stringify(queries)}`);
    onGetProfiles(token, page, items, queries.searchBy, queries.search);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const { token, onSetLoginRedirectPath, onGetProfiles, location, onSetProfileListRedirectPath } = this.props;
      onSetLoginRedirectPath('/profiles');
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      const items = queries.items ? parseInt(queries.items as any, 10) : 10;
      onSetProfileListRedirectPath(`/profiles?${queryString.stringify(queries)}`);
      onGetProfiles(token, page, items, queries.searchBy, queries.search);
    }
  } 

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath(`/profiles/${row[0]}/details`);
    this.props.history.push(`/profiles/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    this.props.history.push(`/profiles/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  }

  render() {
    const { profiles, loading, location, pages, index, history } = this.props;
    const profilesRows = profiles.map(profile => {
      return createDataRow(profile);
    });
    const spinner = loading? <Spinner type="linear" /> : null;
    const page = index? parseInt(index as any, 10) : 1;
    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Profiles </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        <div className={styles.Table}>
          <div className={`d-flex justify-content-between`}>
            <Search 
              location={location}
              searchByOptions={searchByOptions}
              history={history}
            />
            <PaginationItemsSelector location={location} onPageRedirection={(qStr: string) => this.onPageRedirection(qStr)} />
            <div className={styles.AddButton}>
              <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
            </div>
          </div>

          <Table headers={ProfileListHeaders} rows={profilesRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(ProfileList);
