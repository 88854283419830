import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleProfile, createProfile } from '../../../store/profiles';
import ProfileForm from '../ProfileForm/ProfileForm';
import styles from './ProfileCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IProfileCreateProps {
  token: string;
  profile: any;
  history: any;
  loading: boolean;
  index: number;
  profileListRedirectPath: string;
  onCreateProfile(token: string, profile: any): any;
  onClearSingleProfile(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    index: state.profiles.index,
    profile: state.profiles.currentProfile,
    loading: loadingAppSelector(state),
    profileListRedirectPath: state.profiles.profileListRedirectPath,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateProfile: (token: string, profile: any) => dispatch(createProfile(token, profile)),
    onClearSingleProfile: () => dispatch(clearSingleProfile()),
  }
};

class ProfileCreate extends React.Component<IProfileCreateProps>{
  state: State = initialState;

  onSubmitProfile = async (profilePayload: any) => {
    const { onCreateProfile, token, history } = this.props;
    await onCreateProfile(token, profilePayload);
    const { profile } = this.props;
    if (profile) {
      history.push(`/profiles/${profile.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleProfile } = this.props;
    onClearSingleProfile();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, profileListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const profileListURL = profileListRedirectPath? profileListRedirectPath : `/profiles?&page=${index? index : 1}`;

    return (
      <div className={styles.ProfileCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={profileListURL} exact>
                Profiles
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={profileListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <ProfileForm onSubmitProfile={this.onSubmitProfile} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(ProfileCreate);
