import * as React from 'react';
import Select from 'react-select';
import styles from './Dropdown.module.css';

interface IDropdown {
  label?: string;
  disabled?: boolean;
  options: any;
  value: string;
  required?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  onChange?(event: React.ChangeEvent<HTMLElement>): any;
  onBlur?(event: React.ChangeEvent<HTMLElement>): any;
  onEnterKeyDown?(event: React.KeyboardEvent<HTMLElement>): any;
  onMenuOpen?(): any;
}

const theme = (iTheme: any) => ({
  ...iTheme,
  colors: {
    ...iTheme.colors,
    primary: '#80bdff'
  },
});

const dropdown: React.FunctionComponent<IDropdown & any> = (props) => {
  const { label, disabled, options, onChange, onBlur, value, required, onMenuOpen, placeholder, isLoading, onEnterKeyDown} = props;
  const requiredContent = <span className={styles.Required}> * </span>;
  const dropdownValue = (value !== (undefined || null))? options.filter((option: any) => option.value.toString() === value.toString()) : null;
  const labelBody = label? 
    <label htmlFor={label}>
      <b> {label} {required? requiredContent : null} </b>
    </label> : null;
  return (
    <div className={styles.FormControl}>
      {labelBody}
    <Select
      id={label}
      isDisabled={disabled}
      value={dropdownValue}
      style={{marginBottom: '0px'}}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      theme={theme}
      isLoading={isLoading}
      onMenuOpen={onMenuOpen}
      placeholder={placeholder}
      onKeyDown={onEnterKeyDown}
    />
    </div>
  );
};

export default dropdown;
