import { CircularProgress, LinearProgress } from "@material-ui/core";
import * as React from 'react';

interface ISpinner {
  size?: number;
  color?: 'primary' | 'secondary';
  type?: 'circular' | 'linear';
}

const spinner: React.FunctionComponent<ISpinner> = (props) => {
  const { size, color, type } = props;
  const progress: JSX.Element = type === 'linear'? <LinearProgress color={color} /> : <CircularProgress color={color} size={size}/>;
  return progress;
};

export default spinner;
