export const FETCH_SINGLE_BROWSER_START = 'FETCH_SINGLE_BROWSER_START';
export const FETCH_SINGLE_BROWSER_SUCCESS = 'FETCH_SINGLE_BROWSER_SUCCESS';
export const FETCH_SINGLE_BROWSER_FAIL = 'FETCH_SINGLE_BROWSER_FAIL';
export const CLEAR_SINGLE_BROWSER = 'CLEAR_SINGLE_BROWSER';

export const FETCH_BROWSERS_START = 'FETCH_BROWSERS_START';
export const FETCH_BROWSERS_SUCCESS = 'FETCH_BROWSERS_SUCCESS';
export const FETCH_BROWSERS_FAIL = 'FETCH_BROWSERS_FAIL';

export const CREATE_SINGLE_BROWSER_START = 'CREATE_SINGLE_BROWSER_START';
export const CREATE_SINGLE_BROWSER_SUCCESS = 'CREATE_SINGLE_BROWSER_SUCCESS';
export const CREATE_SINGLE_BROWSER_FAIL = 'CREATE_SINGLE_BROWSER_FAIL';

export const UPDATE_SINGLE_BROWSER_START = 'UPDATE_SINGLE_BROWSER_START';
export const UPDATE_SINGLE_BROWSER_SUCCESS = 'UPDATE_SINGLE_BROWSER_SUCCESS';
export const UPDATE_SINGLE_BROWSER_FAIL = 'UPDATE_SINGLE_BROWSER_FAIL';

export const DELETE_SINGLE_BROWSER_START = 'DELETE_SINGLE_BROWSER_START';
export const DELETE_SINGLE_BROWSER_SUCCESS = 'DELETE_SINGLE_BROWSER_SUCCESS';
export const DELETE_SINGLE_BROWSER_FAIL = 'DELETE_SINGLE_BROWSER_FAIL';

export const SET_BROWSER_LIST_REDIRECT_PATH = 'SET_BROWSER_LIST_REDIRECT_PATH';

export interface IBrowserState {
  currentBrowser: {} | null;
  browsersList: [],
  browserListRedirectPath: string | null,
  browsersPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleBrowserStartAction {
  type: typeof FETCH_SINGLE_BROWSER_START;
}

export interface IFetchSingleBrowserSuccessAction {
  type: typeof FETCH_SINGLE_BROWSER_SUCCESS;
  currentBrowser: {};
}

export interface IFetchSingleBrowserFailAction {
  type: typeof FETCH_SINGLE_BROWSER_FAIL;
  error: object;
}

export interface ICreateSingleBrowserStartAction {
  type: typeof CREATE_SINGLE_BROWSER_START;
}

export interface ICreateSingleBrowserSuccessAction {
  type: typeof CREATE_SINGLE_BROWSER_SUCCESS;
  currentBrowser: {};
}

export interface ICreateSingleBrowserFailAction {
  type: typeof CREATE_SINGLE_BROWSER_FAIL;
  error: object;
}

export interface IUpdateSingleBrowserStartAction {
  type: typeof UPDATE_SINGLE_BROWSER_START;
}

export interface IUpdateSingleBrowserSuccessAction {
  type: typeof UPDATE_SINGLE_BROWSER_SUCCESS;
  currentBrowser: {};
}

export interface IUpdateSingleBrowserFailAction {
  type: typeof UPDATE_SINGLE_BROWSER_FAIL;
  error: object;
}

export interface IDeleteSingleBrowserStartAction {
  type: typeof DELETE_SINGLE_BROWSER_START;
}

export interface IDeleteSingleBrowserSuccessAction {
  type: typeof DELETE_SINGLE_BROWSER_SUCCESS;
}

export interface IDeleteSingleBrowserFailAction {
  type: typeof DELETE_SINGLE_BROWSER_FAIL;
  error: object;
}

export interface IClearSingleBrowserAction {
  type: typeof CLEAR_SINGLE_BROWSER;
}

export interface IFetchBrowsersStartAction {
  type: typeof FETCH_BROWSERS_START;
}

export interface IFetchBrowsersSuccessAction {
  type: typeof FETCH_BROWSERS_SUCCESS;
  browsersList: [];
  browsersPages: number,
  index: number;
}

export interface IFetchBrowsersFailAction {
  type: typeof FETCH_BROWSERS_FAIL;
  error: object;
}

export interface ISetBrowserListRedirectPath {
  type: typeof SET_BROWSER_LIST_REDIRECT_PATH;
  path: string;
}

export type IBrowserActionTypes = IFetchSingleBrowserStartAction | IFetchSingleBrowserSuccessAction | IFetchSingleBrowserFailAction
  | IFetchBrowsersStartAction | IFetchBrowsersSuccessAction | IFetchBrowsersFailAction | IClearSingleBrowserAction
  | IDeleteSingleBrowserStartAction | IDeleteSingleBrowserSuccessAction | IDeleteSingleBrowserFailAction | ICreateSingleBrowserStartAction
  | ICreateSingleBrowserSuccessAction | ICreateSingleBrowserFailAction | IUpdateSingleBrowserStartAction | IUpdateSingleBrowserSuccessAction
  | IUpdateSingleBrowserFailAction | ISetBrowserListRedirectPath;
