import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleDevice, getDeviceById, updateDevice } from '../../../store/devices';
import DeviceForm from '../DeviceForm/DeviceForm';
import styles from './DeviceEdit.module.css';
import * as _ from 'lodash';
import * as DeviceValidator from '../../../utils/devices/DeviceValidator';

interface IDeviceEditProps {
  token: string;
  device: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  devicesError: any;
  deviceListRedirectPath: string;
  onUpdateDevice(token: string, device: any, id: string): any;
  onGetDeviceById(token: string, id: string): any;
  onClearSingleDevice(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    device: state.devices.currentDevice,
    index: state.devices.index,
    loading: loadingAppSelector(state),
    deviceListRedirectPath: state.devices.deviceListRedirectPath,
    devicesError: state.devices.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateDevice: (token: string, device: any, id: string) => dispatch(updateDevice(token, device, id)),
    onGetDeviceById: (token: string, id: string) => dispatch(getDeviceById(id, token)),
    onClearSingleDevice: () => dispatch(clearSingleDevice()),
  }
};

class DeviceEdit extends React.Component<IDeviceEditProps>{

  async componentDidMount() {
    const { match, onGetDeviceById, token } = this.props;
    await onGetDeviceById(token, match.params.id);
  }

  onSubmitDevice = async (devicePayload: any) => {
    const { onUpdateDevice, device, token, history } = this.props;
    const deviceChanges = DeviceValidator.identifyDeviceChanges(device, devicePayload);
    if (_.isEmpty(deviceChanges)) {
      history.push(`/devices/${device.id}/details`);
      return;
    }
    await onUpdateDevice(token, deviceChanges, device.id);
    const { devicesError } = this.props;
    if (!devicesError) {
      history.push(`/devices/${device.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleDevice } = this.props;
    onClearSingleDevice();
  }

  render() {
    const { device, match, loading, index, deviceListRedirectPath } = this.props;
    let deviceForm = null;
    if (device && device.id.toString() === match.params.id) {
      deviceForm = <DeviceForm onSubmitDevice={this.onSubmitDevice} deviceFromProps={device}  />;
    }
    const deviceListURL = deviceListRedirectPath? deviceListRedirectPath : `/devices?&page=${index? index : 1}`; 

    return (
      <div className={styles.DeviceEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={deviceListURL} exact>
                Devices
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/devices/${match.params.id}/details`} exact>
                {device? device.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/devices/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { deviceForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(DeviceEdit);
