import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { createTestStatus, clearSingleTestStatus } from '../../../store/test_status';
import TestStatusForm from '../TestStatusForm/TestStatusForm';
import styles from './TestStatusCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface ITestStatusCreateProps {
  token: string;
  testStatus: any;
  index: number;
  history: any;
  loading: boolean;
  testStatusListRedirectPath: string;
  onCreateTestStatus(token: string, testStatus: any): any;
  onClearSingleTestStatus(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    testStatus: state.testStatus.current,
    index: state.testStatus.index,
    testStatusListRedirectPath: state.testStatus.listRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateTestStatus: (token: string, testStatus: any) => dispatch(createTestStatus(token, testStatus)),
    onClearSingleTestStatus: () => dispatch(clearSingleTestStatus()),
  }
};

class TestStatusCreate extends React.Component<ITestStatusCreateProps>{
  state: State = initialState;

  onSubmitTestStatus = async (testStatusPayload: any) => {
    const { onCreateTestStatus, token, history } = this.props;
    await onCreateTestStatus(token, testStatusPayload);
    const { testStatus } = this.props;

    if (testStatus) {
      history.push(`/status/${testStatus.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleTestStatus } = this.props;
    onClearSingleTestStatus();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, testStatusListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const testStatusListURL = testStatusListRedirectPath ? testStatusListRedirectPath : `/status?&page=${index ? index : 1}`;

    return (
      <div className={styles.TestStatusCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testStatusListURL} exact>
                Status
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={testStatusListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <TestStatusForm onSubmitTestStatus={this.onSubmitTestStatus} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestStatusCreate);
