export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_LOGIN_REDIRECT_PATH = 'SET_LOGIN_REDIRECT_PATH';

export interface ILoginState {
  token: string | null;
  userId: string | null;
  role: string | null;
  tokenExpirationDate: string | null;
  error: any;
  loading: boolean;
  loginRedirectPath: string;
}

export interface ILoginStartAction {
  type: typeof LOGIN_START;
}

export interface ILoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
  userId: string;
  role: string;
  tokenExpirationDate: string;
}

export interface ILoginFailAction {
  type: typeof LOGIN_FAIL;
  error: object;
}

export interface ILogoutAction {
  type: typeof LOGOUT;
}

export interface ISetLoginRedirectPathAction {
  type: typeof  SET_LOGIN_REDIRECT_PATH;
  path: string;
}

export type ILoginActionTypes = ILoginSuccessAction | ILoginFailAction | ILogoutAction | ISetLoginRedirectPathAction | ILoginStartAction;
