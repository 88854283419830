import * as api from '../../services/api';

import {
  CREATE_SINGLE_TEST_STATUS_FAIL,
  CREATE_SINGLE_TEST_STATUS_START,
  CREATE_SINGLE_TEST_STATUS_SUCCESS,
  // DELETE_SINGLE_BROWSER_FAIL,
  // DELETE_SINGLE_BROWSER_START,
  // DELETE_SINGLE_BROWSER_SUCCESS,
  FETCH_TEST_STATUS_START,
  FETCH_TEST_STATUS_SUCCESS,
  FETCH_TEST_STATUS_FAIL,
  FETCH_SINGLE_TEST_STATUS_START,
  FETCH_SINGLE_TEST_STATUS_SUCCESS,
  FETCH_SINGLE_TEST_STATUS_FAIL,
  CLEAR_SINGLE_TEST_STATUS,
  ITestStatusActionTypes,
  SET_TEST_STATUS_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_TEST_STATUS_FAIL,
  UPDATE_SINGLE_TEST_STATUS_START,
  UPDATE_SINGLE_TEST_STATUS_SUCCESS,
} from './types';

export const fetchSingleTestStatusStart = (): ITestStatusActionTypes => {
    return {
      type: FETCH_SINGLE_TEST_STATUS_START
    };
};

export const fetchSingleTestStatusSuccess = (currentTestStatus: {}): ITestStatusActionTypes => {
    return {
      type: FETCH_SINGLE_TEST_STATUS_SUCCESS,
      currentTestStatus,
    };
};

export const fetchSingleTestStatusFail = (error: Error): ITestStatusActionTypes => {
  return {
    type: FETCH_SINGLE_TEST_STATUS_FAIL,
    error,
  };
};

export const clearSingleTestStatus = (): ITestStatusActionTypes => {
  return {
    type: CLEAR_SINGLE_TEST_STATUS,
  };
};

export const createSingleTestStatusStart = (): ITestStatusActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_STATUS_START
  };
};

export const createSingleTestStatusSuccess = (currentTestStatus: {}): ITestStatusActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_STATUS_SUCCESS,
    currentTestStatus,
  };
};

export const createSingleTestStatusFail = (error: Error): ITestStatusActionTypes => {
  return {
    type: CREATE_SINGLE_TEST_STATUS_FAIL,
    error,
  };
};

export const updateSingleTestStatusStart = (): ITestStatusActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_STATUS_START
  };
};

export const updateSingleTestStatusSuccess = (currentTestStatus: {}): ITestStatusActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_STATUS_SUCCESS,
    currentTestStatus,
  };
};

export const updateSingleTestStatusFail = (error: Error): ITestStatusActionTypes => {
  return {
    type: UPDATE_SINGLE_TEST_STATUS_FAIL,
    error,
  };
};

// export const deleteSingleBrowserStart = (): ITestStatusActionTypes => {
//   return {
//     type: DELETE_SINGLE_BROWSER_START,
//   };
// };

// export const deleteSingleBrowserSuccess = (): ITestStatusActionTypes => {
//   return {
//     type: DELETE_SINGLE_BROWSER_SUCCESS,
//   };
// };

// export const deleteSingleBrowserFail= (error: Error): ITestStatusActionTypes => {
//   return {
//     type: DELETE_SINGLE_BROWSER_FAIL,
//     error
//   };
// };

export const fetchTestStatusStart = (): ITestStatusActionTypes => {
  return {
    type: FETCH_TEST_STATUS_START
  };
};

export const fetchTestStatusSuccess = (list: [], page: number, index: number): ITestStatusActionTypes => {
  return {
    type: FETCH_TEST_STATUS_SUCCESS,
    list,
    page,
    index,
  };
};

export const fetchTestStatusFail = (error: Error): ITestStatusActionTypes => {
  return {
    type: FETCH_TEST_STATUS_FAIL,
    error,
  };
};

export const setTestStatusListRedirectPath = (path: string): ITestStatusActionTypes => {
  return {
    type: SET_TEST_STATUS_LIST_REDIRECT_PATH,
    path
  };
};

export const getTestStatusById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleTestStatusStart());
    return api.getTestStatusById(token, id)
      .then(response => {
        dispatch(fetchSingleTestStatusSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleTestStatusFail(error));
      });
  }
};

// export const deleteBrowserById = (id: string, token: string) => {
//   return (dispatch: any) => {
//     dispatch(deleteSingleBrowserStart());
//      return api.deleteBrowserById(token, id)
//         .then(response => {
//           dispatch(deleteSingleBrowserSuccess());
//         })
//         .catch(error => {
//           dispatch(deleteSingleBrowserFail(error));
//         });
//   }
// };

export const getTestStatus = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchTestStatusStart());
    return api.getTestStatus(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchTestStatusSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchTestStatusFail(error));
      });
  }
};

export const createTestStatus = (token: string, testStatus: any) => {
  return (dispatch: any) => {
    dispatch(createSingleTestStatusStart());
    return api.createTestStatus(token, testStatus)
      .then(response => {
        dispatch(createSingleTestStatusSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleTestStatusFail(error));
      });
  }
};

export const updateTestStatus = (token: string, testStatus: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleTestStatusStart());
    return api.updateTestStatus(token, testStatus, id)
      .then(response => {
        dispatch(updateSingleTestStatusSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleTestStatusFail(error));
      });
  }
};
