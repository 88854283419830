import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleProfile, getProfileById, updateProfile } from '../../../store/profiles';
import ProfileForm from '../ProfileForm/ProfileForm';
import styles from './ProfileEdit.module.css';
import * as _ from 'lodash';
import * as ProfileValidator from '../../../utils/profiles/ProfileValidator';

interface IProfileEditProps {
  token: string;
  profile: any;
  history: any;
  loading: boolean;
  index: number;
  match: any;
  profilesError: any;
  profileListRedirectPath: string;
  onUpdateProfile(token: string, profile: any, id: string): any;
  onGetProfileById(token: string, id: string): any;
  onClearSingleProfile(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    profile: state.profiles.currentProfile,
    index: state.profiles.index,
    loading: loadingAppSelector(state),
    profileListRedirectPath: state.profiles.profileListRedirectPath,
    profilesError: state.profiles.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateProfile: (token: string, profile: any, id: string) => dispatch(updateProfile(token, profile, id)),
    onGetProfileById: (token: string, id: string) => dispatch(getProfileById(id, token)),
    onClearSingleProfile: () => dispatch(clearSingleProfile()),
  }
};

class ProfileEdit extends React.Component<IProfileEditProps>{

  async componentDidMount() {
    const { match, onGetProfileById, token } = this.props;
    await onGetProfileById(token, match.params.id);
  }

  onSubmitProfile = async (profilePayload: any) => {
    const { onUpdateProfile, profile, token, history } = this.props;
    const profileChanges = ProfileValidator.identifyProfileChanges(profile, profilePayload);
    if (_.isEmpty(profileChanges)) {
      history.push(`/profiles/${profile.id}/details`);
      return;
    }
    await onUpdateProfile(token, profileChanges, profile.id);
    const { profilesError } = this.props;
    if (!profilesError) {
      history.push(`/profiles/${profile.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleProfile } = this.props;
    onClearSingleProfile();
  }

  render() {
    const { profile, match, loading, index, profileListRedirectPath } = this.props;
    let profileForm = null;
    if (profile && profile.id.toString() === match.params.id) {
      profileForm = <ProfileForm onSubmitProfile={this.onSubmitProfile} profileFromProps={profile}  />;
    }
    const profileListURL = profileListRedirectPath? profileListRedirectPath : `/profiles?&page=${index? index : 1}`;

    return (
      <div className={styles.ProfileEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={profileListURL} exact>
                Profiles
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/profiles/${match.params.id}/details`} exact>
                {profile? profile.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/profiles/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { profileForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(ProfileEdit);
