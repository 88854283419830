import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Search from '../../../components/UI/Search/Search';
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from '../../../components/UI/Table/Table';
import Can from '../../../components/Can/Can';
import { loadingAppSelector } from '../../../store/global/selector';
import { getUsers, setUserListRedirectPath } from '../../../store/users';
import { setLoginRedirectPath } from '../../../store/login';
import styles from './UserList.module.css';
import { formatUserActive, formatUserType, formatUserRole, IUserActive, IUserTpe, IUserRole, searchByOptions } from '../../../utils/users/Formatters';
import Pagination from '../../../components/UI/Pagination/Pagination';
import * as StringURLHelper from '../../../utils/StringURLHelper';
import { formatOptionalField } from '../../../utils/FieldFormatHelper';
import PaginationItemsSelector from '../../../components/UI/PaginationItemsSelector/PaginationItemsSelector';

interface IUserListProps {
  token: string;
  history: any;
  location: any;
  users: [];
  pages: number;
  index: number;
  loading: boolean;
  onSetLoginRedirectPath(path: string): any;
  onSetUserListRedirectPath(path: string): any;
  onGetUsers(token: string, index: number, items: number, searchBy: any, search: any): any;
}

const UserListHeaders = [
  'ID',
  'Name',
  'Last Name',
  'Email',
  'Role',
  'Type',
  'Active',
];

const createDataRow = (user: any) => {
  return [
    user.id,
    formatOptionalField(user.name),
    formatOptionalField(user.lastName),
    formatOptionalField(user.email),
    formatOptionalField(formatUserRole[user.role as keyof IUserRole]),
    formatOptionalField(formatUserType[user.type as keyof IUserTpe]),
    formatOptionalField(formatUserActive[user.active as keyof IUserActive]),
  ];
};

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    users: state.users.usersList,
    pages: state.users.usersPages,
    index: state.users.index,
    loading: loadingAppSelector(state)
  }
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
    onGetUsers: (token: string, index: number, items: number, searchBy: any, search: any) => dispatch(getUsers(token, index, items, searchBy, search)),
    onSetUserListRedirectPath: (path: string) => dispatch(setUserListRedirectPath(path)),
  }
};

class UserList extends React.Component<IUserListProps> {
  state = {
    users: []
  };

  componentDidMount(): void {
    const { token, onSetLoginRedirectPath, onSetUserListRedirectPath, onGetUsers, location } = this.props;
    onSetLoginRedirectPath('/users');
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    const items = queries.items ? parseInt(queries.items as any, 10) : 10;
    onSetUserListRedirectPath(`/users?${queryString.stringify(queries)}`);
    onGetUsers(token, page, items, queries.searchBy, queries.search);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const { token, onSetLoginRedirectPath, onGetUsers, location, onSetUserListRedirectPath } = this.props;
      onSetLoginRedirectPath('/users');
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      const items = queries.items ? parseInt(queries.items as any, 10) : 10;
      onSetUserListRedirectPath(`/users?${queryString.stringify(queries)}`);
      onGetUsers(token, page, items, queries.searchBy, queries.search);
    }
  } 

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath(`/users/${row[0]}/details`);
    this.props.history.push(`/users/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    this.props.history.push(`/users/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  }

  render() {
    const { users, loading, location, pages, index, history } = this.props;
    const usersRows = users.map(user => {
      return createDataRow(user);
    });
    const spinner = loading? <Spinner type="linear" /> : null;
    const page = index? parseInt(index as any, 10) : 1;
    const addUserIconButton = (
      <div className={styles.AddButton}>
          <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
      </div>
    );
    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Users </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        
        <div className={styles.Table}>
          <div className={`d-flex justify-content-between`}>
            <Search 
              location={location}
              searchByOptions={searchByOptions}
              history={history}
            />
            <PaginationItemsSelector location={location} onPageRedirection={(qStr: string) => this.onPageRedirection(qStr)} />
            <Can
              perform="users:add"
              data={null}
              yes={() => addUserIconButton}
              no={() => null}
            />
          </div>

          <Table headers={UserListHeaders} rows={usersRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(UserList);
