import {
  CLEAR_SINGLE_BROWSER,
  CREATE_SINGLE_BROWSER_FAIL,
  CREATE_SINGLE_BROWSER_START,
  CREATE_SINGLE_BROWSER_SUCCESS,
  DELETE_SINGLE_BROWSER_FAIL,
  DELETE_SINGLE_BROWSER_START,
  DELETE_SINGLE_BROWSER_SUCCESS,
  FETCH_BROWSERS_FAIL,
  FETCH_BROWSERS_START,
  FETCH_BROWSERS_SUCCESS,
  FETCH_SINGLE_BROWSER_FAIL,
  FETCH_SINGLE_BROWSER_START,
  FETCH_SINGLE_BROWSER_SUCCESS,
  IBrowserActionTypes,
  IBrowserState,
  IClearSingleBrowserAction,
  ICreateSingleBrowserFailAction,
  ICreateSingleBrowserStartAction,
  ICreateSingleBrowserSuccessAction,
  IDeleteSingleBrowserFailAction,
  IDeleteSingleBrowserStartAction,
  IDeleteSingleBrowserSuccessAction,
  IFetchBrowsersFailAction,
  IFetchBrowsersStartAction,
  IFetchBrowsersSuccessAction,
  IFetchSingleBrowserFailAction,
  IFetchSingleBrowserStartAction,
  IFetchSingleBrowserSuccessAction,
  ISetBrowserListRedirectPath,
  IUpdateSingleBrowserFailAction,
  IUpdateSingleBrowserStartAction,
  IUpdateSingleBrowserSuccessAction,
  SET_BROWSER_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_BROWSER_FAIL,
  UPDATE_SINGLE_BROWSER_START,
  UPDATE_SINGLE_BROWSER_SUCCESS,
} from './types';

const initialState: IBrowserState = {
  currentBrowser: null,
  browsersList: [],
  browsersPages: 0,
  index: 0,
  browserListRedirectPath: null,
  error: null,
  loading: false,
};

const fetchSingleBrowserStart = (state: IBrowserState, action: IFetchSingleBrowserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchSingleBrowserSuccess = (state: IBrowserState, action: IFetchSingleBrowserSuccessAction) => {
  return {
    ...state,
    currentBrowser: action.currentBrowser,
    error: null,
    loading: false
  };
};

const fetchSingleBrowserFail = (state: IBrowserState, action: IFetchSingleBrowserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const clearSingleBrowser = (state: IBrowserState, action: IClearSingleBrowserAction) => {
  return {
    ...state,
    currentBrowser: null
  };
};

const createSingleBrowserStart = (state: IBrowserState, action: ICreateSingleBrowserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const createSingleBrowserSuccess = (state: IBrowserState, action: ICreateSingleBrowserSuccessAction) => {
  return {
    ...state,
    currentBrowser: action.currentBrowser,
    error: null,
    loading: false
  };
};

const createSingleBrowserFail = (state: IBrowserState, action: ICreateSingleBrowserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const updateSingleBrowserStart = (state: IBrowserState, action: IUpdateSingleBrowserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const updateSingleBrowserSuccess = (state: IBrowserState, action: IUpdateSingleBrowserSuccessAction) => {
  return {
    ...state,
    currentBrowser: action.currentBrowser,
    error: null,
    loading: false
  };
};

const updateSingleBrowserFail = (state: IBrowserState, action: IUpdateSingleBrowserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};


const deleteSingleBrowserStart = (state: IBrowserState, action: IDeleteSingleBrowserStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const deleteSingleBrowserSuccess = (state: IBrowserState, action: IDeleteSingleBrowserSuccessAction) => {
  return {
    ...state,
    currentBrowser: null,
    error: null,
    loading: false
  };
};

const deleteSingleBrowserFail = (state: IBrowserState, action: IDeleteSingleBrowserFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const fetchBrowsersStart = (state: IBrowserState, action: IFetchBrowsersStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const fetchBrowsersSuccess = (state: IBrowserState, action: IFetchBrowsersSuccessAction) => {
  return {
    ...state,
    browsersList: action.browsersList,
    error: null,
    loading: false,
    browsersPages: action.browsersPages,
    index: action.index,
  };
};

const fetchBrowsersFail = (state: IBrowserState, action: IFetchBrowsersFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const setBrowserListRedirectPath = (state: IBrowserState, action: ISetBrowserListRedirectPath) => {
  return {
    ...state,
    browserListRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: IBrowserActionTypes): IBrowserState => {
  switch ( action.type ) {
    case FETCH_SINGLE_BROWSER_START: return fetchSingleBrowserStart(state, action);
    case FETCH_SINGLE_BROWSER_SUCCESS: return fetchSingleBrowserSuccess(state, action);
    case FETCH_SINGLE_BROWSER_FAIL: return fetchSingleBrowserFail(state, action);
    case CREATE_SINGLE_BROWSER_START: return createSingleBrowserStart(state, action);
    case CREATE_SINGLE_BROWSER_SUCCESS: return createSingleBrowserSuccess(state, action);
    case CREATE_SINGLE_BROWSER_FAIL: return createSingleBrowserFail(state, action);
    case UPDATE_SINGLE_BROWSER_START: return updateSingleBrowserStart(state, action);
    case UPDATE_SINGLE_BROWSER_SUCCESS: return updateSingleBrowserSuccess(state, action);
    case UPDATE_SINGLE_BROWSER_FAIL: return updateSingleBrowserFail(state, action);
    case DELETE_SINGLE_BROWSER_START: return deleteSingleBrowserStart(state, action);
    case DELETE_SINGLE_BROWSER_SUCCESS: return deleteSingleBrowserSuccess(state, action);
    case DELETE_SINGLE_BROWSER_FAIL: return deleteSingleBrowserFail(state, action);
    case CLEAR_SINGLE_BROWSER: return clearSingleBrowser(state, action);
    case FETCH_BROWSERS_START: return fetchBrowsersStart(state, action);
    case FETCH_BROWSERS_SUCCESS: return fetchBrowsersSuccess(state, action);
    case FETCH_BROWSERS_FAIL: return fetchBrowsersFail(state, action);
    case SET_BROWSER_LIST_REDIRECT_PATH: return setBrowserListRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
