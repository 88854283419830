export const FETCH_SINGLE_TEST_CASE_START = 'FETCH_SINGLE_TEST_CASE_START';
export const FETCH_SINGLE_TEST_CASE_SUCCESS = 'FETCH_SINGLE_TEST_CASE_SUCCESS';
export const FETCH_SINGLE_TEST_CASE_FAIL = 'FETCH_SINGLE_TEST_CASE_FAIL';
export const CLEAR_SINGLE_TEST_CASE = 'CLEAR_SINGLE_TEST_CASE';

export const FETCH_TEST_CASES_START = 'FETCH_TEST_CASES_START';
export const FETCH_TEST_CASES_SUCCESS = 'FETCH_TEST_CASES_SUCCESS';
export const FETCH_TEST_CASES_FAIL = 'FETCH_TEST_CASES_FAIL';

export const CREATE_SINGLE_TEST_CASE_START = 'CREATE_SINGLE_TEST_CASE_START';
export const CREATE_SINGLE_TEST_CASE_SUCCESS = 'CREATE_SINGLE_TEST_CASE_SUCCESS';
export const CREATE_SINGLE_TEST_CASE_FAIL = 'CREATE_SINGLE_TEST_CASE_FAIL';

export const UPDATE_SINGLE_TEST_CASE_START = 'UPDATE_SINGLE_TEST_CASE_START';
export const UPDATE_SINGLE_TEST_CASE_SUCCESS = 'UPDATE_SINGLE_TEST_CASE_SUCCESS';
export const UPDATE_SINGLE_TEST_CASE_FAIL = 'UPDATE_SINGLE_TEST_CASE_FAIL';

export const DELETE_SINGLE_TEST_CASE_START = 'DELETE_SINGLE_TEST_CASE_START';
export const DELETE_SINGLE_TEST_CASE_SUCCESS = 'DELETE_SINGLE_TEST_CASE_SUCCESS';
export const DELETE_SINGLE_TEST_CASE_FAIL = 'DELETE_SINGLE_TEST_CASE_FAIL';

export const SET_TEST_CASE_LIST_REDIRECT_PATH = 'SET_TEST_CASE_LIST_REDIRECT_PATH';

export interface ITestCaseState {
  currentTestCase: {} | null;
  testCasesList: [],
  testCaseListRedirectPath: string | null,
  testCasesPages: number,
  index: number,
  error: any;
  loading: boolean;
}

export interface IFetchSingleTestCaseStartAction {
  type: typeof FETCH_SINGLE_TEST_CASE_START;
}

export interface IFetchSingleTestCaseSuccessAction {
  type: typeof FETCH_SINGLE_TEST_CASE_SUCCESS;
  currentTestCase: {};
}

export interface IFetchSingleTestCaseFailAction {
  type: typeof FETCH_SINGLE_TEST_CASE_FAIL;
  error: object;
}

export interface ICreateSingleTestCaseStartAction {
  type: typeof CREATE_SINGLE_TEST_CASE_START;
}

export interface ICreateSingleTestCaseSuccessAction {
  type: typeof CREATE_SINGLE_TEST_CASE_SUCCESS;
  currentTestCase: {};
}

export interface ICreateSingleTestCaseFailAction {
  type: typeof CREATE_SINGLE_TEST_CASE_FAIL;
  error: object;
}

export interface IUpdateSingleTestCaseStartAction {
  type: typeof UPDATE_SINGLE_TEST_CASE_START;
}

export interface IUpdateSingleTestCaseSuccessAction {
  type: typeof UPDATE_SINGLE_TEST_CASE_SUCCESS;
  currentTestCase: {};
}

export interface IUpdateSingleTestCaseFailAction {
  type: typeof UPDATE_SINGLE_TEST_CASE_FAIL;
  error: object;
}

export interface IDeleteSingleTestCaseStartAction {
  type: typeof DELETE_SINGLE_TEST_CASE_START;
}

export interface IDeleteSingleTestCaseSuccessAction {
  type: typeof DELETE_SINGLE_TEST_CASE_SUCCESS;
}

export interface IDeleteSingleTestCaseFailAction {
  type: typeof DELETE_SINGLE_TEST_CASE_FAIL;
  error: object;
}

export interface IClearSingleTestCaseAction {
  type: typeof CLEAR_SINGLE_TEST_CASE;
}

export interface IFetchTestCasesStartAction {
  type: typeof FETCH_TEST_CASES_START;
}

export interface IFetchTestCasesSuccessAction {
  type: typeof FETCH_TEST_CASES_SUCCESS;
  testCasesList: [];
  testCasesPages: number,
  index: number;
}

export interface IFetchTestCasesFailAction {
  type: typeof FETCH_TEST_CASES_FAIL;
  error: object;
}

export interface ISetTestCaseListRedirectPath {
  type: typeof SET_TEST_CASE_LIST_REDIRECT_PATH;
  path: string;
}

export type ITestCaseActionTypes = IFetchSingleTestCaseStartAction | IFetchSingleTestCaseSuccessAction | IFetchSingleTestCaseFailAction
  | IFetchTestCasesStartAction | IFetchTestCasesSuccessAction | IFetchTestCasesFailAction | IClearSingleTestCaseAction
  | IDeleteSingleTestCaseStartAction | IDeleteSingleTestCaseSuccessAction | IDeleteSingleTestCaseFailAction | ICreateSingleTestCaseStartAction
  | ICreateSingleTestCaseSuccessAction | ICreateSingleTestCaseFailAction | IUpdateSingleTestCaseStartAction | IUpdateSingleTestCaseSuccessAction
  | IUpdateSingleTestCaseFailAction | ISetTestCaseListRedirectPath;
