
export const formatEnvironmentActive = {
  'true': 'Yes',
  'false': 'No'
};

export type IEnvironmentActive = typeof formatEnvironmentActive

export const searchByOptions = [
  {
    label: 'ID',
    value: 'id'
  },
  {
    label: 'Description',
    value: 'description'
  },
];
