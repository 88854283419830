import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleUser, createUser } from '../../../store/users';
import UserForm from '../UserForm/UserForm';
import styles from './UserCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IUserCreateProps {
  token: string;
  user: any;
  index: number;
  history: any;
  loading: boolean;
  userListRedirectPath: string;
  onCreateUser(token: string, user: any): any;
  onClearSingleUser(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    index: state.users.index,
    user: state.users.currentUser,
    userListRedirectPath: state.users.userListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateUser: (token: string, user: any) => dispatch(createUser(token, user)),
    onClearSingleUser: () => dispatch(clearSingleUser()),
  }
};

class UserCreate extends React.Component<IUserCreateProps>{
  state: State = initialState;

  onSubmitUser = async (userPayload: any) => {
    const { onCreateUser, token, history } = this.props;
    if (userPayload.password !== userPayload.confirmPassword) {
      this.setState({
        ...this.state,
        showModal: true,
        modalMessage: `Passwords don't match, please check!`,
      });
      return;
    }
    await onCreateUser(token, userPayload);
    const { user } = this.props;
    if (user) {
      history.push(`/users/${user.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleUser } = this.props;
    onClearSingleUser();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, userListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const userListURL = userListRedirectPath? userListRedirectPath : `/users?&page=${index? index : 1}`;
    
    return (
      <div className={styles.UserCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={userListURL} exact>
                Users
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={userListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <UserForm onSubmitUser={this.onSubmitUser} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(UserCreate);
