import axios from '../axios-instance';

export const getEnvironments = (token: string, index: number, items: number, searchBy: any, search: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  let path = '/environments';
  if (index !== -1) {
    path += `?items=${items? items: 10}&index=${index}`;
    path += search?  `&search=${search}` : '';
  }
  return axios.get(path, options);
};

export const getEnvironmentById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.get(`/environments/${id}`, options);
};

export const createEnvironment = (token: string, environment: any) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.post('/environments/', environment, options);
};

export const updateEnvironment = (token: string, environment: any, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };
  return axios.put(`/environments/${id}`, environment, options);
};

export const deleteEnvironmentById = (token: string, id: string) => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`
    },
  };

  return axios.delete(`/environments/${id}`, options);
};
