import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleUser, getUserById, updateUser } from '../../../store/users';
import PasswordChangeForm from './PasswordChangeForm/PasswordChangeForm';
import styles from './PasswordChange.module.css';
import * as _ from 'lodash';
import * as UserValidator from '../../../utils/users/UserValidator';

interface IPasswordChangeProps {
  token: string;
  user: any;
  history: any;
  loading: boolean;
  match: any;
  index: number;
  usersError: any;
  userListRedirectPath: string;
  onUpdateUser(token: string, user: any, id: string): any;
  onGetUserById(token: string, id: string): any;
  onClearSingleUser(): any;
}

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    user: state.users.currentUser,
    index: state.users.index,
    userListRedirectPath: state.users.userListRedirectPath,
    loading: loadingAppSelector(state),
    usersError: state.users.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateUser: (token: string, user: any, id: string) => dispatch(updateUser(token, user, id)),
    onGetUserById: (token: string, id: string) => dispatch(getUserById(id, token)),
    onClearSingleUser: () => dispatch(clearSingleUser()),
  }
};

class PasswordChange extends React.Component<IPasswordChangeProps>{
  state: State = initialState;

  async componentDidMount() {
    const { match, onGetUserById, token } = this.props;
    await onGetUserById(token, match.params.id);
  }

  onSubmitUser = async (userPayload: any) => {
    const { onUpdateUser, user, token, history } = this.props;
    if (userPayload.password !== userPayload.confirmPassword) {
      this.setState({
        ...this.state,
        showModal: true,
        modalMessage: `Passwords don't match, please check!`,
      });
      return;
    }
    const userChanges = UserValidator.identifyPasswordChange(user, userPayload);
    if (_.isEmpty(userChanges)) {
      history.push(`/users/${user.id}/details`);
      return;
    }
    await onUpdateUser(token, userChanges, user.id);
    const { usersError } = this.props;
    if (!usersError) {
      history.push(`/users/${user.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleUser } = this.props;
    onClearSingleUser();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { user, match, loading, userListRedirectPath, index } = this.props;
    const { showModal, modalMessage } = this.state;
    let passwordChangeForm = null;
    if (user && user.id.toString() === match.params.id) {
      passwordChangeForm = <PasswordChangeForm onSubmitUser={this.onSubmitUser} userFromProps={user}  />;
    }
    const userListURL = userListRedirectPath? userListRedirectPath : `/users?&page=${index? index : 1}`;

    return (
      <div className={styles.PasswordChange}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={userListURL} exact>
                Users
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/users/${match.params.id}/details`} exact>
                {user? user.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Change Password </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/users/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { passwordChangeForm }
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(PasswordChange);
