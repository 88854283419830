
export const formatProfileActive = {
  'true': 'Yes',
  'false': 'No'
};

export type IProfileActive = typeof formatProfileActive

export const searchByOptions = [
  {
    label: 'ID',
    value: 'profile.id'
  },
  {
    label: 'Email',
    value: 'profile.email'
  },
  {
    label: 'Description',
    value: 'profile.description'
  },
  {
    label: 'Account',
    value: 'account.description'
  },
  {
    label: 'User Name',
    value: 'user.name'
  },
  {
    label: 'User Last Name',
    value: 'user.lastName'
  },
];
