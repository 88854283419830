import * as React from 'react';
import Button from '../../components/UI/Button/Button'
import Modal from '../../components/UI/Modal/Modal';
import styles from './withErrorHandling.module.css';

interface IWithErrorHandlingState {
  error: any;
}

interface IWithErrorHandlingProps {
  history: any;
  isAuthenticated: boolean;
  redirectPath: string;
  onLogout(): any;
}

const withErrorHandling = <P extends object>(Component: React.ComponentType<P>, axios: any) => {
  return class WithErrorHandling extends React.Component<P & IWithErrorHandlingProps> {
    state: IWithErrorHandlingState = {
      error: null
    };
    private reqInterceptor: any;
    private resInterceptor: any;

    componentWillMount () {
      this.reqInterceptor = axios.interceptors.request.use( (req: any) => {
        this.setState( { error: null } );
        return req;
      } );
      this.resInterceptor = axios.interceptors.response.use( (res: any) => res, (error: any) => {
        const { isAuthenticated } = this.props;
        if (error.response && error.response.status === 401 && isAuthenticated) {
          const { onLogout } = this.props;
          onLogout();
          return;
        }
        this.setState( { error } );
      } );
    }

    componentWillUnmount () {
      axios.interceptors.request.eject( this.reqInterceptor );
      axios.interceptors.response.eject( this.resInterceptor );
    }

    errorConfirmedHandler = () => {
      const { history, redirectPath} = this.props;
      const { error } = this.state;
      if ( error && (!error.response || (error.response.status !== 400 && error.response.status !== 401 && error.response.status !== 409))) {
        history.push(redirectPath);
      }
      this.setState( { error: null } );
    };

    render() {
      const { ...props } = this.props;
      const { error } = this.state;
      let errorContent = null;
      let errorMessage = 'Network Error. Please contact your system administrator.';
      if (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          if (error.response.data.errors.Conflict) {
            errorMessage = error.response.data.errors.Conflict;
          }
          if (error.response.data.errors.NotFound) {
            errorMessage = error.response.data.errors.NotFound;
          }
          if (error.response.data.errors.Forbidden) {
            errorMessage = error.response.data.errors.Forbidden;
          }
          if (error.response.data.errors.InternalError) {
            errorMessage = error.response.data.errors.InternalError;
          }
        }
        if (error.response && error.response.status === 401) {
          errorMessage = 'Invalid Credentials';
        }
        errorContent = (
          <div className="container text-center" style={{width: '300px'}}>
            {errorMessage}
            <div className={styles.CloseButton}>
              <Button type="button" color="danger" onClick={this.errorConfirmedHandler} autoFocus> Got it </Button>
            </div>
          </div>
        );
      }

      return (
        <>
          <Modal
            open={error !== null}
            onToggle={this.errorConfirmedHandler}>
            {errorContent}
          </Modal>
          <Component {...props} />
        </>
      );
    }
  }
};

export default withErrorHandling;
