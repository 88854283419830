import * as React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Search from '../../../components/UI/Search/Search';
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from '../../../components/UI/Table/Table';
import { loadingAppSelector } from '../../../store/global/selector';
import { getAccounts, setAccountListRedirectPath } from '../../../store/accounts';
import { setLoginRedirectPath } from '../../../store/login';
import styles from './AccountList.module.css';
import { formatAccountActive, IAccountActive, searchByOptions } from '../../../utils/accounts/Formatters';
import Pagination from '../../../components/UI/Pagination/Pagination';
import * as StringURLHelper from '../../../utils/StringURLHelper';
import { formatOptionalField } from '../../../utils/FieldFormatHelper';
import PaginationItemsSelector from '../../../components/UI/PaginationItemsSelector/PaginationItemsSelector';

interface IAccountListProps {
  token: string;
  history: any;
  location: any;
  accounts: [];
  pages: number;
  index: number;
  loading: boolean;
  onSetLoginRedirectPath(path: string): any;
  onSetAccountListRedirectPath(path: string): any;
  onGetAccounts(token: string, index: number, items: number, searchBy: any, search: any): any;
}

const AccountListHeaders = [
  'ID',
  'Description',
  'Domain',
  'Active',
];

const createDataRow = (account: any) => {
  return [
    account.id,
    account.description,
    formatOptionalField(account.domainName),
    formatAccountActive[account.active as keyof IAccountActive]
  ];
};

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    accounts: state.accounts.accountsList,
    pages: state.accounts.accountsPages,
    index: state.accounts.index,
    loading: loadingAppSelector(state)
  }
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onSetLoginRedirectPath: (path: string) => dispatch(setLoginRedirectPath(path)),
    onGetAccounts: (token: string, index: number, items: number, searchBy: any, search: any) => dispatch(getAccounts(token, index, items, searchBy, search)),
    onSetAccountListRedirectPath: (path: string) => dispatch(setAccountListRedirectPath(path)),          
  }
};

class AccountList extends React.Component<IAccountListProps> {
  state = {
    accounts: []
  };

  componentDidMount(): void {
    const { token, onSetLoginRedirectPath, onGetAccounts, location, onSetAccountListRedirectPath } = this.props;
    onSetLoginRedirectPath('/accounts');
    const queries = queryString.parse(location.search);
    const page = queries.page ? parseInt(queries.page as any, 10) : 1;
    const items = queries.items ? parseInt(queries.items as any, 10) : 10;
    onSetAccountListRedirectPath(`/accounts?${queryString.stringify(queries)}`);
    onGetAccounts(token, page, items, queries.searchBy, queries.search);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.location !== prevProps.location) {
      const { token, onSetLoginRedirectPath, onGetAccounts, location, onSetAccountListRedirectPath } = this.props;
      onSetLoginRedirectPath('/accounts');
      const queries = queryString.parse(location.search);
      const page = queries.page ? parseInt(queries.page as any, 10) : 1;
      const items = queries.items ? parseInt(queries.items as any, 10) : 10;
      onSetAccountListRedirectPath(`/accounts?${queryString.stringify(queries)}`);
      onGetAccounts(token, page, items, queries.searchBy, queries.search);
    }
  } 

  onRowClickHandler = (row: any) => {
    const { onSetLoginRedirectPath } = this.props;
    onSetLoginRedirectPath(`/accounts/${row[0]}/details`);
    this.props.history.push(`/accounts/${row[0]}/details`);
  };

  onAddButtonClickHandler = () => {
    this.props.history.push(`/accounts/create`);
  };

  onPageRedirection = (str: string) => {
    this.props.history.push(`?${str}`);
  }

  render() {
    const { accounts, loading, location, pages, index, history} = this.props;
    const accountsRows = accounts.map(account => {
      return createDataRow(account);
    });
    const spinner = loading? <Spinner type="linear" /> : null;
    const page = index? parseInt(index as any, 10) : 1;
    return (
      <>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
              <Breadcrumb.Section>
                <NavigationItem link="/" exact>
                  Home
                </NavigationItem>
              </Breadcrumb.Section>
              <Breadcrumb.Divider> / </Breadcrumb.Divider>
              <Breadcrumb.Section active> Accounts </Breadcrumb.Section>
            </Breadcrumb>
        </div>
        <div className={styles.Table}>
          <div className={`d-flex justify-content-between`}>
            <Search 
              location={location}
              searchByOptions={searchByOptions}
              history={history}
            />
            <PaginationItemsSelector location={location} onPageRedirection={(qStr: string) => this.onPageRedirection(qStr)} />
            <div className={styles.AddButton}>
              <IconButton color="primary" type="add" fabSize="medium" disabled={loading} onClick={() => this.onAddButtonClickHandler()}/>
            </div>
          </div>

          <Table headers={AccountListHeaders} rows={accountsRows} onRowClick={this.onRowClickHandler} />
          <Pagination totalPages={pages} currentPage={page} onPageSelection={(key: string, value: string) => this.onPageRedirection(StringURLHelper.appendToURl(location, [{key, value}]))}/>
          <div className={styles.Spinner}>
            {spinner}
          </div>
        </div>
      </>
    );
  }
}

export default connect(matchStateToProps, matchDispatchToProps)(AccountList);
