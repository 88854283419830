import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import styles from './NavigationItem.module.css';

interface INavigationItem {
  link: string;
  exact?: boolean;
  position?: 'left' | 'right';
  loading: boolean;
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.login.loading,
  };
};

const navigationItem: React.FunctionComponent<INavigationItem> = (props) => {
  const { link, exact, position, loading } = props;
  const menuItem = (
    <Menu.Item position={position} className={styles.menuItem} as="span">
      {props.children}
    </Menu.Item>
  );
  return loading? menuItem : (
    <NavLink
    className={`${styles.navItem} ${position === 'right'? styles.rightItem : null}`}
    to={link}
    exact={exact}>
      {menuItem}
    </NavLink>
  );
};

export default connect(mapStateToProps,null)(navigationItem);
