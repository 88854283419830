interface ITestCaseUpdateData {
  description?: string;
  status?: boolean;
}

export const identifyTestCaseChanges = (prevTestCase: any, newTestCase: any) => {
  const changes: ITestCaseUpdateData = {};
  if (newTestCase.description && (prevTestCase.description !== newTestCase.description)) {
    changes.description = newTestCase.description;
  }

  if (prevTestCase.status !== newTestCase.status) {
    changes.status = newTestCase.status;
  }
  return changes;
}