import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../../components/UI/Button/Button';
import DescriptionList from '../../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import Modal from '../../../../components/UI/Modal/Modal';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../../store/global/selector';
import { clearSingleDefect, deleteDefectById, getDefectById } from '../../../../store/defects';
import styles from './DefectDetails.module.css';
import * as _ from 'lodash';
import { getTestReportById } from '../../../../store/test_reports';
import TestStatusValues from '../../../../utils/test_status/TestStatusValues';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IDefectDetailsProps {
  currentTestReport: any;
  token: string;
  match: any;
  defect: any;
  index: number;
  testReportIndex: number;
  location: any;
  history: any;
  loading: boolean;
  defectListRedirectPath: any;
  testReportListRedirectPath: any;
  onGetDefectById(id: string, testReportId: string, token: string): any;
  onClearSingleDefect(): any;
  onDeleteDefectById(id: string, testReportId: string, token: string): any;
  onGetTestReportById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    currentTestReport: state.testReports.currentTestReport,
    token: state.login.token,
    defect: state.defects.currentDefect,
    index: state.defects.index,
    testReportIndex: state.testReports.index,
    defectListRedirectPath: state.defects.defectListRedirectPath,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetDefectById: (id: string, testReportId: string, token: string) => dispatch(getDefectById(id, testReportId, token)),
    onClearSingleDefect: () => dispatch(clearSingleDefect()),
    onDeleteDefectById: (id: string, testReportId: string, token: string) => dispatch(deleteDefectById(id, testReportId, token)),
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
  }
};
const formatOptionalField = (field: any) => !(_.isNull(field) || _.isUndefined(field))? field : '-'; 

const formatDefectDetails = (defect: any) => {
  return {
    ID: defect.id,
    Description: formatOptionalField(defect.description),
    'Severity': formatOptionalField(defect.severity),
  }
};

class DefectDetails extends React.Component<IDefectDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetDefectById, onGetTestReportById } = this.props;
    onGetDefectById(match.params.id, match.params.testReportId, token);
    onGetTestReportById(match.params.testReportId, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      const { token, match, onGetDefectById, onGetTestReportById } = this.props;
      onGetDefectById(match.params.id, match.params.testReportId, token);
      onGetTestReportById(match.params.testReportId, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleDefect } = this.props;
    onClearSingleDefect();
  }

  onEditButtonClickHandler = () => {
    const { history, defect, match } = this.props;
    history.push(`/testReports/${match.params.testReportId}/defects/${defect.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteDefectById, defect, token, history, match } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteDefectById(defect.id, match.params.testReportId, token);
    history.push(`/testReports/${match.params.testReportId}/defects`);
  };

  render() {
    const { defect, match, loading, index, testReportIndex, testReportListRedirectPath, defectListRedirectPath, currentTestReport } = this.props;
    const { showModal } = this.state;
    const defectDetails = defect? <DescriptionList entity={formatDefectDetails(defect)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${testReportIndex? testReportIndex : 1}`;
    const defectListURL = defectListRedirectPath? defectListRedirectPath : `/testReports/${match.params.testReportId}/defects?&page=${index? index : 1}`;

    return (
      <div className={styles.DefectDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link="/" exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={testReportListURL} exact>
              Test Reports
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section> 
            <NavigationItem link={`/testReports/${match.params.testReportId}/details`} exact>
              {match.params.testReportId} 
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={defectListURL} exact>
              Defects
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={defectListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          {
            currentTestReport && currentTestReport.testStatusId !== TestStatusValues.ARCHIVED && (
              <div className='d-flex justify-content-between'>
                <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
                <div className={styles.Divider}/>
                <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} />
              </div>
            )
          }
        </div>
        <div className={styles.Details}>
          <div className={styles.DefectDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {defectDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this defect?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(DefectDetails);
