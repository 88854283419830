import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleTestReport, getTestReportById, updateTestReport } from '../../../store/test_reports';
import TestReportForm from '../TestReportForm/TestReportForm';
import styles from './TestReportEdit.module.css';
import * as _ from 'lodash';
import * as TestReportValidator from '../../../utils/test_reports/TestReportValidator';
import TestStatusValues from '../../../utils/test_status/TestStatusValues';

interface ITestReportEditProps {
  token: string;
  testReport: any;
  index: number;
  history: any;
  loading: boolean;
  match: any;
  testReportsError: any;
  testReportListRedirectPath: string;
  onUpdateTestReport(token: string, testReport: any, id: string): any;
  onGetTestReportById(token: string, id: string): any;
  onClearSingleTestReport(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    testReport: state.testReports.currentTestReport,
    index: state.testReports.index,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    loading: loadingAppSelector(state),
    testReportsError: state.testReports.error,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onUpdateTestReport: (token: string, testReport: any, id: string) => dispatch(updateTestReport(token, testReport, id)),
    onGetTestReportById: (token: string, id: string) => dispatch(getTestReportById(id, token)),
    onClearSingleTestReport: () => dispatch(clearSingleTestReport()),
  }
};

class TestReportEdit extends React.Component<ITestReportEditProps>{

  async componentDidMount() {
    // eslint-disable-next-line
    const { match, onGetTestReportById, token, testReport } = this.props;
    await onGetTestReportById(token, match.params.id);
  }

  onSubmitTestReport = async (testReportPayload: any) => {
    const { onUpdateTestReport, testReport, token, history } = this.props;
    const testReportChanges = TestReportValidator.identifyTestReportChanges(testReport, testReportPayload);
    if (_.isEmpty(testReportChanges)) {
      history.push(`/testReports/${testReport.id}/details`);
      return;
    }
    await onUpdateTestReport(token, testReportChanges, testReport.id);
    const { testReportsError } = this.props;
    if (!testReportsError) {
      history.push(`/testReports/${testReport.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleTestReport } = this.props;
    onClearSingleTestReport();
  }

  componentDidUpdate() {
    const { history, testReport } = this.props;

    if(testReport && testReport.testStatusId === TestStatusValues.ARCHIVED) {
      history.push(`/testReports/${testReport.id}/details`);
    }
  }

  render() {
    // eslint-disable-next-line
    const { testReport, match, loading, index, testReportListRedirectPath, history } = this.props;
    let testReportForm = null;
    if (testReport && testReport.id.toString() === match.params.id) {
      testReportForm = <TestReportForm onSubmitTestReport={this.onSubmitTestReport} testReportFromProps={testReport}  />;
    }
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${index? index : 1}`;

    return (
      <div className={styles.TestReportEdit}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testReportListURL} exact>
                Test Reports
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={`/testReports/${match.params.id}/details`} exact>
                {testReport? testReport.id: null}
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Edit </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={`/testReports/${match.params.id}/details`} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        { testReportForm }
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestReportEdit);
