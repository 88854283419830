import * as api from '../../services/api';

import {
  CLEAR_SINGLE_DEVICE,
  CREATE_SINGLE_DEVICE_FAIL,
  CREATE_SINGLE_DEVICE_START,
  CREATE_SINGLE_DEVICE_SUCCESS,
  DELETE_SINGLE_DEVICE_FAIL,
  DELETE_SINGLE_DEVICE_START,
  DELETE_SINGLE_DEVICE_SUCCESS,
  FETCH_DEVICES_FAIL,
  FETCH_DEVICES_START,
  FETCH_DEVICES_SUCCESS,
  FETCH_SINGLE_DEVICE_FAIL,
  FETCH_SINGLE_DEVICE_START,
  FETCH_SINGLE_DEVICE_SUCCESS,
  IDeviceActionTypes,
  SET_DEVICE_LIST_REDIRECT_PATH,
  UPDATE_SINGLE_DEVICE_FAIL,
  UPDATE_SINGLE_DEVICE_START,
  UPDATE_SINGLE_DEVICE_SUCCESS,
} from './types';

export const fetchSingleDeviceStart = (): IDeviceActionTypes => {
    return {
      type: FETCH_SINGLE_DEVICE_START
    };
};

export const fetchSingleDeviceSuccess = (currentDevice: {}): IDeviceActionTypes => {
    return {
      type: FETCH_SINGLE_DEVICE_SUCCESS,
      currentDevice,
    };
};

export const fetchSingleDeviceFail = (error: Error): IDeviceActionTypes => {
  return {
    type: FETCH_SINGLE_DEVICE_FAIL,
    error,
  };
};

export const clearSingleDevice = (): IDeviceActionTypes => {
  return {
    type: CLEAR_SINGLE_DEVICE,
  };
};

export const createSingleDeviceStart = (): IDeviceActionTypes => {
  return {
    type: CREATE_SINGLE_DEVICE_START
  };
};

export const createSingleDeviceSuccess = (currentDevice: {}): IDeviceActionTypes => {
  return {
    type: CREATE_SINGLE_DEVICE_SUCCESS,
    currentDevice,
  };
};

export const createSingleDeviceFail = (error: Error): IDeviceActionTypes => {
  return {
    type: CREATE_SINGLE_DEVICE_FAIL,
    error,
  };
};

export const updateSingleDeviceStart = (): IDeviceActionTypes => {
  return {
    type: UPDATE_SINGLE_DEVICE_START
  };
};

export const updateSingleDeviceSuccess = (currentDevice: {}): IDeviceActionTypes => {
  return {
    type: UPDATE_SINGLE_DEVICE_SUCCESS,
    currentDevice,
  };
};

export const updateSingleDeviceFail = (error: Error): IDeviceActionTypes => {
  return {
    type: UPDATE_SINGLE_DEVICE_FAIL,
    error,
  };
};

export const deleteSingleDeviceStart = (): IDeviceActionTypes => {
  return {
    type: DELETE_SINGLE_DEVICE_START,
  };
};

export const deleteSingleDeviceSuccess = (): IDeviceActionTypes => {
  return {
    type: DELETE_SINGLE_DEVICE_SUCCESS,
  };
};

export const deleteSingleDeviceFail= (error: Error): IDeviceActionTypes => {
  return {
    type: DELETE_SINGLE_DEVICE_FAIL,
    error
  };
};

export const fetchDevicesStart = (): IDeviceActionTypes => {
  return {
    type: FETCH_DEVICES_START
  };
};

export const fetchDevicesSuccess = (devicesList: [], devicesPages: number, index: number): IDeviceActionTypes => {
  return {
    type: FETCH_DEVICES_SUCCESS,
    devicesList,
    devicesPages,
    index,
  };
};

export const fetchDevicesFail = (error: Error): IDeviceActionTypes => {
  return {
    type: FETCH_DEVICES_FAIL,
    error,
  };
};

export const setDeviceListRedirectPath = (path: string): IDeviceActionTypes => {
  return {
    type: SET_DEVICE_LIST_REDIRECT_PATH,
    path
  };
};

export const getDeviceById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSingleDeviceStart());
    return api.getDeviceById(token, id)
      .then(response => {
        dispatch(fetchSingleDeviceSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchSingleDeviceFail(error));
      });
  }
};

export const deleteDeviceById = (id: string, token: string) => {
  return (dispatch: any) => {
    dispatch(deleteSingleDeviceStart());
     return api.deleteDeviceById(token, id)
        .then(response => {
          dispatch(deleteSingleDeviceSuccess());
        })
        .catch(error => {
          dispatch(deleteSingleDeviceFail(error));
        });
  }
};

export const getDevices = (token: string, index: number, items: number, searchBy: any, search: any) => {
  return (dispatch: any) => {
    dispatch(fetchDevicesStart());
    return api.getDevices(token, index, items, searchBy, search)
      .then(response => {
        dispatch(fetchDevicesSuccess(response.data, parseInt(response.headers.pages, 10), parseInt(response.headers.index, 10)));
      })
      .catch(error => {
        dispatch(fetchDevicesFail(error));
      });
  }
};

export const createDevice = (token: string, device: any) => {
  return (dispatch: any) => {
    dispatch(createSingleDeviceStart());
    return api.createDevice(token, device)
      .then(response => {
        dispatch(createSingleDeviceSuccess(response.data));
      })
      .catch(error => {
        dispatch(createSingleDeviceFail(error));
      });
  }
};

export const updateDevice = (token: string, device: any, id: string) => {
  return (dispatch: any) => {
    dispatch(updateSingleDeviceStart());
    return api.updateDevice(token, device, id)
      .then(response => {
        dispatch(updateSingleDeviceSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateSingleDeviceFail(error));
      });
  }
};
