import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { formatBooleanField, IFormatBooleanField } from '../../../utils/test_reports/Formatters';
import {
  clearSingleTestReport,
  deleteTestReportById,
  getTestReportById,
  archiveTestReportById,
  unarchiveTestReportById
} from "../../../store/test_reports";
import styles from './TestReportDetails.module.css';
import { formatOptionalField, formatOptionalNumericField } from '../../../utils/FieldFormatHelper';
import TestStatusValues from '../../../utils/test_status/TestStatusValues';

const initialState = {
  showModal: false,
  showArchiveModal: false
};

type State = typeof initialState;

interface ITestReportDetailsProps {
  token: string;
  match: any;
  testReport: any;
  location: any;
  index: number;
  history: any;
  loading: boolean;
  testReportListRedirectPath: string;
  userRole: string;
  onGetTestReportById(id: string, token: string): any;
  onClearSingleTestReport(): any;
  onDeleteTestReportById(id: string, token: string): any;
  onArchiveTestReportById(id: string, token: string): any;
  onUnarchiveTestReportById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    index: state.testReports.index,
    testReport: state.testReports.currentTestReport,
    testReportListRedirectPath: state.testReports.testReportListRedirectPath,
    loading: loadingAppSelector(state),
    userRole: state.login.role
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetTestReportById: (id: string, token: string) => dispatch(getTestReportById(id, token)),
    onClearSingleTestReport: () => dispatch(clearSingleTestReport()),
    onDeleteTestReportById: (id: string, token: string) => dispatch(deleteTestReportById(id, token)),
    onArchiveTestReportById: (id: string, token: string) => dispatch(archiveTestReportById(id, token)),
    onUnarchiveTestReportById: (id: string, token: string) => dispatch(unarchiveTestReportById(id, token))
  }
};

const formatTestReportDetails = (testReport: any) => {
  return {
    ID: testReport.id,
    'Issue Key': formatOptionalField(testReport.issueId),
    'Project Key': formatOptionalField(testReport.projectKey),
    'Project': formatOptionalField(testReport.project),
    'Report Type': formatOptionalField(testReport.formType),
    'Tester': formatOptionalField(testReport.reviewerUser.id ? `${testReport.reviewerUser.name} ${testReport.reviewerUser.lastName}` : ''),
    'Developer': formatOptionalField(testReport.reviewedUser.id ? `${testReport.reviewedUser.name} ${testReport.reviewedUser.lastName}` : ''),
    'Account': formatOptionalField(testReport.account.description),
    'Environment': formatOptionalField(testReport.environment.description),
    'Device': formatOptionalField(testReport.device.description),
    'Browser': formatOptionalField(testReport.browser.description),
    'Version': formatOptionalField(testReport.version),
    'Build': formatOptionalField(testReport.build),
    'OS': formatOptionalField(testReport.os),
    'Regression': formatOptionalField(formatBooleanField[testReport.regression as keyof IFormatBooleanField]),
    'Applications': formatOptionalField(testReport.applications),
    'URL': formatOptionalField(testReport.url),
    'Defects Count': formatOptionalNumericField(testReport.defectsCount),
    'Failed UAT': formatOptionalField(formatBooleanField[testReport.returned as keyof IFormatBooleanField]),
    'QA Status': formatOptionalField(testReport.status),
    'Notes': formatOptionalField(testReport.notes),
    'Status': formatOptionalField(testReport.testStatus.description),
  }
};

class TestReportDetails extends React.Component<ITestReportDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetTestReportById } = this.props;
    onGetTestReportById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetTestReportById } = this.props;
      onGetTestReportById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleTestReport } = this.props;
    onClearSingleTestReport();
  }

  onEditButtonClickHandler = () => {
    const { history, testReport } = this.props;
    history.push(`/testReports/${testReport.id}/edit`);
  };

  onDefectsButtonClickHandler = () => {
    const { history, testReport } = this.props;
    history.push(`/testReports/${testReport.id}/defects`);
  };

  onTestCasesButtonClickHandler = () => {
    const { history, testReport } = this.props;
    history.push(`/testReports/${testReport.id}/testCases`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteTestReportById, testReport, token, history } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteTestReportById(testReport.id, token);
    history.push('/');
  };

  onArchiveButtonClickHandler = () => {
    this.setState({
      showArchiveModal: true
    });
  }

  onConfirmArchiveHandler = async () => {
    // eslint-disable-next-line
    const { onArchiveTestReportById, testReport, token, history } = this.props;
    this.setState({
      showArchiveModal: false
    });
    await onArchiveTestReportById(testReport.id, token);
  };

  onRejectArchiveHandler = () => {
    this.setState({
      showArchiveModal: false
    })
  };

  onUnarchiveButtonClickHandler = () => {
    this.setState({
      showArchiveModal: true
    });
  }

  onConfirmUnarchiveHandler = async () => {
    // eslint-disable-next-line
    const { onUnarchiveTestReportById, testReport, token, history } = this.props;
    this.setState({
      showArchiveModal: false
    });
    await onUnarchiveTestReportById(testReport.id, token);
  };

  onRejectUnarchiveHandler = () => {
    this.setState({
      showArchiveModal: false
    })
  };

  render() {
    const { testReport, match, loading, index, testReportListRedirectPath, userRole } = this.props;
    const { showModal, showArchiveModal } = this.state;
    const testReportDetails = testReport? <DescriptionList entity={formatTestReportDetails(testReport)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const testReportListURL = testReportListRedirectPath? testReportListRedirectPath : `/testReports?&page=${index? index : 1}`;

    console.log('ROLE', userRole);

    return (
      <div className={styles.TestReportDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{ backgroundColor: "inherit" }}>
            <Breadcrumb.Section>
              <NavigationItem link="/" exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={testReportListURL} exact>
                Test Reports
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={testReportListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className="d-flex justify-content-between">
            <IconButton
              color="primary"
              type="bugReport"
              fabSize="medium"
              onClick={this.onDefectsButtonClickHandler}
              disabled={loading}
            />
            <div className={styles.Divider} />
            <IconButton
              color="primary"
              type="assignment"
              fabSize="medium"
              onClick={this.onTestCasesButtonClickHandler}
              disabled={loading}
            />
            {userRole === "admin" &&
              testReport &&
              testReport.testStatusId === TestStatusValues.ARCHIVED && (
                <>
                  <div className={styles.Divider} />
                  <IconButton
                    color="primary"
                    type="unarchive"
                    fabSize="medium"
                    onClick={this.onUnarchiveButtonClickHandler}
                    disabled={loading}
                  />
                </>
              )}
            {testReport && testReport.testStatusId !== TestStatusValues.ARCHIVED && (
              <>
                <div className={styles.Divider} />
                <IconButton
                  color="primary"
                  type="archive"
                  fabSize="medium"
                  onClick={this.onArchiveButtonClickHandler}
                  disabled={loading}
                />
                <div className={styles.Divider} />
                <IconButton
                  color="primary"
                  type="edit"
                  fabSize="medium"
                  onClick={this.onEditButtonClickHandler}
                  disabled={loading}
                />
              </>
            )}
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>

        <div className={styles.Details}>
          <div className={styles.TestReportDetails}>
            <div className={styles.Spinner}>{spinner}</div>
            {testReportDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this testReport?
          <div
            className={`${styles.ModalButtons} d-flex justify-content-center`}
          >
            <Button
              type="button"
              color="success"
              onClick={this.onConfirmDeleteHandler}
              disabled={loading}
            >
              {" "}
              Yes{" "}
            </Button>
            <div className={styles.Divider} />
            <Button
              type="button"
              color="danger"
              onClick={this.onRejectDeleteHandler}
              disabled={loading}
            >
              {" "}
              No{" "}
            </Button>
          </div>
        </Modal>

        <Modal open={showArchiveModal}>
          Are you sure you want to{" "}
          {testReport && testReport.testStatusId === TestStatusValues.ARCHIVED
            ? "unarchive"
            : "archive"}{" "}
          this test report?
          <div
            className={`${styles.ModalButtons} d-flex justify-content-center`}
          >
            <Button
              type="button"
              color="success"
              onClick={
                testReport && testReport.testStatusId === TestStatusValues.ARCHIVED
                  ? this.onConfirmUnarchiveHandler
                  : this.onConfirmArchiveHandler
              }
              disabled={loading}
            >
              {" "}
              Yes{" "}
            </Button>
            <div className={styles.Divider} />
            <Button
              type="button"
              color="danger"
              onClick={this.onRejectArchiveHandler}
              disabled={loading}
            >
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(TestReportDetails);
