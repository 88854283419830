import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';
import styles from './DrawerToggle.module.css';

interface IDrawerToggle {
  onClick?(): any;
}

const drawerToggle: React.FunctionComponent<IDrawerToggle> = (props) => {
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} className={styles.DrawerToggle}>
      <MenuIcon/>
    </IconButton>
  );
};

export default drawerToggle;
