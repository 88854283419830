import { ILoginActionTypes, ILoginFailAction, ILoginStartAction, ILoginState, ILoginSuccessAction, ILogoutAction, ISetLoginRedirectPathAction, LOGIN_FAIL, LOGIN_START, LOGIN_SUCCESS, LOGOUT, SET_LOGIN_REDIRECT_PATH } from './types';

const initialState: ILoginState = {
  token: null,
  userId: null,
  role: null,
  tokenExpirationDate: null,
  error: null,
  loading: false,
  loginRedirectPath: '/'
};

const loginStart = (state: ILoginState, action: ILoginStartAction) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const loginSuccess = (state: ILoginState, action: ILoginSuccessAction) => {
  return {
    ...state,
    token: action.token,
    userId: action.userId,
    role: action.role,
    tokenExpirationDate: action.tokenExpirationDate,
    error: null,
    loading: false
  };
};

const loginFail = (state: ILoginState, action: ILoginFailAction) => {
  return {
    ...state,
    error: action.error,
    loading: false
  };
};

const logout = (state: ILoginState, action: ILogoutAction) => {
  return {
    ...state,
    token: null,
    userId: null,
    role: null,
    tokenExpirationDate: null,
  };
};

const setAuthRedirectPath = (state: ILoginState, action: ISetLoginRedirectPathAction) => {
  return {
    ...state,
    loginRedirectPath: action.path
  };
};

const reducer = (state = initialState, action: ILoginActionTypes): ILoginState => {
  switch ( action.type ) {
    case LOGIN_START: return loginStart(state, action);
    case LOGIN_SUCCESS: return loginSuccess(state, action);
    case LOGIN_FAIL: return loginFail(state, action);
    case LOGOUT: return logout(state, action);
    case SET_LOGIN_REDIRECT_PATH: return setAuthRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
