import * as React from "react";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import ClearIcon from "@material-ui/icons/Clear";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BugReportIcon from "@material-ui/icons/BugReport";
import LockIcon from "@material-ui/icons/Lock";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";

interface IIconButton {
  color: "default" | "inherit" | "primary" | "secondary";
  disabled?: boolean;
  type?:
    | "search"
    | "add"
    | "send"
    | "remove"
    | "custom"
    | "edit"
    | "back"
    | "delete"
    | "clear"
    | "bugReport"
    | "assignment"
    | "lock"
    | "archive"
    | "unarchive";
  fabSize?: "small" | "medium" | "large";
  onClick?(event: React.MouseEvent<HTMLElement>): any;
  onKeyUp?(): any;
}

const iconButton: React.FunctionComponent<IIconButton> = (props) => {
  const { color, disabled, type, onClick, onKeyUp, fabSize } = props;
  let icon = null;

  switch (type) {
    case "search":
      icon = <SearchIcon />;
      break;
    case "add":
      icon = <AddIcon />;
      break;
    case "send":
      icon = <SendIcon />;
      break;
    case "remove":
      icon = <RemoveIcon />;
      break;
    case "edit":
      icon = <EditIcon />;
      break;
    case "back":
      icon = <ArrowBackIcon />;
      break;
    case "delete":
      icon = <DeleteIcon />;
      break;
    case "clear":
      icon = <ClearIcon />;
      break;
    case "bugReport":
      icon = <BugReportIcon />;
      break;
    case "assignment":
      icon = <AssignmentIcon />;
      break;
    case "lock":
      icon = <LockIcon />;
      break;
    case "archive":
      icon = <ArchiveIcon />;
      break;
    case "unarchive":
      icon = <UnarchiveIcon />;
      break;
    default:
      icon = props.children;
  }

  let button: JSX.Element;
  if (fabSize) {
    button = (
      <Fab
        size={fabSize}
        disabled={disabled}
        color={color}
        onClick={onClick}
        onKeyUp={onKeyUp}
      >
        {icon}
      </Fab>
    );
  } else {
    button = (
      <IconButton
        disabled={disabled}
        color={color}
        onClick={onClick}
        onKeyUp={onKeyUp}
      >
        {icon}
      </IconButton>
    );
  }

  return <i>{button}</i>;
};

export default iconButton;
