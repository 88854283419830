import * as React from 'react';
import { Container } from 'semantic-ui-react';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import styles from './Layout.module.css';

interface ILayout {
  isAuthenticated: boolean;
}

class Layout extends React.Component<ILayout> {
  state = {
    showSideDrawer: false
  };

  sideDrawerClosedHandler = () => {
    this.setState( { showSideDrawer: false } );
  };

  sideDrawerToggleHandler = () => {
    this.setState( ( prevState: React.ComponentState ) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    } );
  };

  render () {
    const { isAuthenticated, children } = this.props;
    return isAuthenticated? (
      <>
        <Toolbar
          onDrawerToggleClick={this.sideDrawerToggleHandler} />
        <SideDrawer
          open={this.state.showSideDrawer}
          onClose={this.sideDrawerClosedHandler} />
        <main className={styles.main}>
          <Container style={{width: '75%'}}>
            {children}
          </Container>
        </main>
      </>
    ) : children;
  }
}

export default Layout;
