import * as React from 'react';
import styles from './Backdrop.module.css';

interface IBackdrop {
  show: boolean;
  onClick?(): any;
}

const backdrop: React.FunctionComponent<IBackdrop> = (props) => {
  const { onClick, show } = props;
  return (
    show ? <div className={styles.backdrop} onClick={onClick}/> : null
  );
};

export default backdrop;