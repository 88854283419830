import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Button from '../../../components/UI/Button/Button';
import DescriptionList from '../../../components/UI/DescriptionList/DescriptionList';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleDevice, deleteDeviceById, getDeviceById } from '../../../store/devices';
import { formatDeviceActive, IDeviceActive } from '../../../utils/devices/Formatters';
import styles from './DeviceDetails.module.css';
import * as _ from 'lodash';

const initialState = {
  showModal: false,
};

type State = typeof initialState;

interface IDeviceDetailsProps {
  token: string;
  match: any;
  device: any;
  index: number;
  location: any;
  history: any;
  loading: boolean;
  deviceListRedirectPath: string;
  onGetDeviceById(id: string, token: string): any;
  onClearSingleDevice(): any;
  onDeleteDeviceById(id: string, token: string): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    device: state.devices.currentDevice,
    index: state.devices.index,
    deviceListRedirectPath: state.devices.deviceListRedirectPath,    
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onGetDeviceById: (id: string, token: string) => dispatch(getDeviceById(id, token)),
    onClearSingleDevice: () => dispatch(clearSingleDevice()),
    onDeleteDeviceById: (id: string, token: string) => dispatch(deleteDeviceById(id, token)),
  }
};
const formatOptionalField = (field: any) => !(_.isEmpty(field) || _.isNull(field) || _.isUndefined(field))? field : '-'; 

const formatDeviceDetails = (device: any) => {
  return {
    ID: device.id,
    Description: formatOptionalField(device.description),
    Brand: formatOptionalField(device.brand),
    Model: formatOptionalField(device.model),
    'Active': formatOptionalField(formatDeviceActive[device.active as keyof IDeviceActive]),
  }
};

class DeviceDetails extends React.Component<IDeviceDetailsProps>{
  state: State = initialState;

  componentDidMount(): void {
    const { token, match, onGetDeviceById } = this.props;
    onGetDeviceById(match.params.id, token);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
    const { token, match, onGetDeviceById } = this.props;
      onGetDeviceById(match.params.id, token);
    }
  }

  componentWillUnmount(): void {
    const { onClearSingleDevice } = this.props;
    onClearSingleDevice();
  }

  onEditButtonClickHandler = () => {
    const { history, device } = this.props;
    history.push(`/devices/${device.id}/edit`);
  };

  onDeleteButtonClickHandler = async () => {
    this.setState({
      showModal: true,
    });
  };

  onRejectDeleteHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  onConfirmDeleteHandler = async () => {
    const { onDeleteDeviceById, device, token, history } = this.props;
    this.setState({
      showModal: false,
    });
    await onDeleteDeviceById(device.id, token);
    history.push('/');
  };

  render() {
    const { device, match, loading, index, deviceListRedirectPath } = this.props;
    const { showModal } = this.state;
    const deviceDetails = device? <DescriptionList entity={formatDeviceDetails(device)}/> : null;
    const spinner = loading? <Spinner type="linear" /> : null;
    const deviceListURL = deviceListRedirectPath? deviceListRedirectPath : `/devices?&page=${index? index : 1}`; 

    return (
      <div className={styles.DeviceDetails}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
          <Breadcrumb.Section>
            <NavigationItem link='/' exact>
              Home
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section>
            <NavigationItem link={deviceListURL} exact>
              Devices
            </NavigationItem>
          </Breadcrumb.Section>
          <Breadcrumb.Divider> / </Breadcrumb.Divider>
          <Breadcrumb.Section active> {match.params.id} </Breadcrumb.Section>
        </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={deviceListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
          <div className='d-flex justify-content-between'>
            <IconButton color="primary" type="edit" fabSize="medium" onClick={this.onEditButtonClickHandler} disabled={loading} />
            {/* <div className={styles.Divider}/>
            <IconButton color="primary" type="delete" fabSize="medium" onClick={this.onDeleteButtonClickHandler} disabled={loading} /> */}
          </div>
        </div>
        <div className={styles.Details}>
          <div className={styles.DeviceDetails}>
            <div className={styles.Spinner}>
              {spinner}
            </div>
            {deviceDetails}
          </div>
        </div>

        <Modal open={showModal}>
          Are you sure you want to delete this device?
          <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
            <Button type="button" color="success" onClick={this.onConfirmDeleteHandler} disabled={loading}> Yes </Button>
            <div className={styles.Divider} />
            <Button type="button" color="danger" onClick={this.onRejectDeleteHandler} disabled={loading}> No </Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(DeviceDetails);
