import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb} from 'semantic-ui-react';
import NavigationItem from '../../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import IconButton from '../../../components/UI/IconButton/IconButton';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';
import { loadingAppSelector } from '../../../store/global/selector';
import { clearSingleBrowser, createBrowser } from '../../../store/browsers';
import BrowserForm from '../BrowserForm/BrowserForm';
import styles from './BrowserCreate.module.css';

const initialState = {
  showModal: false,
  modalMessage: '',
};

type State = typeof initialState;

interface IBrowserCreateProps {
  token: string;
  browser: any;
  index: number;
  history: any;
  loading: boolean;
  browserListRedirectPath: string;
  onCreateBrowser(token: string, browser: any): any;
  onClearSingleBrowser(): any;
}

const matchStateToProps = (state: any) => {
  return {
    token: state.login.token,
    browser: state.browsers.currentBrowser,
    index: state.browsers.index,
    browserListRedirectPath: state.browsers.browserListRedirectPath,
    loading: loadingAppSelector(state),
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    onCreateBrowser: (token: string, browser: any) => dispatch(createBrowser(token, browser)),
    onClearSingleBrowser: () => dispatch(clearSingleBrowser()),
  }
};

class BrowserCreate extends React.Component<IBrowserCreateProps>{
  state: State = initialState;

  onSubmitBrowser = async (browserPayload: any) => {
    const { onCreateBrowser, token, history } = this.props;
    await onCreateBrowser(token, browserPayload);
    const { browser } = this.props;
    if (browser) {
      history.push(`/browsers/${browser.id}/details`);
    }
  };

  componentWillUnmount(): void {
    const { onClearSingleBrowser } = this.props;
    onClearSingleBrowser();
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalMessage: '',
    });
  };

  render() {
    const { loading, index, browserListRedirectPath } = this.props;
    const { showModal, modalMessage } = this.state;
    const browserListURL = browserListRedirectPath? browserListRedirectPath : `/browsers?&page=${index? index : 1}`;

    return (
      <div className={styles.BrowserCreate}>
        <div className={`text-center ${styles.Breadcrumb}`}>
          <Breadcrumb style={{backgroundColor:'inherit'}}>
            <Breadcrumb.Section>
              <NavigationItem link='/' exact>
                Home
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section>
              <NavigationItem link={browserListURL} exact>
                Browsers
              </NavigationItem>
            </Breadcrumb.Section>
            <Breadcrumb.Divider> / </Breadcrumb.Divider>
            <Breadcrumb.Section active> Create </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <div className={`d-flex justify-content-between`}>
          <NavigationItem link={browserListURL} exact>
            <IconButton color="primary" type="back" disabled={loading} />
          </NavigationItem>
        </div>

        <BrowserForm onSubmitBrowser={this.onSubmitBrowser} />
        <Modal open={showModal}>
            { modalMessage }
            <div className={`${styles.ModalButtons} d-flex justify-content-center`}>
              <Button type="button" color="danger" onClick={this.onCloseModal} disabled={loading}> Got it </Button>
            </div>
          </Modal>
      </div>
    );
  }

}

export default connect(matchStateToProps,matchDispatchToProps)(BrowserCreate);
